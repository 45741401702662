import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ChooseAvatarPage } from '../pages/chooser/choose-avatar/choose-avatar.page';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  avatars: avatar[];

  urlPrefix: string = 'https://web.pipeline.page/';

  constructor(
    private modalCtrl: ModalController,
    public userService: UserService,
  ) {
  }

  getAvatars() {
    if(!this.avatars) {
      let genders = this.userService.getGenders();
      let avatars = [], iMax = 5;
      genders.forEach((gender: string) => {
        let i = 1;
        while(i <= iMax) {
          avatars.push({
            src: './assets/img/avatars/' + gender + '/light/' + i + '.webp',
            gender: gender,
            skin: 'light'
          });
          avatars.push({
            src: './assets/img/avatars/' + gender + '/dark/' + i + '.webp',
            gender: gender,
            skin: 'dark'
          });
          i++;
        }
      });
      this.avatars = avatars;
    }
    return this.avatars;
  }

  select() {
    return new Promise(async (resolve, reject) => {
      let avatars = await this.getAvatars();
      let modal = await this.modalCtrl.create({
        component: ChooseAvatarPage,
        componentProps: {
          avatars: avatars,
        },
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: 'avatarModal'
      });
      modal.onWillDismiss().then((response: any) => {
        resolve(response && response.data && response.data.src ? this.urlPrefix + response.data.src.replace('./', '/') : null);
      });
      modal.present();
    });
  }

}
