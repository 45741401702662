<img [src]="picture" *ngIf="!!picture" />

<div class="centerView" [hidden]="view.cameraActive">
    <ion-icon [name]="!!view.error ? 'warning-outline' : 'camera-outline'" [hidden]="!!picture"></ion-icon>
    <p [hidden]="!view.error" [innerHTML]="view.error|translate"></p>
</div>

<div class="bottomView" [hidden]="!view.cameraActive">

    <ion-grid id="picturesGrid" *ngIf="!!view.pictures">
        <ion-row>
            <ion-col [size]="3" *ngFor="let picture of view.pictures; let i = index;" (click)="onPictureGridImageClick(picture)">
                <ion-img [src]="picture"></ion-img>
                <ion-button color="danger" shape="round" icon-only (click)="deletePictureFromList(picture, i)" size="small">
                    <ion-icon name="close-outline"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>

    <div [hidden]="!picture" class="singlePictureActions">
        <ion-button (click)="choosePicture()" color="success" icon-only>
            <ion-icon name="checkmark-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="deletePicture()" color="danger" icon-only>
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
    </div>

    <div [hidden]="!!picture">

        <ion-button (click)="switchCamera()" fill="clear" icon-only color="dark">
            <ion-icon name="camera-reverse-outline"></ion-icon>
        </ion-button>
        
        <ion-button (click)="takeSnapshot()" id="snapshotBtn" fill="clear" icon-only color="dark">
            <ion-icon name="radio-button-on-outline"></ion-icon>
        </ion-button>

        <ion-button (click)="toggleZoom()" fill="clear" icon-only color="dark">
            <ion-label [innerHTML]="view.zoom"></ion-label>
        </ion-button>

        <!--
        <ion-button (click)="setColorEffect()" fill="clear" icon-only color="dark">
            <ion-icon name="color-wand-outline"></ion-icon>
        </ion-button>
        -->
    </div>
</div>