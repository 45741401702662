<ion-card (click)="viewProduct(product)" class="product hasBackground">
  <ion-card-header>
    <ion-thumbnail *ngIf="!!product.image">
      <ion-img [src]="product.image" (ionError)="thumbnailLoadingFailed(product)"></ion-img>
    </ion-thumbnail>
    <ion-skeleton-text class="image" *ngIf="!product.image" [animated]="!product.uid"></ion-skeleton-text>
  </ion-card-header>
  <ion-card-content>
    <ion-card-title [hidden]="!product.name" [innerHTML]="product.name"></ion-card-title>
    <p [hidden]="!product.price && !product.price_html" class="price" [innerHTML]="product.price_html || (product.price + ' €')"></p>

    <ion-skeleton-text [hidden]="product.name" style="width:90%;" animated></ion-skeleton-text>
    <ion-skeleton-text [hidden]="product.name" style="width:30%;" animated></ion-skeleton-text>

    <ion-button color="primary" size="block">
      <ion-label [innerHTML]="'view_product'|translate"></ion-label>
    </ion-button>

  </ion-card-content>
</ion-card>