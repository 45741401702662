import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'pipeline-post-footer',
  templateUrl: './post-footer.component.html',
  styleUrls: ['./post-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostFooterComponent implements OnInit {
  @Input() content: IonContent;
  @Input() post: post;
  @Input() view: any;

  constructor(
  ) {
    this.view = this.view || {};
  }

  detectChanges() {

  }

  ngOnInit() {
    this.view = this.view || {};
  }

}
