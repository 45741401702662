import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { ApplePayService } from '../../../services/apple-pay.service';
import { BasketService } from '../../../services/basket.service';
import { CheckoutService } from '../../../services/checkout.service';
import { ConfigService } from '../../../services/config.service';
import { EventsService } from '../../../services/events.service';
import { ModalService } from '../../../services/modal.service';
import { OrdersService } from '../../../services/orders.service';
import { PaymentsService } from '../../../services/payments.service';
import { ToolsService } from '../../../services/tools.service';
import { UserService } from '../../../services/user.service';
import { WeclappService } from '../../../services/weclapp.service';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.page.html',
  styleUrls: ['./payment.page.scss'],
})
export class PaymentPage implements OnInit {

  _basket: basketInfo;

  applePayAvailable: boolean = false;

  config: pipelineAppConfig;

  deliveryOptions: any;

  googlePayAvailable: boolean = false;
  
  isOpen: boolean;

  isWeb: boolean = false;
  
  paymentOptions: any;

  tmpOrder: any;

  user: user;

  view: any = {
    paymentMethods: [],
  };

  constructor(
    private applePay: ApplePayService,
    private basket: BasketService,
    private checkoutService: CheckoutService,
    private configService: ConfigService,
    private events: EventsService,
    private modalCtrl: ModalService,
    private navCtrl: NavController,
    private ordersService: OrdersService,
    private payments: PaymentsService,
    private tools: ToolsService,
    public userService: UserService,
    private weclapp: WeclappService,
  ) {
    this.config = this.configService.getConfig();
    
    this.deliveryOptions = this.checkoutService.getDeliveryOptions() || {};
    this.deliveryOptions.repeat = this.deliveryOptions.repeat || false;

    this.isWeb = this.tools.isWeb();
    
    this.tmpOrder = this.ordersService.getTmpOrder() || this.ordersService.createTmpOrder();
    this.paymentOptions = this.payments.getPaymentOptions();
    this.user = this.userService.getUser() || {};
  }

  async calcIsModal() {
    try {
      this.view.isModal = (await this.modalCtrl).isModal();
    } catch(e) {
      this.view.isModal = true;
    }
  }

  async checkCanMakeApplePayments() {
    await this.applePay.canMakePayments()
    .then(blCanMakePayments => {
      this.applePayAvailable = !!blCanMakePayments;
    })
    .catch(() => {
      this.applePayAvailable = false;
    });
  }
  
  async dismiss() {
    this.modalCtrl.dismiss();
  }

  dateChanged() {
  }

  async ionViewWillEnter() {
    this.isWeb = this.tools.isWeb();
    this._basket = await this.basket.calculateBasketInfo();
    this.tmpOrder = this.ordersService.getTmpOrder();
    this.user = this.userService.getUser() || {};
  }

  loadPaymentMethods() {
    if(!!this.config.useWeclappExtension) {
      this.loadWeclappPaymentMethods();
    }
  }

  loadWeclappPaymentMethods() {
    this.weclapp.getPaymentMethods()
    .then((paymentMethods: weclappPaymentMethod[]) => {
      this.view.paymentMethods = this.view.paymentMethods.concat(this.weclapp.parsePaymentMethods(paymentMethods));
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  login() {
    this.userService.setAfterLoginRedirectUrl('/payment');
    this.userService.setAfterRegisterRedirectUrl('/payment');
    this.navCtrl.navigateForward('/login');
  }

  next() {
    this.dismiss();

    if(!!this.config.showCheckoutPage) {
      this.events.publish('view:checkout');
    } else {
      this.pay();
    }
  }

  ngOnInit() {
    this.calcIsModal();
    this.loadPaymentMethods();

    if(this.config.usePayPalExtension) {
      this.renderPayPalButton();
    }

    try {
      this.checkCanMakeApplePayments();
    } catch(e) {
      console.warn('e apply pay', e);
    }
  }

  pay() {
    this.payments.pay()
    .then((payResponse: any) => {
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  renderPayPalButton() {
    let _this = this;
    setTimeout(() => {
      // Render the PayPal button into #paypal-button-container
      <any>window['paypal'].Buttons({

        // Set up the transaction
        createOrder: (data, actions) => {
          let createParams = {
            purchase_units: [{
              amount: {
                value: _this._basket.price_a
              }
            }]
          };
          return actions.order.create(createParams);
        },

        // Finalize the transaction
        onApprove: (data, actions) => {
          return actions.order.capture()
            .then(function (details) {
              // Show a success message to the buyer
              alert('Transaction completed by ' + details.payer.name.given_name + '!');
            })
            .catch(err => {
              console.warn(err);
            })
        }
      }).render('#paypal-button-container');
    }, 500)
  }

  use(paymentMethod: string) {
    this.payments.setPaymentMethod(paymentMethod);
    this.paymentOptions = this.payments.getPaymentOptions();
  }

}