<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-back-button [text]="'back'|translate"></ion-back-button>
    </ion-buttons>
    <ion-title [innerHTML]="'feedback'|translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <ion-header collapse="condense" class="ion-no-border">
    <ion-toolbar class="ion-no-border container">
      <ion-title size="large" [innerHTML]="'feedback'|translate"></ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-spinner name="circular" [hidden]="form && form.fields"></ion-spinner>

  <div class="container" *ngIf="form && form.fields">
    <ion-card>
      <ion-list
        lines="none"
        *ngFor="let field of form.fields; let iField = index"
        [hidden]="view.step != field.group"
      >
        <ion-item lines="none" *ngIf="field.type == 'stars'">
          <p [innerHTML]="field.title"></p>
          <rating
            [rate]="form.fields[iField].answer"
            readonly="false"
            size="default"
            (rateChange)="onChange($event, iField)"
          ></rating>
        </ion-item>
        <ion-item *ngIf="field.type != 'stars'">
          <ion-label>
            <h1
              *ngIf="field.type == 'headline'"
              class="ion-text-wrap"
              [innerHTML]="field.title"
            ></h1>
            <h3
              *ngIf="field.type == 'subheadline'"
              class="ion-text-wrap"
              [innerHTML]="field.title"
            ></h3>
            <p
              *ngIf="field.type != 'headline' && field.type != 'subheadline'"
              class="ion-text-wrap"
              [innerHTML]="field.title"
            ></p>
          </ion-label>
          <ion-textarea
            rows="10"
            *ngIf="field.type == 'textarea'"
            id="editor"
            name="editor"
            [(ngModel)]="form.fields[iField].answer"
          ></ion-textarea>
          <ion-input
            type="text"
            *ngIf="field.type == 'text'"
            [(ngModel)]="form.fields[iField].answer"
          ></ion-input>
          <ion-input
            type="tel"
            *ngIf="field.type == 'phone'"
            [(ngModel)]="form.fields[iField].answer"
          ></ion-input>
          <ion-input
            type="email"
            *ngIf="field.type == 'email'"
            [(ngModel)]="form.fields[iField].answer"
          ></ion-input>
        </ion-item>
      </ion-list>
    </ion-card>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-no-border container">
    <ion-item lines="none" *ngIf="user && user.uid">
      <ion-checkbox
        [(ngModel)]="formConfig.anonymous"
        slot="start"
      ></ion-checkbox>
      <ion-label [innerHTML]="'feedback_anonymous'|translate"></ion-label>
    </ion-item>
    <ion-button
      size="block"
      color="dark"
      (click)="submit()"
      [hidden]="view.step !== view.steps"
      [disabled]="!formConfig.validated"
    >
      <ion-label [innerHTML]="'send'|translate"></ion-label>
      <ion-icon name="arrow-forward" slot="end"></ion-icon>
    </ion-button>
    <ion-button
      size="block"
      color="dark"
      (click)="next()"
      [hidden]="view.step === view.steps"
      [disabled]="!formConfig.validated"
    >
      <ion-label [innerHTML]="'continue'|translate"></ion-label>
      <ion-icon name="arrow-forward" slot="end"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-footer>
