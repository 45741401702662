import { Injectable } from '@angular/core';

import { AppcmsService } from './appcms.service';
import { ChooserService } from './chooser.service';
import { EventsService } from './events.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  currentTeam: team;

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private events: EventsService,
    private userService: UserService,
  ) {
  }
  
  getAll(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.AppCMS.loadPluginData('teams', Object.assign(params, {
      filter: options,
    }), [], {}, blForceRefresh);
  }

  getAvailable(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.getAll(Object.assign(options, {
      user: options.user || this.userService.getUid(),
    }), blForceRefresh, params);
  }

  getByUid(teamId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('teams', {}, [teamId], {}, blForceRefresh);
  }

  getByUserUid(userId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('teams', {}, ['user', userId], {}, blForceRefresh);
  }

  getCurrentTeam() {
    return this.currentTeam;
  }

  getCurrentTeamUid() {
    let team: team = this.getCurrentTeam();

    if(!!team && !!team.uid) {
      return team.uid;
    }
  }

  init() {
    return new Promise((resolve, reject) => {

      this.events.subscribe('appcms:user:updated', () => {
        this.loadUserTeamsData();
      });

      this.loadUserTeamsData().then(resolve).catch(reject);
    });
  }

  async loadUserTeamsData() {
    if(!!this.userService.isLoggedIn()) {
      try {
        let teams: team[] = (await this.getAvailable() as team[]);

        if(!!teams[0] && !this.getCurrentTeam()) {
          this.setCurrentTeam(teams[0]);
        }
      } catch(e) {
        console.warn('> loading team failed', e);
      }
    }
  }

  pick(options: chooseConfig = null) {
    return new Promise(async (resolve, reject) => {

      let chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: await this.getAll(),
        labelKey: 'name',
        service: this,
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig)
        .then(async (chooseResponse: chooseResponse) => {
          resolve(chooseResponse);
        })
        .catch(reject);
    });
  }

  setCurrentTeam(team: team) {
    if(!!team && !!team.uid) {
      this.currentTeam = team;
      
      this.events.publish('team:updated', team);
      this.AppCMS.setRequestParam('team_uid', team.uid);
    }
  }

}