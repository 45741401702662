import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  state: any = {
    isBackground: false,
  }

  constructor(
    //private backgroundMode: BackgroundMode,
  ) {
  }

  init() {
    document.addEventListener('pause', (event: any) => this.onPause(event));
    document.addEventListener('resume', (event: any) => this.onResume(event));
    
    try {
      //this.backgroundMode.enable();
    } catch(e) {
      console.warn('background mode init error', e);
    }
  }

  isBackground() {
    return !!this.state.isBackground;
  }

  onPause(event: any) {
    this.state.isBackground = true;
  }

  onResume(event: any) {
    this.state.isBackground = false;
  }

}
