import { Component, OnInit } from '@angular/core';

import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'pipeline-post-suggestions-card',
  templateUrl: './post-suggestions-card.component.html',
  styleUrls: ['./post-suggestions-card.component.scss'],
})
export class PostSuggestionsCardComponent implements OnInit {

  view: any = {};

  constructor(
    private events: EventsService,
  ) {
    
  }

  ngOnInit() {
    this.view.loaded = false;

    this.events.subscribe('view:suggestions:updated', () => {
      this.view.loaded = true;
    });
  }

}
