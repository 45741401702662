import { ChangeDetectionStrategy, Component, Input, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AbonnementService } from '../../../services/abonnement.service';
import { BrowserService } from '../../../services/browser.service';
import { EventsService } from '../../../services/events.service';
import { ShortcodesService } from '../../../services/shortcodes.service';
import { ToolsService } from '../../../services/tools.service';

@Component({
  selector: 'pipeline-post-content',
  templateUrl: './post-content.component.html',
  styleUrls: ['./post-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostContentComponent implements OnInit {
  @Input() post: post;
  
  postPage: any;

  view: any = {};

  constructor(
    private abo: AbonnementService,
    private events: EventsService,
    private browser: BrowserService,
    private zone: NgZone,
    private _sanitizer: DomSanitizer,
    private shortcodes: ShortcodesService,
    private tools: ToolsService,
  ) {
  }

  detectChanges(blFireEvents: boolean = true) {
    if(!!blFireEvents) {
      this.events.publish('post:reader:update', this.post);
    }
  }

  initEvents() {

    this.events.subscribe('post:content:update', (post: post) => {
      this.post = post;
      this.loaded(false);
    });

  }

  loaded(blFireEvents: boolean = true) {
    this.zone.run(() => {
      this.post.thumbnailBackground = this._sanitizer.bypassSecurityTrustStyle('url(' + this.post.thumbnail + ')');
      this.post.vip = (this.abo.calcVip(this.post) && !this.view.isPreviewMode);

      let _document = document.getElementById('post');

      if(!!_document) {
        let post = this.shortcodes.prepare(this.post, 'post_content');
        let content = post ? (post.post_content || this.post.post_content) : this.post.post_content;
  
        // detect if video mode
        this.view.mode = this.tools.isVideoModeContent(content) ? 'video' : this.view.mode;
        
        if(this.view.mode === 'video') {
          this.view.short = false;
        } else
        if(this.view.short) {
          content = this.tools.truncateBySentences(content, 15);
        }
  
        _document.innerHTML = content;
  
        this.prepareOnLinkClick(_document);
        
        this.post.post_content = _document.outerHTML;
        this.view.post_content_formatted = this._sanitizer.bypassSecurityTrustHtml(this.post.post_content);
      }
      
      this.detectChanges(blFireEvents);
    });
  }

  ngOnInit() {
    this.initEvents();
    this.loaded();
  }

  onLinkClick(event: any) {
    let url = event.target.getAttribute('data-url');
    if(url) {
      if(url[0] === '/' && this.post.host) {
        url = this.post.host + url;
      }
      if(url.indexOf('http') === -1) {
        url = 'https://' + url;
      }
      this.browser.create(url);
    }
    event.preventDefault();
    this.detectChanges();

    return false;
  }

  onPageClick(event: any) {
    if(event && event.target && event.target.nodeName && event.target.nodeName === 'A') {
      return this.onLinkClick(event);
    }
  }

  prepareOnLinkClick(_document: any) {
    let a: any = _document.querySelectorAll('a');

      if(a && a.length) {

        a.forEach((element: any) => {
          element.setAttribute('data-url', element.href);
          element.removeAttribute("href");
          //element.setAttribute('onclick', 'onLinkClick');
          element.addEventListener('click', (event: any) => {
            this.onLinkClick(event);
          });
        });
      }
  }

}
