<ion-card class="downloadWallCard">
  <ion-card-header>
    <ion-card-title [innerHTML]="'post_download_wall_title'|translate"></ion-card-title>
    <ion-card-subtitle [innerHTML]="'post_download_wall_subtitle'|translate"></ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <ion-button (click)="downloadApp()" color="white" size="block">
      <ion-label [innerHTML]="'download_app'|translate"></ion-label>
      <ion-icon name="download-outline" slot="end"></ion-icon>
    </ion-button>

    <ion-button (click)="createAccount()" color="white" fill="outline" size="block">
      <ion-label [innerHTML]="'create_account'|translate"></ion-label>
      <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>
    </ion-button>
  </ion-card-content>
</ion-card>