import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.page.html',
  styleUrls: ['./cookies.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CookiesPage implements OnInit {

  cookies: any;
  
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {
    this.cookies = this.navParams.get('cookiesService');
  }

  accept() {
    this.cookies.accept()
    .then(() => {
      this.dismiss();
    })
    .catch((error: any) => {
      //console.log('error', error);
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  more() {
    this.cookies.more();
  }

  ngOnInit() {
  }

}
