<pipeline-home-loading-wrapper *ngIf="view.loading" [hidden]="!view.loading"></pipeline-home-loading-wrapper>

<ion-header *ngIf="view.isMultiMode" class="ion-no-border">
    <ion-toolbar class="ion-no-border">
        <ion-buttons slot="start">
            <ion-button icon-only (click)="dismiss()">
                <ion-icon name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="scrollable ion-padding" [class.multiMode]="!!view.isMultiMode">

    <img *ngIf="config.showLoginTopLogo && !view.isMultiMode" [src]="config.loginTopLogoUrl || './assets/img/logo.webp'" class="login-logo pipeline-logo light size-{{config.loginTopLogoSize || 'medium'}}" />

    <img *ngIf="config.showLoginTopLogo && !view.isMultiMode" [src]="config.loginTopLogoUrl || './assets/img/logo-dark.webp'" class="login-logo pipeline-logo dark size-{{config.loginTopLogoSize || 'medium'}}" />
    
    <ion-spinner name="circular" [hidden]="!view.loading || view.loggedIn || view.verify"></ion-spinner>

    <div class="form" [hidden]="view.loading" autocomplete="off" (ngSubmit)="login()">

        <h1 class="headline" *ngIf="!view.isMultiMode" [innerHTML]="(view.headline && view.headline !== 'login_headline' ? view.headline : (config.loginHeadlineText || 'login_headline'))|translate"></h1>

        <form (ngSubmit)="login()">
                
            <ion-list lines="none">
                <ion-item *ngIf="error" class="error-msg" color="danger">
                    <ion-icon name="warning-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="error|translate" class="ion-text-wrap"></ion-label>
                </ion-item>
                <ion-item>
                    <ion-icon name="mail-outline" slot="start"></ion-icon>
                    <ion-label position="stacked" [innerHTML]="'email'|translate"></ion-label>
                    <ion-input autocorrect="on" name="email" type="email" #loginEmail id="loginEmail" autocomplete="off" [(ngModel)]="user.email" class="color-text-primary" [placeholder]="'email'|translate"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-icon name="key-outline" slot="start"></ion-icon>
                    <ion-label position="stacked" [innerHTML]="'password'|translate"></ion-label>
                    <ion-input autocorrect="on" name="password" type="password" autocomplete="off" [(ngModel)]="user.password" class="color-text-primary" [placeholder]="'password'|translate"></ion-input>
                </ion-item>
                <ion-button *ngIf="config.allowUserForgotPassword" type="button" size="small" (click)="forgotPassword()" class="forgotPassword" fill="clear" type="button">
                    <ion-label [innerHTML]="'forgot_password'|translate"></ion-label>
                </ion-button>
            </ion-list>
            
            <ion-button type="submit" size="block" color="primary">
                <ion-label [innerHTML]="'log_in'|translate"></ion-label>
            </ion-button>

            <ion-grid>
                <ion-row>
                    <ion-col *ngIf="config.allowUserRegister" [size]="(view.platform != 'android') && (view.isWeb || (view.platform === 'ios' && !view.isWeb)) ? 6 : 12">
                        <ion-button (click)="register()" size="block" fill="clear">
                            <ion-label [innerHTML]="'create_account'|translate"></ion-label>
                        </ion-button>
                    </ion-col>
                    <ion-col size="6" *ngIf="config.useSignInWithApple && (view.platform != 'android') && (view.isWeb || (view.platform === 'ios' && !view.isWeb))">
                        <ion-button *ngIf="view.platform === 'ios' && !view.isWeb" (click)="signInWithApple()" size="block" class="dark" color="dark" type="button">
                            <ion-icon name="logo-apple"></ion-icon>
                        </ion-button>
                        <div *ngIf="view.isWeb" id="appleid-signin" data-color="black" data-border="true" data-type="sign in"></div>
                    </ion-col>
                </ion-row>
            </ion-grid>
            
        </form>

        <small class="legal-info-text" [innerHTML]="view.loginLegalInfo"></small>

        <svg class="login-curve curve curve-vertical curve-middle" xmlns="http://www.w3.org/2000/svg" width="64.128" height="931.265" viewBox="0 0 64.128 931.265">
          <path data-name="curve" d="M465.611,0c257.155,0,466.568,64.415,465.62,64.105S2.48,62.7-.009,64.105,208.456,0,465.611,0Z" transform="translate(64.128 0.031) rotate(90)" />
        </svg>

    </div>
    
</ion-content>