import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChooseCoverPage } from '../pages/chooser/choose-cover/choose-cover.page';

@Injectable({
  providedIn: 'root'
})
export class CoversService {

  covers: cover[];

  defaultCover: string = './assets/img/covers/pipeline-app-profile-header-7.jpg';

  urlPrefix: string = 'https://web.pipeline.page/';
  
  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  getAll() {
    if(!this.covers) {
      let avatars = [], iMax = 13;
      let i = 1;
        while(i <= iMax) {
          avatars.push({
            src: './assets/img/covers/pipeline-app-profile-header-' + i + '.jpg',
          });
          i++;
        }
      this.covers = avatars;
    }
    return this.covers;
  }

  getDefault() {
    return this.defaultCover;
  }

  select() {
    return new Promise(async (resolve, reject) => {
      let avatars = await this.getAll();
      let modal = await this.modalCtrl.create({
        component: ChooseCoverPage,
        componentProps: {
          avatars: avatars,
          coversService: this,
        },
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: 'avatarModal'
      });
      modal.onWillDismiss().then((response: any) => {
        resolve(response && response.data && response.data.src ? this.urlPrefix + response.data.src.replace('./', '/') : null);
      });
      modal.present();
    });
  }

}
