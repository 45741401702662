import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ShareInternalPage } from '../pages/share-internal/share-internal.page';
import { Share } from '@capacitor/share';

import * as jQuery from 'jquery';

import { SearchService} from './search.service';
import { TranslationService } from './translation.service';
import { ToolsService } from './tools.service';
import { UserService } from './user.service';

import { SharingModalPage } from '../pages/modals/sharing-modal/sharing-modal.page';
import { AppcmsService } from './appcms.service';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  constructor(
    private AppCMS: AppcmsService,
    private modalCtrl: ModalController,
    private search: SearchService,
    private translation: TranslationService,
    private tools: ToolsService,
    public userService: UserService,
  ) {
  }

  getURLMetaData(url: string) {
    return new Promise((resolve, reject) => {
      let proxyUrl: string = `${this.search.getProxyUrl()}${url}`;

      this.AppCMS.loadUrl(proxyUrl, {}, true)
      .then((response: any) => {
        this.parseURLMetaDataResponse(response).then(resolve).catch(reject);
      })
      .catch((response: any) => {
        this.parseURLMetaDataResponse(response).then(resolve).catch(reject);
      });
    });
  }

  async internal(post: post) {
    let shareModal = await this.modalCtrl.create({
      component: ShareInternalPage,
      componentProps: {
        post: post,
        sharingService: this,
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'bottomModal',
      breakpoints: [0.5,1],
      initialBreakpoint: 0.5,
    });
    shareModal.onWillDismiss().then((event: any) => {
      //console.log('event', event);
    });
    await shareModal.present();
  }

  parseURLMetaDataResponse(response: any) {
    return new Promise((resolve, reject) => {
      let meta: any = {},
          metaExplode = (response || '').split('<meta ');

      metaExplode.shift();

      metaExplode.forEach((metaLine: string) => {
        metaLine = `<meta ${metaLine.replace('\\', '')}`;
        
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(metaLine, 'text/html'),
            tag = htmlDoc.getElementsByTagName('meta')[0],
            name = tag.getAttribute('name') || tag.getAttribute('property'),
            content = tag.getAttribute('content');

        if(!!name && !!content) {
          meta[name] = content;
        }

      });

      resolve(meta);
    });
  }

  share(message: string = null, title: string = null, image: string = null, url: string = null) {
    return new Promise((resolve, reject) => {
      this.translation.get('share')
        .subscribe(async (response: any) => {
          try {
            Share.share({
              title: title,
              text: message,
              url: url,
              dialogTitle: response || 'share'
            })
            .then(resolve)
            .catch((error: any) => {
              console.warn('sare error', error);
              this.shareUsingModal({
                message: message,
                title: title,
                image: image,
                url: url,
              })
              .then(resolve)
              .catch(reject);
            });
          } catch(e) {
            this.shareUsingModal({
              message: message,
              title: title,
              image: image,
              url: url,
            }).then(resolve).catch(reject);
          }
        });
    });
  }

  sharePost(post: post) {
    let slugExplode = jQuery.trim(post.url, '/').split('/'), slug = slugExplode[slugExplode.length-1];
    slug = (slug ||post.uid);
    slug = (slug + '').replace('?p=', '');

    let url = `https://open.pipeline.page/${slug}`;

    this.translation.get([
      'share_post_text',
      'share_post_headline',
    ])
    .subscribe((translations: any) => {
      this.share(
        translations.share_post_text || 'share_post_text',
        translations.share_post_headline || 'share_post_headline',
        post.thumbnail,
        url,
      );
    });
  }

  shareProfile(profile: user) {
    let url = 'https://web.pipeline.page/tabs/profile/' + profile.uid;

    this.translation.get([
      'share_profile_headline',
      'share_profile_text',
    ])
    .subscribe((translations: any) => {
      this.share(
        translations.share_profile_text || 'share_profile_text',
        translations.share_profile_headline || "share_profile_headline",
        profile.photo,
        url,
      );
    });
  }

  async shareUsingModal(shareOptions: any) {
    let modal = await this.modalCtrl.create({
      component: SharingModalPage,
      componentProps: {
        shareOptions: shareOptions,
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'sharingModal'
    });

    modal.present();
  }

  shareViaEmail(message: string, subject: string, to: string[], css: string[], bcc: string[], files: string[]) {
    //return this.socialSharing.shareViaEmail(message, subject, to, css, bcc, files);
  }
  
}
