import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ToolsService } from '../../../services/tools.service';

import SwiperCore, { Autoplay, Navigation, Pagination} from 'swiper';
SwiperCore.use([Autoplay, Navigation, Pagination]);

@Component({
  selector: 'pipeline-shop-products-slider',
  templateUrl: './shop-products-slider.component.html',
  styleUrls: ['./shop-products-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShopProductsSliderComponent implements OnInit {
  @Input() products: product[];

  shopProductsSliderOptions: any = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    slidesPerGroup: (window.innerWidth > 768 ? 4 : 2),
    slidesPerView: (window.innerWidth > 768 ? 4 : 2),
  };

  private swiper: any;

  view: any = {
    products: [{},{},{},{},{},{}],
  };

  constructor(
    private tools: ToolsService,
  ) {

  }

  ngOnInit() {
    this.view.products = this.tools.shuffle(this.products);
  }

  setSwiperInstance(event: any) {
    this.swiper = event;
  }

}
