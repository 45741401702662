<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
      </ion-buttons>
    <ion-title [innerHTML]="'share'|translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true">

  <ion-card>
    <ion-card-content>
      <p [innerHTML]="'share_fallback_text'|translate"></p>
    </ion-card-content>
    <ion-list lines="none">
      <ion-item *ngIf="shareOptions.title && shareOptions.title.length">
        <ion-label position="stacked" [innerHTML]="'subject'|translate"></ion-label>
        <ion-input [(ngModel)]="shareOptions.title" [placeholder]="'subject'|translate"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked" [innerHTML]="'message'|translate"></ion-label>
        <ion-textarea rows="5" [(ngModel)]="shareOptions.message" [placeholder]="'message'|translate"></ion-textarea>
      </ion-item>
      <ion-item *ngIf="shareOptions.url && shareOptions.url.length">
        <ion-label position="stacked" [innerHTML]="'url'|translate"></ion-label>
        <ion-input [(ngModel)]="shareOptions.url" disabled [placeholder]="'url'|translate"></ion-input>
      </ion-item>
    </ion-list>
    <ion-card-content>
      <ion-button size="block" (click)="copyToClipboard()">
        <ion-label [innerHTML]="'copy_to_clipboard'|translate"></ion-label>
      </ion-button>
    </ion-card-content>
  </ion-card>

</ion-content>
