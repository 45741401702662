<ion-header translucent="true" class="ion-no-border">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'verified_info_title'|translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-card>
    <ion-card-header>
      <ion-card-title [innerHTML]="'verified_info_headline'|translate"></ion-card-title>
      <ion-card-subtitle [innerHTML]="'verified_info_subheadline'|translate"></ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <p [innerHTML]="'verified_info_text'|translate"></p>
      <br>
      <p [innerHTML]="'verified_info_text_2'|translate"></p>
      <br>
      <p [innerHTML]="'verified_info_text_3'|translate"></p>
    </ion-card-content>
  </ion-card>

</ion-content>
