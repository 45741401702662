import { Injectable } from "@angular/core";
import { ActionSheetController, LoadingController } from "@ionic/angular";

import { AppcmsService } from "./appcms.service";
import { UserService } from "./user.service";
import { TranslationService } from "./translation.service";
import { AvatarService } from "./avatar.service";
import { CoversService } from "./covers.service";
import { ModalService } from "./modal.service";

import { Camera, CameraResultType } from '@capacitor/camera';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';

import * as $ from 'jquery';

import { WebUploaderPage } from "../pages/web-uploader/web-uploader.page";
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: "root",
})
export class MediaextendService {

  hostUrl: string;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private AppCMS: AppcmsService,
    private avatar: AvatarService,
    private config: ConfigService,
    private cover: CoversService,
    private loadingCtrl: LoadingController,
    private modalService: ModalService,
    private photoViewer: PhotoViewer,
    private translations: TranslationService,
    public userService: UserService,
  ) {
    this.hostUrl = this.config.getHostUrl();
  }

  async apply(sourceType: any, options: any = {}) {

    const image = await Camera.getPhoto({
      quality: 85,
      allowEditing: true,
      resultType: CameraResultType.Uri
    });
    console.log('> image', image);

    var imageUrl = image.webPath;
    console.log('> imageUrl', imageUrl);

    return new Promise((resolve, reject) => {

      /*
      options = Object.assign(options, {
        quality: 75,
        destinationType: this.camera.DestinationType.FILE_URI,
        sourceType: sourceType, // 0:Photo Library, 1=Camera, 2=Saved Photo Album
        encodingType: 0, // 0=JPG 1=PNG
      });
     
      this.camera
        .getPicture(options)
        .then((fileUrl) => {
          this.crop
            .crop(fileUrl, {
              quality: 80,
              targetHeight: 1080,
              targetWidth: 1920,
            })
            .then((newPath) => {
              this.onCapturePhoto(newPath.split("?")[0])
                .then(resolve)
                .catch((error: any) => {
                  console.warn('error 3', error);
                  reject(error);
                });
            })
            .catch((error: any) => {
              console.warn('error 2', error);
              reject(error);
            });
        })
        .catch((error: any) => {
          console.warn('error 1', error);
          if (error === 'cordova_not_available') {
            this.applyFromWeb(null, options)
              .then(resolve)
              .catch((error: any) => {
                console.warn('error 4', error);
                reject(error);
              });
          } else {
            reject(error);
          }
        });
        */
    });
    
  }

  applyFromWeb(fileURI: string = null, options: any = {}) {
    return new Promise(async (resolve, reject) => {

      let webUploader = await this.modalService.create({
        component: WebUploaderPage,
        componentProps: Object.assign(options, {
          fileURI: fileURI,
          mediaService: this,
          modalCtrl: this.modalService.getController(),
        }),
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: 'webUploaderModal'
      });

      webUploader.onDidDismiss().then((response: any) => {
        if (response.data && (response.data.code || response.data.url)) {
          resolve(response.data.code || response.data.url);
        } else {
          reject(response.data);
        }
      });

      await webUploader.present();
    });
  }

  applyYouTubeVideo(options: any = {}) {
    return this.applyFromWeb(null, Object.assign(options, {
      source: 'youtube',
    }));
  }

  async chooseFromMedia(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      let buttons = [], chooseKey = ('choose_' + options.location);

      this.translations.get([
        'add_attachment',
        'cancel',
        chooseKey,
        'delete_photo',
        'take_photo',
        'upload_photo',
        'view_attachment',
        'view_photo',
      ])
        .subscribe(async (response: any) => {

          if (options.admin) {

            if(!!this.config.useAvatars()) {
              buttons.push({
                text: response[chooseKey] || chooseKey,
                handler: () => {
                  let provider = options.location === 'cover' ? this.cover : this.avatar;
                  provider.select()
                    .then(resolve)
                    .catch(reject);
                },
              });
            }

            buttons.push({
              text: response.take_photo || 'take_photo',
              handler: () => {
                this.apply(null, options) // this.camera.PictureSourceType.CAMERA
                  .then(resolve)
                  .catch(reject);
              },
            });
            buttons.push({
              text: response.upload_photo || 'upload_photo',
              handler: () => {
                this.apply(null, options) // this.camera.PictureSourceType.PHOTOLIBRARY
                  .then(resolve)
                  .catch(reject);
              },
            });
            if (options.photo) {
              buttons.push({
                text: response.view_photo || 'view_photo',
                handler: () => {
                  this.photoViewer.show(options.photo);
                },
              });
              buttons.push({
                text: response.delete_photo || 'delete_photo',
                color: 'danger',
                handler: () => {
                  this.removeImage(options.photo, options.photoType)
                    .then(resolve).catch(reject);
                },
              });
            }
            buttons.push({
              text: response.cancel || 'cancel',
              role: "cancel",
            });
          } else
            if (options.photo) {
              buttons.push({
                text: response.view_photo || 'view_photo',
                handler: () => {
                  this.photoViewer.show(options.photo);
                },
              });
            }

          let actionSheet = await this.actionSheetCtrl.create({
            header: options.admin ? (response.add_attachment || 'add_attachment') : (response.view_attachment || 'view_attachment'),
            buttons: buttons,
          });
          await actionSheet.present();
        });
    });
  }

  clearCache() {
    //this.camera.cleanup();
  }

  deleteMediaItem(mediaId: number) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {},
      [mediaId, 'delete'],
    );
  }

  getMediaList(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      options = options || {};

      this.AppCMS.loadPluginData(
        "pipeline",
        {
          filter: options,
        },
        ['media', 'list'],
        null,
        blForceRefresh
      )
        .then((mediaList: any[]) => {
          if (mediaList && mediaList.length) {
            mediaList.forEach((mediaItem: any, index: number) => {
              mediaList[index] = this.parseSingleMediaItem(mediaItem);
            });
          }
          resolve(mediaList);
        })
        .catch(reject);
    });
  }

  async onCapturePhoto(fileURI: string) {
    return new Promise(async (resolve, reject) => {
      let loading = await this.loadingCtrl.create({
        spinner: 'circular',
      });
      loading.present();

      this.upload(fileURI)
        .then((response: any) => {
          loading.dismiss();

          if (
            response.link &&
            response.link.thumbnails &&
            response.link.thumbnails.md
          ) {
            let image =
              response.link.thumbnails.md.src ||
              response.link.thumbnails.lg.src;
            if (image[0] === "/") {
              image = this.hostUrl + "/" + image;
            }
            resolve(image);
          } else {
            reject(response);
          }
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  parseSingleMediaItem(mediaItem: any) {
    return {
      mime_type: mediaItem.mine_type || mediaItem.post_mime_type,
      name: mediaItem.name || mediaItem.post_title,
      photo: mediaItem.photo || mediaItem.thumbnail,
      timestamp: mediaItem.timestamp || mediaItem.post_date,
      //timestamp_formatted: mediaItem.timestamp_formatted || moment(mediaItem.post_date).format('DD.MM.YYYY HH:mm'),
      uid: parseInt(mediaItem.uid),
      url: mediaItem.url || mediaItem.guid,
    } as mediaItem;
  }

  removeImage(image: string, type: string = 'photo') {
    return new Promise((resolve, reject) => {
      let user = this.userService.getUser() || {};
      switch (type) {
        case 'cover':
          user.classifications.cover = '';
          user.classifications.coverImage = '';
          this.userService.setUser(user, true).then(resolve).catch(reject);
          break;
        case 'photo':
          user.photo = '';
          this.userService.setUser(user, true).then(resolve).catch(reject);
          break;
      }
    });
  }

  async upload(fileURI: string) {
    console.log('> upload', fileURI);

    let d = new Date();
    let apiCredentials = this.userService.getUser(true);
    let fileName = this.userService.getUid() + "-" + d.getMilliseconds() + ".jpg";

    const response = await fetch(fileURI);
    const blob = await response.blob();

    let url = this.AppCMS.getApiUrl() + "/mediaextend/upload.json";

    let user = {
      email: apiCredentials.email,
      password: apiCredentials.password,
    };

    const formData = new FormData();
    formData.append('file', blob, fileName);
    formData.append('user[email]', user.email);
    formData.append('user[password]', user.password);
  }

  async uploadUsingWeb(fileURI: string, files: any = null) {
    return new Promise((resolve, reject) => {
      var file_data = files ? files[0] : null;
      var form_data = new FormData();
      form_data.append("file", file_data);

      $.ajax({
        url: this.AppCMS.getApiUrl() + "/mediaextend/upload.json",
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        data: form_data,
        type: 'post',
        success: (response: any) => {
          resolve(
            response && response.success ? response.link : response
          );
        },
        error: (error: any) => {
          console.warn('error', error);
          reject(error);
        },
      });
    });
  }

}
