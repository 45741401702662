import { Injectable } from '@angular/core';

import { AppcmsService } from '../services/appcms.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    public AppCMS: AppcmsService,
    private configService: ConfigService,
  ) {

  }

  createEvent(event: calendarEvent) {
    //console.log('> create event', event);
  }

  getEventByUid(eventId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['calendar', eventId]);
  }

  getEvents(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      let calendarUids: number[] = this.configService.filterEventsByCalendarUids();
      
      if(!!calendarUids && !!calendarUids.length) {
        options.calendars = calendarUids;
      }
      
      this.AppCMS.loadPluginData('pipeline', options, ['calendar'])
      .then((response: any) => {
        if(response && response.result && response.result.length) {
          response.result.forEach((event: calendarEvent) => {
            event = this.getFullEvent(event);
          });
        }
        resolve(response);
      })
      .catch(reject);
    });
  }

  getFullEvent(event: calendarEvent) {
    return event;
  }

  sendUserResponseToCalendarEvent(eventId: number, iResponse: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: eventId,
      response: iResponse,
    }, ['calendar', 'sendUserResponseToCalendarEvent']);
  }

}