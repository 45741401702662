import { Injectable } from '@angular/core';

import { AppTrackingTransparency } from "capacitor-ios-app-tracking";

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(
  ) {

  }

  askTrackingPermission() {
    try {
      AppTrackingTransparency.requestPermission()
      .catch((e: any) => {
        console.warn('request tracking permission error (2)', e);
      });
    } catch(e) {
      console.warn('request tracking permission error (1)', e);
    }
  }
  
  readTrackingPermission() {
    try {
      AppTrackingTransparency.getTrackingStatus()
      .then((response: any) => {
        //console.log('read tracking permission response', response);
      })
      .catch((e: any) => {
        console.warn('read tracking permission error (2)', e);
      });
    } catch(e) {
      console.warn('read tracking permission error (1)', e);
    }
  }

}