import { Injectable } from '@angular/core';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root'
})
export class ShortcodesService {

  constructor(
    private tools: ToolsService,
  ) {

  }

  applyWordPressShortcodes(postContent: string, wpShortcodes: any[]) {
    if(wpShortcodes && wpShortcodes.length) {
      wpShortcodes.forEach((shortcode: any) => {
        let attributes = '';

        Object.keys(shortcode).forEach((shortcodeKey) => {
          if(shortcodeKey !== 'id') {
            attributes += ' ' + shortcodeKey + '="' + shortcode[shortcodeKey] + '"';
          }
        });
        
        let shortcodeSearch = '[' + shortcode.id + attributes + ']';
        let shortcodeReplace = '<div product-viewer>' +
          '<h3>Produkt Name</h3>' +
          '<small>Preis</small>' +
          '<a href="/basket">In den Warenkorb</a>' +
        '</div>';

        postContent = postContent.replace(shortcodeSearch, shortcodeReplace);
      });
    }
    return postContent;
  }


  onAudioClick() {
    
  }

  parseShortcodes(postContent: any) {

    if(!postContent || !postContent.length) {
      return '';
    }

    let wpShortcodes = this.parseWordPressShortcodes(postContent);

    if(wpShortcodes && wpShortcodes.length) {
      postContent = this.applyWordPressShortcodes(postContent, wpShortcodes);
    }

    let el = document.createElement('body');
    el.innerHTML = postContent;

    //let captions = el.querySelectorAll('figure.wp-caption');
    let youtubeEmbeds = el.querySelectorAll('figure.wp-block-embed-youtube');
    let galleries = el.querySelectorAll('figure.wp-block-gallery');
    let audios = el.querySelectorAll('figure.wp-block-audio');
    

    if(audios && audios.length) {
      audios.forEach((audio: any) => {
        let audioReplacement = document.createElement('ion-button');
        audioReplacement.onclick = this.onAudioClick,
        audioReplacement.setAttribute('size', 'block');
        audioReplacement.innerHTML = '<ion-icon name="play-outline" slot="start"></ion-icon>';
        audioReplacement.innerHTML += '<ion-label>Abspielen</ion-label>';
        el.innerHTML = el.innerHTML.replace(audio.outerHTML, audioReplacement.outerHTML);
      });
    }

    if(galleries && galleries.length) {
      galleries.forEach((gallery: any) => {
        let galleryReplacement = document.createElement('ion-slides');
        galleryReplacement.setAttribute('pager', 'true');
        galleryReplacement.setAttribute('options', 'innerSliderOptions');
        let galleryItems = gallery.querySelectorAll('ul > li');
        
        if(galleryItems && galleryItems.length) {
          galleryItems.forEach((galleryItem: any) => {
            galleryReplacement.innerHTML += '<ion-slide>' + galleryItem.innerHTML + '</ion-slide>';
          });
        }

        el.innerHTML = el.innerHTML.replace(gallery.outerHTML, galleryReplacement.outerHTML);
      });
    }

    if(youtubeEmbeds && youtubeEmbeds.length) {
      youtubeEmbeds.forEach((youtubeEmbed: any) => {
        let innerUrl = this.tools.stripHtml(youtubeEmbed.innerHTML), urlExplode = innerUrl.split('?v='), uid= urlExplode[urlExplode.length-1];
        let iframe = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + uid + '" frameborder="0"' +
        'allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>' +
        '</iframe>';
        el.innerHTML = el.innerHTML.replace(youtubeEmbed.outerHTML, iframe);
      });
    }

    return el.innerHTML;
  }

  parseWordPressShortcodes(str: string) {
    var regex = /\[\S+(?:\s+[^="]+="[^"\]\s]+")+\]/g,
        m, obj, result = [];
    while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
      regex.lastIndex++;
        }
        m = m[0].slice(1, -1).split(/\s+/);
        result.push(m.reduce((r, s) => {
          var pair = s.split('=');
          if(pair[0] && pair[1]) {
            r[pair[0]] = +pair[1].slice(1,-1);
          }
          return r;
        }, {id: m.shift()}));		
    }
    return result;
  }

  prepare(post: post, key: string) {
    post[key] = this.parseShortcodes(post[key]);
    post[key] = this.tools.cleanContent(post, key);
    return post;
  }

}
