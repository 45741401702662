import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

import { TranslationService } from "./translation.service";
import { ToolsService } from "./tools.service";

import { Clipboard } from '@capacitor/clipboard';

@Injectable({
  providedIn: "root",
})
export class ClipboardService {

  constructor(
    private toastCtrl: ToastController,
    public tools: ToolsService,
    private translations: TranslationService
  ) {}

  afterCopySuccess() {
    this.translations
      .get("copy_to_clipboard_success")
      .subscribe(async (translation) => {
        let toast = await this.toastCtrl.create({
          message: translation,
          duration: 1000,
        });
        toast.present();
      });
  }

  copyText(text: string) {

    if(this.tools.isWeb()) {
      return this.copyTextWeb(text);
    }

    try {
      Clipboard.write({
        string: text,
      });
    } catch(e) {
      console.warn('> copy: native error (1)', e);
      return this.copyTextWeb(text);
    }
  }

  copyTextWeb(text: string) {
    return new Promise((resolve, reject) => {
      var textArea = document.createElement("textarea");

      textArea.style.position = "fixed";
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";

      textArea.value = text;

      document.body.appendChild(textArea);
      textArea.select();

      try {
        let exec = document.execCommand("copy");

        this.afterCopySuccess();

        resolve(!!exec);
      } catch (err) {
        console.warn('copy: web error', err);
        reject(err);
      }
      
      document.body.removeChild(textArea);
    });
  }

}
