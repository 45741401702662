import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WeloveService {

  constructor(

  ) {

  }

  getPaymentOptions() {
    return [

    ];
  }

  pay() {
    return new Promise(async (resolve, reject) => {
      let paymentOptions = this.getPaymentOptions();
      console.log('welove: pay', paymentOptions);

      resolve(paymentOptions);
    });
  }

}
