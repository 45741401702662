import { Component, NgZone, OnInit } from '@angular/core';
import { LoadingController, NavParams } from '@ionic/angular';
import { ConfigService } from '../../services/config.service';
import { EventsService } from '../../services/events.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-web-uploader',
  templateUrl: './web-uploader.page.html',
  styleUrls: ['./web-uploader.page.scss'],
})
export class WebUploaderPage implements OnInit {

  config: pipelineAppConfig;
  
  media: any;

  view: any = {
    allowedExtensions: ['jpg', 'jpeg', 'gif', 'png', 'svg', 'webp'],
    canSubmit: false,
    segment: 'upload',
    type: 'url',
  }

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private loading: LoadingController,
    private modalService: ModalService,
    private navParams: NavParams,
    private zone: NgZone,
  ) {
    this.config = this.configService.getConfig();
    this.media = this.navParams.get('mediaService');

    this.view.source = this.navParams.get('source');
    this.calcAllowedOptions();
  }

  calcAllowedOptions() {
    this.zone.run(() => {
      switch(this.view.source) {
        case 'youtube':
          this.view.allowFileUpload = false;
          this.view.allowUrl = true;
          this.view.allowYoutubeEmbed = true;
          break;
        default:
          this.view.allowFileUpload = true;
          this.view.allowUrl = true;
          this.view.allowYoutubeEmbed = false;
          break;
      }
    });
  }

  dismiss(data: any = null) {
    return this.modalService.dismiss(data);
  }

  handleMediaListSelectionUpdated(view: any) {
    let selectedItems: mediaItem[] = (view && !!view.selected ? view.selected : []);
    let item: mediaItem = (!!selectedItems && selectedItems[0] ? selectedItems[0] : null);

    this.view.canSubmit = (item && !!item.photo);

    if(this.view.canSubmit) {
      this.view.url = item.photo;
    }

  }

  hideLoading() {
    if(this.view.loading) {
      this.view.loading.dismiss();
    }
  }

  initEvents() {
    this.events.subscribe('media:list:selection:updated', (view: any) => {
      this.handleMediaListSelectionUpdated(view);
    });
  }

  initWebUploads() {
    let input = document.querySelector('input[type="file"][class="web-uploader-file"]');
    let _this = this;

    if(!!input) {
      input.addEventListener('change', function() {
        if (this.files && this.files[0]) {
            var img: any = document.querySelector('img[class="thumbnail-preview"]');
  
            try {
              img.onload = () => {
                URL.revokeObjectURL(img.src);  // no longer needed, free memory
              }
              img.src = URL.createObjectURL(this.files[0]); // set src to blob url
              
              _this.showLoading();
              _this.media.uploadUsingWeb(img.src, this.files)
              .then((response: any) => {
                if(response.thumbnail) {
                  _this.view.url = response.thumbnail;
                  _this.view.canSubmit = true;
                  _this.hideLoading();
                }
              })
              .catch((error: any) => {
                _this.events.publish('error', error);
                _this.hideLoading();
              });
    
            } catch(e) {
              console.warn('> web upload error', e);
            }
        }
      });
    }

  }

  ionImgDidLoad(event: any = null) {
    this.view.canSubmit = true;
    console.log('> ionImgDidLoad: this.view.canSubmit', this.view.canSubmit);
  }

  ionViewWillEnter() {
    this.initWebUploads();
  }

  ngOnInit() {
    this.initEvents();
  }

  onTypeChanged() {
    //console.log('onTypeChanged', this.view.type);
  }

  async showLoading() {
    this.view.loading = this.view.loading || (await this.loading.create({
      backdropDismiss: false,
      spinner: 'circular',
    }));
    this.view.loading.present();
  }

  submit() {
    this.validateBeforeSubmit()
    .then(() => {
      this.dismiss(this.view);
    })
    .catch((error: any) => {
      console.warn('error!');
      this.events.publish('error', error);
    });
  }

  thumbnailLoadingFailed() {
    setTimeout(() => {
      this.zone.run(() => {
        this.view.hasThumbnailImg = false;
        this.view.thumbnailImg = false;

        console.log('> thumbnailLoadingFailed: this.view.canSubmit', `${this.view.url}`, this.view.canSubmit);
      });
    });
  }

  urlChanged() {

    if(this.view.source === 'youtube') {
      return this.youtubeUrlChanged();
    }

    let extensionExplode = (this.view.url || '').split('.'),
        extension = extensionExplode[extensionExplode.length-1];

    this.zone.run(() => {
      this.view.hasThumbnailImg = !!(extension && (this.view.allowedExtensions.indexOf(extension) !== -1));
      this.view.canSubmit = !!this.view.hasThumbnailImg;

      if(this.view.hasThumbnailImg) {
        this.view.thumbnailImg = this.view.url;
      }
    });
  }

  validateBeforeSubmit() {
    return new Promise((resolve, reject) => {
      switch(this.view.source) {
        case 'youtube':
          this.validateYouTubeBeforeSubmit().then(resolve).catch(reject);
        default:
          resolve(true);
          break;
      }
    });
  }

  validateYouTubeBeforeSubmit() {
    return new Promise((resolve, reject) => {
      if(this.view.type === 'url') {
        let shortLinkExplode = this.view.url.split('?v='),
            shortLink = shortLinkExplode[shortLinkExplode.length-1],
            embedUrl = `https://www.youtube.com/embed/${shortLink}`;

        this.view.code = `<iframe width="560" height="315" src="${embedUrl}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        resolve(true);
      } else
      if(this.view.type === 'youtube_embed') {
        this.view.code = this.view.youtube_embed_code;
        resolve(true);
      }
    });
  }

  youtubeEmbedCodeChanged() {
    this.zone.run(() => {
      this.view.canSubmit = !!(
        (this.view.youtube_embed_code || '').indexOf('<iframe') !== -1 &&
        (this.view.youtube_embed_code || '').indexOf('https://www.youtube.com/embed/') !== -1 &&
        (this.view.youtube_embed_code || '').indexOf('</iframe>') !== -1
      );
    }); 
  }

  youtubeUrlChanged() {
    this.zone.run(() => {
      this.view.canSubmit = !!((this.view.url || '').indexOf('https://www.youtube.com/watch?v=') !== -1);
    });
  }

}