<ion-header class="ion-no-border">
  
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="white" fill="clear" icon-only>
        <ion-icon name="arrow-back" color="white"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" *ngIf="coupon">
      <ion-button (click)="toggleCollection()" color="white" fill="clear" icon-only>
        <ion-icon [name]="coupon.added ? 'bookmark' : 'bookmark-outline'" color="white"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="coupon" [class.has-location]="!!coupon && !!coupon.location">

  <ion-refresher slot="fixed" (ionRefresh)="dismiss()">
    <ion-refresher-content pullingIcon="close"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <ion-chip color="primary" class="sponsored" *ngIf="coupon && !!coupon.sponsored">
      <ion-label [innerHTML]="'sponsored' | translate"></ion-label>
    </ion-chip>

    <ion-thumbnail *ngIf="!!coupon.photo">
      <ion-img [src]="coupon.photo" [alt]="coupon.title"></ion-img>
    </ion-thumbnail>

    <ion-spinner name="circular" [hidden]="coupon && coupon.uid"></ion-spinner>

    <ion-card id="mainCard">

      <ion-card-header>
        <ion-card-title [innerHTML]="coupon.title"></ion-card-title>
      </ion-card-header>

      <ion-list lines="none">
        <ion-item [hidden]="!coupon.post_date">
          <ion-icon name="calendar-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="coupon.post_date"></ion-label>
        </ion-item>
        <ion-item [hidden]="!coupon.location">
          <ion-icon name="location-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="coupon.location"></ion-label>
        </ion-item>
        <ion-item [hidden]="!coupon.start_date">
          <ion-label [innerHTML]="'date_start'|translate"></ion-label>
          <ion-note [innerHTML]="coupon.start_date_formatted" slot="end"></ion-note>
        </ion-item>
        <ion-item [hidden]="!coupon.end_date">
          <ion-label [innerHTML]="'date_end'|translate"></ion-label>
          <ion-note slot="end" [innerHTML]="coupon.end_date_formatted"></ion-note>
        </ion-item>
      </ion-list>
    </ion-card>

    <ion-card class="couponDescriptionCard" [hidden]="viewData.segment != 'details' || (!coupon.price_html && !coupon.description)">

      <h2 class="price" [hidden]="!coupon.price_html"
        [innerHTML]="coupon.price_html || (!!coupon.price ? coupon.price + '€' : '')"></h2>

      <ion-card-content id="couponDescription" class="post-page" *ngIf="coupon.description">
        <h3 [innerHTML]="'coupon_description'|translate"></h3>
        <p [innerHTML]="coupon.description"></p>
      </ion-card-content>

    </ion-card>

    <ion-card id="couponTicketsCard">
      <ion-card-header>
        <ion-card-subtitle [innerHTML]="'use_coupon'|translate"></ion-card-subtitle>
      </ion-card-header>
      <ion-card-content class="action-buttons" *ngIf="coupon">

        <ion-card *ngIf="!viewData.isLoggedIn">
          <ion-item class="alert error" color="danger">
            
            <ion-label [innerHTML]="'error_action_requires_user_login'|translate" class="ion-text-wrap"></ion-label>

            <ion-button slot="end" (click)="showLoginModal()" color="white">
              <ion-icon name="log-in-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'login'|translate"></ion-label>
            </ion-button>

          </ion-item>
        </ion-card>

        <ion-button *ngIf="viewData.isLoggedIn && viewData.external" [hidden]="viewData.blShowCouponCode" (click)="showCouponCode()" size="block" color="primary">
          <ion-icon name="eye-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'view_coupon'|translate"></ion-label>
        </ion-button>

        <ion-card id="couponInfoCard" *ngIf="viewData.isLoggedIn && viewData.external && viewData.blShowCouponCode">
          <ion-card-content>

            <h1 [innerHTML]="coupon.indent"></h1>
            <p [innerHTML]="'coupon_use_info_text'|translate"></p>

            <ion-button (click)="copyCode()" size="block" [color]="!viewData.copiedCode ? 'primary' : 'medium'">
              <ion-icon name="open-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'copy_coupon_code'|translate"></ion-label>
            </ion-button>

            <ion-button (click)="viewCoupon()" size="block" [color]="viewData.copiedCode ? 'primary' : 'medium'">
              <ion-icon name="open-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'visit_coupon_website'|translate"></ion-label>
            </ion-button>

          </ion-card-content>
        </ion-card>

      </ion-card-content>
    </ion-card>

    <ion-card *ngIf="!!coupon.location">
      <pipeline-google-map [config]="googleMapConfig"></pipeline-google-map>
    </ion-card>

  </div>

</ion-content>