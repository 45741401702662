<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'web_uploader'|translate"></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="submit()" [disabled]="!view.canSubmit" icon-only>
        <ion-icon name="checkmark-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar class="container" *ngIf="config.useMediaExtension">
    <ion-segment [(ngModel)]="view.segment">
      <ion-segment-button [value]="'upload'">
        <ion-label [innerHTML]="'upload'|translate"></ion-label>
      </ion-segment-button>
      <ion-segment-button [value]="'media'">
        <ion-label [innerHTML]="'media'|translate"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <ion-card [hidden]="view.segment != 'upload'">
      <ion-grid>
        <ion-row>
          <ion-col [size]="view.hasThumbnailImg ? 8 : 12">
  
            <ion-radio-group [(ngModel)]="view.type" (ionChange)="onTypeChanged()">
  
              <ion-item lines="none" [hidden]="!view.allowUrl">
                <ion-radio mode="md" color="primary" slot="start" value="url"></ion-radio>
                <ion-label>
                  <h3 [innerHTML]="'upload_type_url'|translate"></h3>
                </ion-label>
              </ion-item>
  
              <ion-item lines="none" [hidden]="view.type !== 'url' || !view.allowUrl">
                <ion-icon name="link-outline" slot="start"></ion-icon>
                <ion-input type="text" [(ngModel)]="view.url" [placeholder]="'url'|translate" (ionChange)="urlChanged()">
                </ion-input>
              </ion-item>
  
              <ion-item lines="none" [hidden]="!view.allowYoutubeEmbed">
                <ion-radio mode="md" color="primary" slot="start" value="youtube_embed"></ion-radio>
                <ion-label>
                  <h3 [innerHTML]="'upload_type_youtube_embed'|translate"></h3>
                </ion-label>
              </ion-item>
  
              <ion-item lines="none" [hidden]="view.type !== 'youtube_embed' || !view.allowYoutubeEmbed">
                <ion-textarea rows="5" [(ngModel)]="view.youtube_embed_code"
                  [placeholder]="'youtube_embed_code'|translate" (ionChange)="youtubeEmbedCodeChanged()"></ion-textarea>
              </ion-item>
  
              <ion-item lines="none" [hidden]="!view.allowFileUpload">
                <ion-radio mode="md" color="primary" slot="start" value="file"></ion-radio>
                <ion-label>
                  <h3 [innerHTML]="'upload_type_file'|translate"></h3>
                </ion-label>
              </ion-item>
  
            </ion-radio-group>
  
            <input [hidden]="view.type !== 'file' || !view.allowFileUpload" type="file" class="web-uploader-file" />
  
          </ion-col>
  
          <ion-col [hidden]="!view.hasThumbnailImg" size="4">
            <img *ngIf="!!view.thumbnailImg" [src]="view.thumbnailImg" [hidden]="!view.url" class="thumbnail-preview"
              (error)="thumbnailLoadingFailed()" (onload)="ionImgDidLoad($event)" />
          </ion-col>
  
        </ion-row>
  
      </ion-grid>
    </ion-card>
  
    <pipeline-media-list *ngIf="config.useMediaExtension" [hidden]="view.segment != 'media'"></pipeline-media-list>

  </div>

</ion-content>