<ion-header translucent="true" class="ion-no-border">
  <ion-toolbar class="ion-no-border container">

      <ion-buttons slot="start">
          <ion-button icon-only (click)="dismiss()">
              <ion-icon name="close"></ion-icon>
          </ion-button>
      </ion-buttons>

      <ion-title [innerHTML]="'report_content'|translate"></ion-title>

  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="container">

    <ion-card>
      <ion-card-header>
        <ion-card-title [innerHTML]="'report_content_title'|translate"></ion-card-title>
        <ion-card-subtitle [innerHTML]="'report_content_subtitle'|translate"></ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <p [innerHTML]="'report_content_info_text'|translate"></p>
      </ion-card-content>
    </ion-card>

    <ion-card>
      <ion-card-header>
        <ion-card-subtitle [innerHTML]="'report_reason'|translate"></ion-card-subtitle>
      </ion-card-header>
      <ion-radio-group [(ngModel)]="view.reason" (ionChange)="onReasonChanged($event)">

        <ion-item *ngFor="let reason of view.reasons">
          <ion-radio slot="start" [value]="reason"></ion-radio>
          <ion-label class="ion-text-wrap" [innerHTML]="('report_reason_' + reason)|translate"></ion-label>
        </ion-item>
  
      </ion-radio-group>
    </ion-card>

  </div>

</ion-content>

<ion-footer>
  <ion-toolbar class="container">
    
    <ion-button (click)="submit()" color="primary" [disabled]="!view.canSubmit" size="block">
      <ion-icon name="checkmark-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'submit'|translate"></ion-label>
    </ion-button>
    
  </ion-toolbar>
</ion-footer>