import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from  '@angular/common/http';

import { Observable, BehaviorSubject, from, of } from  'rxjs';
import { tap, switchMap } from  'rxjs/operators';

import { CacheService } from './../services/cache.service';

import { AuthUser } from  './../interfaces/auth/auth_user';
import { AuthResponse } from  './../interfaces/auth/auth_response';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  currentAccessToken: string|null = null;

  ACCESS_TOKEN_KEY: string = 'ACCESS_TOKEN';

  AUTH_SERVER_ADDRESS: string = 'https://api.pipeline.page/api';
  
  authSubject = new BehaviorSubject(false);

  REFRESH_TOKEN_KEY: 'REFRESH_TOKEN';

  constructor(
    private cache: CacheService,
    private config: ConfigService,
    private httpClient: HttpClient,
  ) {
    if(!!this.config.useAuthExtension()) {
      this.loadToken();
    }
  }

  authorize(user: AuthUser) {
    return new Promise(async (resolve, reject) => {
      try {

        let data: any = {
          access_token: await this.getAccessToken(),
        };
  
        if(!data.access_token) {
          this.login(user).subscribe(
            (response: any) => {
              if(response && response.authorisation && response.authorisation.token) {
                data.access_token = response.authorisation.token;
                this.setAccessToken(response.authorisation.token);
                resolve(data);
              } else {
                reject(response);
              }
            },
            (error: any) => { reject(error); }
          );
        } else {
          resolve(data);
        }
        
      } catch(e) {
        reject(e);
      }
    });
  }

  clearAccessToken() {
    return this.cache.remove(this.ACCESS_TOKEN_KEY);
  }

  clearRefreshToken() {
    return this.cache.remove(this.REFRESH_TOKEN_KEY);
  }

  async getAccessToken() {
    let fromCache: cacheItem = await this.cache.get(this.ACCESS_TOKEN_KEY, -1);
    return (fromCache && !!fromCache.data ? fromCache.data : null);
  }

  getAuthorizationBasicToken(user: AuthUser) {
    var token = `${user.email}:${user.password}`;
    return `Basic ${btoa(token)}`;
  }

  getNewAccessToken() {
    const refreshToken = this.getRefreshToken();

    return refreshToken.pipe(
      switchMap((token: any) => {
        if (token && token.value) {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.value}`
            })
          }
          return this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/auth/refresh`, httpOptions);
        } else {
          return of(null);
        }
      })
    );
  }

  getRefreshToken() {
    return from(this.cache.get(this.REFRESH_TOKEN_KEY));
  }

  isLoggedIn() {
    return this.authSubject.asObservable();
  }

  async loadToken() {
    const token = await this.getAccessToken();
    if (!!token) {
      this.currentAccessToken = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(user: AuthUser): Observable<AuthResponse> {
    return this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/login`, user).pipe(
      tap(async (res: AuthResponse) => {

        if (res.user) {
          this.setAccessToken(res.user.access_token);
          await this.cache.set("EXPIRES_IN", res.user.expires_in);
          this.authSubject.next(true);
        }
      })
    );
  }

  async logout() {
    await this.cache.remove(this.ACCESS_TOKEN_KEY);
    await this.cache.remove("EXPIRES_IN");
    this.authSubject.next(false);
  }

  refresh(user: AuthUser): Observable<AuthResponse> {
    return this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/refresh`, user).pipe(
      tap(async (res: AuthResponse) => {
        if (res.user) {
          await this.cache.set(this.ACCESS_TOKEN_KEY, res.user.access_token);
          await this.cache.set("EXPIRES_IN", res.user.expires_in);
          this.authSubject.next(true);
        }
      })
    );
  }

  register(user: AuthUser): Observable<AuthResponse> {
    return this.httpClient.post<AuthResponse>(`${this.AUTH_SERVER_ADDRESS}/register`, user).pipe(
      tap(async (res: AuthResponse) => {

        if (res.user) {
          await this.cache.set(this.ACCESS_TOKEN_KEY, res.user.access_token);
          await this.cache.set("EXPIRES_IN", res.user.expires_in);
          this.authSubject.next(true);
        }
      })

    );
  }

  setAccessToken(token: string) {
    this.currentAccessToken = token;
    return from(this.cache.set(this.ACCESS_TOKEN_KEY, token));
  }

}