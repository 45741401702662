<ion-header class="ion-no-border">
  
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="white" fill="clear" icon-only>
        <ion-icon name="arrow-back" color="white"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" *ngIf="event">
      <ion-button (click)="toggleCollection()" color="white" fill="clear" icon-only>
        <ion-icon [name]="event.added ? 'bookmark' : 'bookmark-outline'" color="white"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="event">

  <ion-refresher slot="fixed" (ionRefresh)="dismiss()">
    <ion-refresher-content pullingIcon="close"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <ion-chip color="primary" class="sponsored" *ngIf="event && !!event.sponsored">
      <ion-label [innerHTML]="'sponsored' | translate"></ion-label>
    </ion-chip>

    <ion-thumbnail *ngIf="!!event.image">
      <ion-img [src]="event.image" [alt]="event.name"></ion-img>
    </ion-thumbnail>

    <ion-spinner name="circular" [hidden]="event && event.id"></ion-spinner>

    <ion-card>

      <ion-fab vertical="end" edge *ngIf="event.amount && event.amount > 0" class="basketBtn" (click)="_basket()"
        horizontal="end">
        <ion-fab-button>
          <ion-icon name="cart-outline"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <ion-card-header>
        <ion-card-title [innerHTML]="event.name"></ion-card-title>
      </ion-card-header>

      <ion-list lines="none">
        <ion-item [hidden]="!event.post_date">
          <ion-icon name="calendar-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="event.post_date"></ion-label>
        </ion-item>
        <ion-item [hidden]="!event.location">
          <ion-icon name="location-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="event.location"></ion-label>
        </ion-item>
        <ion-item [hidden]="!event.start_time">
          <ion-icon name="time-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="event.start_time"></ion-label>
        </ion-item>
        <ion-item [hidden]="!event.url" (click)="viewEvent()">
          <ion-icon name="open-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'view_event'|translate"></ion-label>
        </ion-item>
      </ion-list>

      <ion-segment [(ngModel)]="viewData.segment">
        <ion-segment-button value="details">
          <ion-label [innerHTML]="'details'|translate"></ion-label>
        </ion-segment-button>
        <ion-segment-button value="people" [disabled]="!viewData.isLoggedIn">
          <ion-label [innerHTML]="'people'|translate"></ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-card>

    <ion-card [hidden]="viewData.segment != 'details' || (!event.price_html && !event.description_formatted)">

      <h2 class="price" [hidden]="!event.price_html"
        [innerHTML]="event.price_html || (!!event.price ? event.price + '€' : '')"></h2>

      <ion-card-content id="eventDescription" class="post-page" *ngIf="event.description_formatted">
        <h3 [innerHTML]="'event_description'|translate"></h3>
        <p [innerHTML]="event.description_formatted"></p>
      </ion-card-content>

    </ion-card>

    <ion-card class="eventDetailsPeopleCard" *ngIf="!!viewData.isLoggedIn" [hidden]="viewData.segment != 'people'">

      <ion-segment [(ngModel)]="viewData.user_response_segment" *ngIf="!!viewData.userResponsesByValue">
        <ion-segment-button [value]="1">
          <ion-label [innerHTML]="('event_user_response_1'|translate) + ' (' + viewData.userResponsesByValue[1] + ')'"></ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="0">
          <ion-label [innerHTML]="('event_user_response_0'|translate) + ' (' + viewData.userResponsesByValue[0] + ')'"></ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="-1">
          <ion-label [innerHTML]="('event_user_response_-1'|translate) + ' (' + viewData.userResponsesByValue[-1] + ')'"></ion-label>
        </ion-segment-button>
      </ion-segment>

      <ion-card class="noEntriesCard" *ngIf="!!viewData.user_response_segment && !viewData.userResponsesByValue[viewData.user_response_segment]">
        <ion-card-content>
            <ion-icon name="warning-outline"></ion-icon>
            <ion-card-title [innerHTML]="('no_event_user_response_' + viewData.user_response_segment)|translate"></ion-card-title>
        </ion-card-content>
      </ion-card>

      <ion-list *ngIf="event && event.user_responses">
        <ion-item *ngFor="let response of event.user_responses" [hidden]="!!viewData.user_response_segment && (viewData.user_response_segment != response.value)">
          <ion-avatar slot="start" *ngIf="response.user" (click)="viewProfile(response.user)">
            <ion-img [src]="response.user.photo" *ngIf="response.user.photo"></ion-img>
            <ion-skeleton-text [hidden]="!!response.user.photo" class="image" [animated]="!!response.user.uid"></ion-skeleton-text>
          </ion-avatar>
          <ion-label (click)="viewProfile(response.user)" *ngIf="response.user && response.user.classifications">
            <h3 [innerHTML]="response.user.classifications.displayName"></h3>
            <p [innerHTML]="('event_user_response_' + response.value)|translate"></p>
            <ion-skeleton-text [hidden]="!!response.user.uid" style="float:left;width:60%;margin-right:10px;" animated>
            </ion-skeleton-text>
            <ion-skeleton-text [hidden]="!!response.user.uid" style="float:left;width:25%;" animated></ion-skeleton-text>
          </ion-label>
          <ion-icon [name]="(response.value === -1 ? 'thumbs-down-outline' : (response.value === 0 ? 'help-circle-outline' : 'thumbs-up-outline'))" [color]="(response.value === -1 ? 'danger' : (response.value === 0 ? 'warning' : 'success'))" slot="end"></ion-icon>
        </ion-item>
      </ion-list>

    </ion-card>

    <ion-card id="eventTicketsCard" *ngIf="!!event.available">
      <ion-card-header>
        <ion-card-subtitle [innerHTML]="'buy_tickets'|translate"></ion-card-subtitle>
      </ion-card-header>
      <ion-card-content class="action-buttons" *ngIf="event">

        <ion-card *ngIf="!viewData.isLoggedIn">
          <ion-item class="alert error" color="danger">
            
            <ion-label [innerHTML]="'error_action_requires_user_login'|translate" class="ion-text-wrap"></ion-label>

            <ion-button slot="end" (click)="showLoginModal()" color="white">
              <ion-icon name="log-in-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'login'|translate"></ion-label>
            </ion-button>

          </ion-item>
        </ion-card>

        <ion-grid id="amountGrid" [hidden]="!viewData.isLoggedIn || viewData.external">
          <ion-row>
            <ion-col size="8">
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-button size="block" fill="clear" color="dark" icon-only (click)="minus()">
                      <ion-icon name="remove"></ion-icon>
                    </ion-button>
                  </ion-col>
                  <ion-col>
                    <ion-input placeholder="0" [(ngModel)]="event.amount" class="amountPicker"></ion-input>
                  </ion-col>
                  <ion-col>
                    <ion-button size="block" fill="clear" icon-only color="dark" (click)="add()">
                      <ion-icon name="add"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
            <ion-col size="4">
              <ion-card-subtitle class="price"
                [innerHTML]="event.price_html || (!!event.price ? event.price + '€' : '')"></ion-card-subtitle>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-button *ngIf="viewData.external" (click)="viewEvent()" size="block" color="primary">
          <ion-icon name="open-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'view_event'|translate"></ion-label>
        </ion-button>

        <ion-button disabled *ngIf="(event.manage_stock && event.stock_status == 'outofstock')" size="block"
          color="medium">
          <ion-icon name="basket" slot="start"></ion-icon>
          <ion-label [innerHTML]="'sold'|translate"></ion-label>
        </ion-button>

      </ion-card-content>
    </ion-card>

    <ion-card>
      <pipeline-google-map [config]="googleMapConfig"></pipeline-google-map>
    </ion-card>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!event.available">
  <ion-toolbar class="container">
    <ion-grid>
      <ion-row>
        <ion-col size="4">
          <ion-button (click)="respond(1)" [disabled]="!viewData.isLoggedIn" fill="clear" color="success">
            <ion-icon name="thumbs-up-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'event_respond_positive'|translate" class="ion-text-wrap"></ion-label>
          </ion-button>
        </ion-col>
        <ion-col size="4">
          <ion-button (click)="respond(0)" [disabled]="!viewData.isLoggedIn" fill="clear" color="dark">
            <ion-icon name="help-circle-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'event_respond_neutral'|translate" class="ion-text-wrap"></ion-label>
          </ion-button>
        </ion-col>
        <ion-col size="4">
          <ion-button (click)="respond(-1)" [disabled]="!viewData.isLoggedIn" fill="clear" color="danger">
            <ion-icon name="thumbs-down-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'event_respond_negative'|translate" class="ion-text-wrap"></ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>