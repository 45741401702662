import { Injectable } from '@angular/core';

import { AppcmsService } from './appcms.service';
import { ChooserService } from './chooser.service';
import { ConfigService } from './config.service';

import { SimplyLocalService } from './simply-local.service';
import { WeclappService } from './weclapp.service';
import { WeloveService } from './welove.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  cards: walletCard[];

  history: walletHistoryItem[];

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private config: ConfigService,
    private simplyLocal: SimplyLocalService,
    private weclapp: WeclappService,
    private welove: WeloveService,
  ) {

  }

  add() {
    return this.pick();
  }

  calculateWalletPoints(basketInfo: basketInfo) {
    if(this.config.useWallet() && this.config.useSimplyLocalExtension()) {
      return parseInt(parseFloat(basketInfo.price_a as any) * 0.1 as any);
    }
    return 0;
  }

  getAllPointsAmount() {
    let iAmount: number = 0,
        cards: walletCard[] = this.getCards(false);

    if(cards && cards.length) {
      cards.forEach((card: walletCard) => {
        if(!!card.points) {
          iAmount += card.points;
        }
      });
    }

    return iAmount;
  }

  getCards(blCalcAmount: boolean = true) {
    if(!this.cards) {

      let weloveCard: walletCard = {
        photo: './assets/img/logo-dark.webp',
        provider: 'welove',
        //subtitle: '+140 in den letzten 30 Tagen',
        title: `WE LOVE`,
      };

      if(!!blCalcAmount) {
        let iPoints: number = 100 || this.getAllPointsAmount();
        weloveCard.points = iPoints;
        weloveCard.title = `${iPoints} Punkte`;
      }

      this.cards = [weloveCard];
    }
    return this.cards;
  }

  getHistory() {
    if(!this.history) {
      this.history = [
        {
          title: '+100 Punkte',
          description: 'Willkommen in der WE LOVE Community',
        },
      ]
    }
    return this.history;
  }
  
  getWallet(blForceRefresh: boolean = true) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['wallet'], null, blForceRefresh);
  }

  pay() {
    return new Promise((resolve, reject) => {
      this.pick()
      .then((response: any) => {
        console.log('selected wallet', response);
        resolve(response);
      })
      .catch(reject);
    });
  }

  pick() {
    return new Promise(async (resolve, reject) => {
      let data: any[] = [];

      if(!!this.config.useSimplyLocalExtension()) {
        data.push({
          handler: this.simplyLocal,
          name: 'Simply Local',
          uid: 'simplylocal',
        }); 
      }

      if(!!this.config.useWeclappExtension()) {
        data.push({
          handler: this.weclapp,
          name: 'weclapp',
          uid: 'weclapp',
        }); 
      }

      if(!!this.config.useWeloveWalletExtension()) {
        data.push({
          handler: this.welove,
          name: 'WE LOVE',
          uid: 'welove',
        }); 
      }

      let chooseConfig: chooseConfig = {
        data: data,
        labelKey: 'name',
        service: this,
        valueKey: 'uid',
      };
      this.chooser.choose(chooseConfig)
      .then((chooseResponse: chooseResponse) => {
        if(!chooseResponse) {
          reject('error_empty_choose_response');
        } else
        if(!chooseResponse.data) {
          reject('error_empty_choose_response_data');
        } else
        if(!chooseResponse.data.item) {
          reject('error_empty_choose_response_item');
        } else
        if(!chooseResponse.data.item.handler) {
          reject('error_empty_choose_response_handler');
        } else {
          chooseResponse.data.item.handler.pay().then(resolve).catch(reject);
        }
      })
      .catch(reject);
    });
  }

}
