<ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
  <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
</ion-refresher>

<div class="noEntriesCard" *ngIf="view.comments && !view.comments.length">
  <ion-card-content>
    <ion-icon name="warning-outline"></ion-icon>
    <ion-card-title [innerHTML]="'no_comments'|translate"></ion-card-title>
  </ion-card-content>
</div>

<div class="comments-list" *ngIf="view.comments && view.comments.length">
  <ion-card *ngFor="let comment of view.comments">
    <ion-list lines="none">
      <ion-item-sliding>
        <ion-item>

          <ion-avatar
            *ngIf="!!comment.user && !!comment.user.photo"
            slot="start"
            (click)="viewProfile(comment.user)"
          >
            <ion-img [src]="comment.user.photo"></ion-img>
          </ion-avatar>

          <ion-label class="ion-text-wrap">
            <h4
              *ngIf="!!comment.user"
              [innerHTML]="comment.user.displayName"
              (click)="viewProfile(comment.user)"
            ></h4>
            <p class="date" [innerHTML]="comment.timestamp_formatted"></p>
            <p [innerHTML]="comment.description"></p>
            <ion-skeleton-text
              [hidden]="comment.description"
              [animated]="!comment.uid"
            ></ion-skeleton-text>
          </ion-label>
        </ion-item>

        <ion-item-options
          slot="end"
          *ngIf="!!comment.uid && comment.user && (comment.user.uid === user.uid)"
        >
          <ion-item-option (click)="deleteComment(comment)">
            <ion-icon name="trash-outline"></ion-icon>
            <ion-label [innerHTML]="'delete'|translate"></ion-label>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>

    <ion-card-header
      class="footer-bar"
      *ngIf="!!comment.uid && appConfig.useReactions"
    >
      <ion-segment
        scrollable
        (ionChange)="onReactionsSegmentChanged()"
        [(ngModel)]="view.reactionsSegment"
        class="reactions has-comments-btn"
        [hidden]="!post.uid"
        *ngIf="appConfig.useReactions"
      >
        <ion-segment-button
          *ngFor="let emoji of view.emojis; let iEmoji = index;"
          (click)="react(comment.uid, emoji)"
          [hidden]="(iEmoji > 2) && !view.expandReactionsView"
          [value]="emoji"
          icon-only
        >
          <ion-label>
            <span [innerHTML]="emoji.emoji"></span>
            <small
              *ngIf="post && post.reactions && post.reactions[emoji.name]"
              [innerHTML]="post.reactions[emoji.name].count"
            ></small>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="expand" icon-only>
          <ion-icon
            [name]="!view.expandReactionsView ? 'chevron-forward-outline' : 'chevron-back-outline'"
          ></ion-icon>
        </ion-segment-button>
      </ion-segment>

      <ion-button
        class="comment-btn"
        fill="clear"
        color="primary"
        icon-only
        (click)="replyToComment(comment)">
        <ion-icon name="chatbubbles-outline"></ion-icon>
      </ion-button>
    </ion-card-header>
  </ion-card>
</div>

<ion-grid class="comments-input-grid" *ngIf="config.showInputToolbar" lines="none">
  <ion-row>

    <ion-col size="10">
      <ion-input type="text"
        [(ngModel)]="view.input"
        [placeholder]="'write_comment'|translate"
        (ionSubmit)="submitComment()"
      ></ion-input>
    </ion-col>

    <ion-col size="2">
      <ion-button
        size="block"
        icon-only
        fill="clear"
        slot="end"
        color="primary"
        (click)="submitComment()"
        [disabled]="!view.input"
      >
        <ion-icon name="send-outline"></ion-icon>
      </ion-button>
    </ion-col>

  </ion-row>
</ion-grid>