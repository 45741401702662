import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  availableLanguages: any[] = [
    'ar',
    'bg',
    'de',
    'da',
    'el',
    'en',
    'es',
    'fi',
    'fr',
    'hu',
    'id',
    'it',
    'ja',
    'ko',
    'lt',
    'nl',
    'no',
    'pl',
    'pt',
    'ro',
    'ru',
    'sv',
    'tr',
    'ur',
  ];

  defaultLanguage: string = 'de';

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    public userService: UserService,
  ) {
  }

  getAvailable() {
    return this.availableLanguages;
  }

  getCurrentLanguage() {
    let user = this.userService.getUser() || {};
    return user && user.classifications ? (user.classifications.language || this.defaultLanguage) : this.defaultLanguage
  }

  getDefaultLanguage() {
    return this.defaultLanguage;
  }

  getLanguages(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let key: string = 'pipeline_languages',
          fromCache: cacheItem = await this.cache.get(key, 60 * 60);

      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData("pipeline", {}, ["languages"])
          .then((languages: language[]) => {
            languages.forEach((language: language) => {
              language.flag = './assets/img/flags/' + language.indent + '.svg';
            });
            this.cache.set(key, languages);
            resolve(languages);
          })
          .catch(reject);
      }
    });
  }

  useTranslator() {
    return this.getCurrentLanguage() !== this.getDefaultLanguage();
  }

}
