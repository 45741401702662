import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { ModalController } from '@ionic/angular';
import { RatePostPage } from '../pages/post/rate-post/rate-post.page';
import { AppcmsService } from './appcms.service';

@Injectable({
  providedIn: 'root'
})
export class RatingsService {

  constructor(
    private AppCMS: AppcmsService,
    private modalCtrl: ModalController,
    public userService: UserService,
  ) {

  }

  get(filter: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', {
      filter: filter,
    }, ['ratings']);
  }

  getByPosts(filter: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', {
      filter: filter,
    }, ['ratingsByPosts']);
  }

  rate(post: post) {
    return new Promise(async (resolve, reject) => {
      let user = this.userService.getUser();

      if(!user || !user.uid) {
        reject('error_missing_user_uid');
        return false;
      }

      let modal = await this.modalCtrl.create({
        component: RatePostPage,
        componentProps: {
          post: post,
          ratingsService: this,
        },
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
      });
      modal.onWillDismiss().then((response: any) => {
        if(response && response.data) {
          this.submit(response.data).then(resolve).catch(reject);
        }
      });
      modal.present();
    });
  }

  submit(rating: any) {
    return new Promise((resolve, reject) => {
      rating = JSON.parse(JSON.stringify(rating));
      delete rating.post.relatedArticles;

      let user = this.userService.getUser() || {};
      rating.user = user.uid;

      if(rating && rating.post && rating.post.uid) {
        rating.post = rating.post.uid;
      }

      delete rating.post_content_formatted;
      delete rating.thumbnailBackground;
      delete rating.style;
      
      this.AppCMS.loadPluginData('pipeline', {
        rating: rating,
      }, ['ratings', 'submit']).then(resolve).catch(reject);
    });
  }
  
}
