export var pipelineAppConfig: pipelineAppConfig = {
    allowUserCreateChat: true,
    allowUserCreateChatGroup: true,
    allowUserEditPostSettings: false,
    allowUserForgotPassword: true,
    allowUserLogin: true,
    allowUserPostContent: false,
    allowUserPostContentAsCreator: true,
    allowUserPostContentAsGuest: false,
    allowUserPostContentAsUser: false,
    allowUserRegister: true,
    allowUserTranslatePost: false,
    allowUserWriteChatMessage: true,
    appDescription: 'Regionale & lokale Nachrichten',
    appId: 'page.pipeline.welove',
    appName: 'WE LOVE News',
    appPackageName: 'welove-pipeline-app',
    appSlogan: 'Regionale & lokale Nachrichten',
    appVersionId: 100820,
    appVersionName: '0.8.20',
    environments: {
        api: {
            production: 'https://app.pipeline.page/api',
        },
        app: {
            production: 'https://app.pipeline.page',
        }
    },
    filterDiscoverPostsByContentHostUrl: true,
    filterHomePostsByContentHostUrl: true,
    filterLocalPostsByContentHostUrl: true,
    filterPeopleByContentHostUrl: true,
    filterSearchResultsByContentHostUrl: true,
    filterUserByTeamUids: false,
    footerBottomText: `<p>
        Erstellt mit <a href="https://pipeline.page" target="_blank">pipeline</a><br>
        &copy; ${new Date().getFullYear()} pipeline App GmbH
    </p>`,
    includeRatingsInFeed: true,
    includeReactionsInFeed: true,
    includeRelatedArticlesInFeed: false,
    isWhitelabel: true,
    links: {
        imprint: '',
        privacy_policy: '',
        terms_of_use: '',
    },
    loginHeadlineText: 'Regionale & lokale Nachrichten',
    loginWebLeftAreaBackgroundColor: '#be0113',
    maxPostAge: 365,
    menuPages: [
        {
            uid: 'movies',
            icon: 'film-outline',
            key: 'movies',
            name: 'movies',
            route: '/tabs/movies',
            url: '/tabs/movies',
        },
        {
            uid: 'coupons',
            icon: 'gift-outline',
            key: 'coupons',
            name: 'coupons',
            route: '/tabs/coupons',
            url: '/tabs/coupons',
        },
        /*
        {
            uid: 'real_estates',
            icon: 'home-outline',
            key: 'real_estates',
            name: 'real_estates',
            route: '/tabs/announcements/real-estates',
            url: '/tabs/announcements/real-estates',
        },
        {
            uid: 'marketplace',
            icon: 'radio-outline',
            key: 'marketplace',
            name: 'marketplace',
            route: '/tabs/marketplace',
            url: '/tabs/marketplace',
        },
        */
        {
            uid: 'job_posts',
            icon: 'business-outline',
            key: 'job_posts',
            name: 'jobs',
            route: '/tabs/job-posts',
            url: '/tabs/job-posts',
        },
        /*
        {
            uid: 'death_announcements',
            icon: 'newspaper-outline',
            key: 'death_announcements',
            name: 'death_announcements',
            route: '/tabs/announcements/deaths',
            url: '/tabs/announcements/deaths',
        },
        {
            uid: 'events_list',
            icon: 'calendar-outline',
            key: 'events_list',
            name: 'events_list',
            route: '/tabs/events-list',
            url: '/tabs/events-list',
        },
        */
    ],
    menuSideDesktop: 'start',
    menuSideMobile: 'start',
    multiLanguageSupport: true,
    multiUser: true,
    orderBy: {
        discover: 'post_date desc',
        home: 'post_date desc',
        local: 'post_date desc',
        people: 'auto',
        shop: 'auto',
        showroom: 'auto',
    },
    postsVipFactor: 0,
    pushAppId: 'd815e5ce-08ed-4e5d-a59b-4e1ee509176e',
    routes: {
        discover: '/tabs/discover',
        home: '/tabs/feed/23',
        local: '/tabs/local',
        people: '/tabs/people',
        profile: '/tabs/profile/me',
        search: '/tabs/search',
        shop: '/tabs/shop',
    },
    showAccountBecomeCreator: false,
    showAccountClearCaches: false,
    showAccountSecurity: true,
    showAppearanceColorSchemes: true,
    showAppearanceFontSizeSelector: false,
    showAppearanceFontsSelector: false,
    showAppearancePreferedImageSize: false,
    showAppearanceTabs: true,
    showCheckoutPage: false,
    showFeedBottomShadow: false,
    showFooterAppVersion: true,
    showFooterBottomText: true,
    showHeaderLargeTitle: true,
    showHeaderMenuButton: true,
    showHeaderToolbarLogo: true,
    showHeaderToolbarLogoInApp: true,
    showHeaderToolbarLogoInWeb: true,
    showHeaderToolbarTitle: false,
    showHeaderToolbarTitleInApp: false,
    showHeaderToolbarTitleInWeb: false,
    showJobPostsInFeeds: true,
    showJobPostsInSearchResults: true,
    showLocalSourcesSuggestions: false,
    showLoginPage: false,
    showLoginTopLogo: true,
    showMenuHeaderLogo: false,
    showMenuHeaderTitle: false,
    showMenuPagesInApp: true,
    showMenuPagesInWeb: true,
    showPodcastsSuggestions: true,
    showPodcastsSuggestionsInFeed: true,
    showPostCardAdminFab: false,
    showPostAuthorCard: false,
    showPostCardExcerpt: true,
    showPostCardHostLabel: false,
    showPostCardOptions: false,
    showPostCardVerifiedBadges: false,
    showPostPagination: false,
    showPostReadInAppCard: true,
    showPostRelatedArticles: true,
    showProfileDisplayName: true,
    showProfileEvents: true,
    showProfileFriends: true,
    showProfileSocialInformation: true,
    showProfileSocialInformationLabels: true,
    showProfileUserGroup: false,
    showProfileUserGroupAsPrimaryLabel: false,
    showProfileUsername: false,
    showSalut: true,
    showSettingsLegalLinks: true,
    showSettingsLinks: false,
    showShopCategories: true,
    showShopNewestProducts: false,
    showShopRelatedProductsOnDetailPage: true,
    showShopSaleProducts: false,
    showShopSources: true,
    showShopSuggestedProducts: true,
    showShopTrendingProducts: false,
    showSuggestedProfilesOnFeedPage: false,
    showSuggestedProfilesOnHomePage: false,
    showSuggestedProfilesOnLocalPage: false,
    useSharingExtension: true,
    useShortcuts: false,
    useSimplyLocalExtension: false,
    showTabBar: true,
    showTabBarAsDock: false,
    showTabBarInMenuOnApp: true,
    showTabBarInMenuOnWeb: true,
    showTabBarLabels: false,
    showTabMenu: false,
    tabBarActiveStyle: 'solid',
    tabs: [
        {
            uid: 'home',
            checked: true,
            icon: 'home-outline',
            indent: 'home',
            index: 2,
            name: 'home',
            route: '/tabs/feed/23',
            url: '/tabs/feed/23',
        },
        {
            uid: 'local',
            checked: true,
            icon: 'location-outline',
            indent: 'local',
            index: 1,
            name: 'local',
            route: '/tabs/local',
            url: '/tabs/local',
        },
        {
            uid: 'tv',
            checked: true,
            icon: 'tv-outline',
            indent: 'tv',
            index: 2,
            name: 'tv',
            route: '/tabs/tv',
            url: '/tabs/tv',
        },
        {
            uid: 'search',
            checked: true,
            icon: 'search-outline',
            indent: 'search',
            index: 3,
            name: 'search',
            route: '/tabs/search',
            url: '/tabs/search',
        },
        {
            uid: 'profile',
            checked: true,
            icon: 'person-circle-outline',
            indent: 'profile',
            index: 4,
            name: 'profile',
            route: '/tabs/profile/me',
            url: '/tabs/profile/me',
        },
    ],
    translucentFooters: false,
    translucentHeaders: true,
    useAbonnements: false,
    useAdsExtension: true,
    useAppearance: true,
    useAppleWatchExtension: false,
    useArchive: false,
    useAuthExtension: false,
    useAvatars: false,
    useBionicReadingExtension: true,
    useCalendar: false,
    useCategories: false,
    useCategoriesOnDiscoverPage: true,
    useCategoriesOnFeedPages: true,
    useCategoriesOnHomePage: false,
    useCategoriesOnLocalPage: false,
    useCategoriesOnSearchPage: true,
    useChatExtension: true,
    useCommentsExtension: true,
    useCompaniesExtension: true,
    useComplexityExtension: true,
    useCookiesAlertInApp: true,
    useCookiesAlertInWebApp: true,
    useCouponsExtension: true,
    useDatingExtension: true,
    useDeathAnnouncementsExtension: true,
    useDiscover: true,
    useDocumentsExtension: false,
    useEventsList: false,
    useExperiments: false,
    useFeedback: false,
    useFeeds: false,
    useFiltersExtension: false,
    useFollow: false,
    useGoogleMapsExtension: true,
    useGroups: true,
    useHeadlineProfileAvatar: true,
    useHome: true,
    useInbox: true,
    useInterests: false,
    useIntro: false,
    useIntroCards: true,
    useIntroPeople: false,
    useIntroReadingSettings: false,
    useInviteFriends: false,
    useJobPosts: true,
    useJobTitlesExtension: true,
    useLocal: true,
    useLoomaExtension: false,
    useMarketplaceExtension: true,
    useMediaExtension: true,
    useMoviesExtension: true,
    useOrderByFilters: true,
    usePayPalExtension: false,
    usePeople: false,
    usePointsSystem: false,
    usePostsAdmin: false,
    usePostPaywallExtension: false,
    useProfile: true,
    useProfileDisplayNames: true,
    useProfileTitleExtension: false,
    useProfileUsernames: false,
    usePushNotifications: true,
    usePushNotificationsChannels: false,
    useReactions: true,
    useRegionSearch: true,
    useRegionSearchSuggestions: false,
    useReportingExtension: false,
    useSearch: false,
    useSettings: true,
    useShop: true,
    useShopAccount: false,
    useShopCategories: false,
    useShopInternalPaymentProcess: true,
    useShowroom: false,
    useSignInWithApple: false,
    useSignInWithFacebook: false,
    useSignInWithGoogle: false,
    useStories: true,
    useStoriesCameraExtension: true,
    useStoriesOnDiscoverPage: true,
    useStoriesOnHomePage: false,
    useStoriesOnLocalPage: false,
    useStoriesOnPeoplePage: true,
    useTeamsExtension: true,
    useTesting: false,
    useThemes: true,
    useThreeDeeTouch: false,
    useTransparencyExtension: false,
    useTvExtension: true,
    useTvReelsUI: true,
    useTvVideosFromBlogPosts: true,
    useTvVideosFromMediaextend: false,
    useVideosExtension: true,
    useWallet: false,
    useWeclappExtension: false,
    useWeloveWalletExtension: true,
};