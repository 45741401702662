<ion-card color="primary" id="viewShortCard">
  <ion-card-header>
    <ion-card-title [innerHTML]="(('post_view_short_headline'|translate) || '').replace('pipeline', appConfig.appName)"></ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <p [innerHTML]="(('post_view_short_text'|translate) || '').replace('pipeline', appConfig.appName)"></p>
  </ion-card-content>
  <ion-card-header>
    <ion-button (click)="downloadApp()" color="white" size="block">
      <ion-label [innerHTML]="'download_app'|translate"></ion-label>
      <ion-icon name="download-outline" slot="end"></ion-icon>
    </ion-button>
  </ion-card-header>
</ion-card>