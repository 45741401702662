import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private loading: LoadingController,
  ) {
  }

  getAll() {
    return this.AppCMS.loadPluginData('forms', {
      exclude_answers: true,
    });
  }

  getByUid(formId: number, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let key = 'form_' + formId,
          fromCache: cacheItem = await this.cache.get(key, 60 * 60);

      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('forms', {
          exclude_answers: true,
        }, [formId])
        .then((form: form) => {
          this.cache.set(key, form);
          resolve(form);
        })
        .catch(reject);
      }
    });
  }

  async submit(form: any) {
    let loading = await this.loading.create({
      backdropDismiss: false,
      spinner: 'circular',
    });
    loading.present();

    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('forms', {
        form: form
      }, ['submit', form.uid])
      .then((response) => {
        loading.dismiss();
        resolve(response);
      })
      .catch((error: any) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

}
