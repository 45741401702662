import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../../services/config.service';
import { DownloadService } from '../../../services/download.service';

@Component({
  selector: 'pipeline-post-view-short-card',
  templateUrl: './post-view-short-card.component.html',
  styleUrls: ['./post-view-short-card.component.scss'],
})
export class PostViewShortCardComponent implements OnInit {

  appConfig: pipelineAppConfig;

  constructor(
    private configService: ConfigService,
    private downloadService: DownloadService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  downloadApp() {
    this.downloadService.downloadApp();
  }

  ngOnInit() {

  }

}
