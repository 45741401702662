import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  appSideBanners: col[] = [
    /*
    {
      uid: 9990001,
      active: true,
      category: 'home',
      excerpt: 'invite_friends_ads_campagne_excerpt',
      name: 'invite_friends_ads_campagne_headline',
      post_content: 'invite_friends_ads_campagne_text',
      style: null,
      thumbnail: './assets/img/ads/invite_friends_ads_campagne.jpeg',
      type: 'appSideBanner',
      url: '/invite-friends',
      verified: true,
      sponsored: true,
    }
    */
  ];
  
  constructor(

  ) {

  }

  getAppSideBanners() {
    return this.appSideBanners;
  }

}
