<ion-header class="ion-no-border">
    <ion-toolbar class="ion-no-border container">
      <ion-buttons slot="start">
        <ion-button (click)="dismiss()" color="white" fill="clear" icon-only>
            <ion-icon name="arrow-back" color="white"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end" *ngIf="product">
        <ion-button (click)="toggleCollection()" color="white" fill="clear" icon-only>
            <ion-icon [name]="product.added ? 'bookmark' : 'bookmark-outline'" color="white"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="product">

    <ion-refresher slot="fixed" (ionRefresh)="dismiss($event)">
        <ion-refresher-content pullingIcon="close"></ion-refresher-content>
    </ion-refresher>

    <ion-spinner name="circular" [hidden]="product && (product.uid || product.id)"></ion-spinner>

    <div class="container">
        
        <ion-chip color="primary" class="sponsored" *ngIf="product && !!product.sponsored">
            <ion-label [innerHTML]="'sponsored' | translate"></ion-label>
        </ion-chip>
        
        <swiper (swiper)="setSwiperInstance($event)" #productImagesSlider id="productImagesSlider" *ngIf="product && product.images && product.images.length">
            <ng-template swiperSlide *ngFor="let image of product.images">
                <img [src]="image.src" [alt]="image.alt" />
            </ng-template> 
        </swiper>
        
        <ion-fab slot="fixed" *ngIf="product.amount && product.amount > 0" class="basketBtn" (click)="_basket()" horizontal="end">
            <ion-fab-button>
                <ion-icon name="cart-outline"></ion-icon>
            </ion-fab-button>
        </ion-fab>
    
        <ion-card [class.has-related-articles]="viewData.shop && viewData.shop.products && !!viewData.shop.products.related && config.showShopRelatedProductsOnDetailPage" [class.has-related-post]="product.pipeline && product.pipeline.post && product.pipeline.postData">
    
            <div class="ion-padding">
                <h1 [innerHTML]="product.name"></h1>
                <h2 class="price" [innerHTML]="product.price_html || (!!product.price ? product.price + '€' : '')"></h2>
            </div>

            <ion-grid id="variationsGrid" #variationsGrid *ngIf="product.metaData && product.attributes && product.attributes.length">
                <ion-row>
                    <ion-col *ngFor="let item of product.attributes" [hidden]="!item.visible || !item.variation">
                        <ion-card>
                            <ion-item class="ion-text-wrap" lines="none">
                                <ion-label [innerHTML]="item.label" class="ion-text-wrap"></ion-label>
                                <ion-select interface="action-sheet" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [(ngModel)]="product.metaData[item.name]" [multiple]="uiMetaData.isMultiple[item.name]" (ionChange)="updateProduct()">
                                    <ion-select-option selected *ngFor="let option of item.options" class="ion-text-wrap" [value]="option" [innerHTML]="option"></ion-select-option>
                                </ion-select>
                            </ion-item>
                        </ion-card>
                    </ion-col>
                </ion-row>
            </ion-grid>
    
            <ion-grid id="amountGrid" [hidden]="viewData.external">
                <ion-row>
                    <ion-col size="8">
                        <ion-grid>
                            <ion-row>
                                <ion-col>
                                    <ion-button size="block" fill="clear" color="dark" icon-only [disabled]="(product.manage_stock && product.stock_status == 'outofstock') || showVariationSelect" (click)="minus()">
                                        <ion-icon name="remove"></ion-icon>
                                    </ion-button>
                                </ion-col>
                                <ion-col>
                                    <ion-input placeholder="0" [(ngModel)]="product.amount" [disabled]="(product.manage_stock && product.stock_status == 'outofstock') || showVariationSelect" class="amountPicker"></ion-input>
                                </ion-col>
                                <ion-col>
                                    <ion-button size="block" fill="clear" icon-only color="dark" [disabled]="(product.manage_stock && product.stock_status == 'outofstock') || showVariationSelect" (click)="add()">
                                        <ion-icon name="add"></ion-icon>
                                    </ion-button>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-col>
                    <ion-col size="4">
                        <ion-card-subtitle class="price" [innerHTML]="product.price_html || (!!product.price ? product.price + '€' : '')"></ion-card-subtitle>
                    </ion-col>
                </ion-row>
            </ion-grid>
    
            <div class="action-buttons" *ngIf="product && !showVariationSelect">

                <ion-button (click)="addToBasket()" [hidden]="viewData.external" *ngIf="!(product.manage_stock && product.stock_status == 'outofstock')" size="block" color="primary">
                    <ion-icon name="basket" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'add_to_basket'|translate"></ion-label>
                </ion-button>

                <ion-button *ngIf="viewData.external" (click)="addToBasket()" size="block" color="primary">
                    <ion-icon name="basket" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'view_product'|translate"></ion-label>
                </ion-button>

                <ion-button disabled *ngIf="(product.manage_stock && product.stock_status == 'outofstock')" size="block" color="medium">
                    <ion-icon name="basket" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'sold'|translate"></ion-label>
                </ion-button>

            </div>
    
            <ion-card-content id="productDescription" class="post-page" *ngIf="product.description_formatted">
                <h3 [innerHTML]="'product_description'|translate"></h3>
                <p [innerHTML]="product.description_formatted"></p>
            </ion-card-content>
    
        </ion-card>
    
        <ion-card id="productRelatedArticles" *ngIf="viewData.shop && viewData.shop.products && !!viewData.shop.products.related && config.showShopRelatedProductsOnDetailPage">
            <ion-card-header>
              <ion-card-title [innerHTML]="'related_products'|translate"></ion-card-title>
            </ion-card-header>
            <pipeline-shop-products-slider [products]="viewData.shop.products.related"></pipeline-shop-products-slider>
        </ion-card>

        <ion-card class="post-page post-page-size2" id="productPostReader" *ngIf="product.pipeline && product.pipeline.post && product.pipeline.postData">   
            <ion-card-header>
                <ion-card-title [innerHTML]="product.pipeline.postData.title"></ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <div class="post" #post [innerHTML]="product.pipeline.postData.post_content_formatted" (click)="onPageClick($event)"></div>
            </ion-card-content>
        </ion-card>
        
    </div>
    

</ion-content>