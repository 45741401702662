import { Injectable } from '@angular/core';

import { AppcmsService } from './appcms.service';
import { AvatarService } from './avatar.service';
import { CacheService } from './cache.service';
import { ChooserService } from './chooser.service';
import { EventsService } from './events.service';
import { TranslationService } from './translation.service';
import { UserService } from './user.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FriendsService {

  constructor(
    private AppCMS: AppcmsService,
    private avatar: AvatarService,
    private cache: CacheService,
    private chooser: ChooserService,
    private events: EventsService,
    private translations: TranslationService,
    public userService: UserService,
  ) {
  }

  addFriend(profileId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          user: this.userService.getUid()
        },
        ['add']
      )
      .then((response: any) => {
        this.cache.remove(`profile_${profileId}_{}`);
        this.sendFriendFollowedNotification(profileId)
        .catch((error: any) => {
          console.warn('addFriend push error', error);
        });
        resolve(response);
      })
      .catch(reject);
    });
  }

  asCols(profileId: number = null, friends: user[] = null, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      new Promise((resolve, reject) => {
        if(friends) {
          resolve(friends);
        } else {
          this.getAll(profileId, {}, blForceRefresh).then(resolve).catch(reject);
        }
      })
      .then((friends: user[]) => {
        let user = this.userService.getUser() || {}, cols: col[] = [];
        if(friends && friends.length) {  
          friends = friends.filter((friend: user) => {
            return friend.uid !== user.uid && friend.uid !== profileId;
          });
          friends.forEach((person: user) => {
            let coverImg = this.userService.getCoverImage(person);
            let col: col = {
              avatar: person.photo || (this.avatar.getAvatars()[0].src),  
              category: 'people',
              isFriend: true,
              name: this.userService.getDisplayName(person),
              type: 'person',
              uid: person.uid,
              verified: (this.userService.isType('Admin', person) || this.userService.isType('Blog', person)),
            };
            cols.push(col);
          }); 
        }
        resolve(cols);
      })
      .catch(reject);
    });
  }

  asRows() {
    return new Promise((resolve, reject) => {
      this.asCols()
      .then((cols: any) => {
        resolve([{ cols: cols}]);
      })
      .catch(reject);
    });
  }

  getAll(profileId: number = null, options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      profileId = profileId || this.userService.getUid();

      let key = 'friends_byUser_' + profileId + '_' + JSON.stringify(options),
          fromCache: cacheItem = await this.cache.get(key, 30 * 60);

      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('friends', Object.assign(options, {
          user: profileId,
          expanded: false,
        }), ['all'])
        .then((friends: any) => {
          friends = friends.filter((friend: user) => {
            return friend && friend.uid;
          });
          friends = this.userService.parseUsers(friends);
          this.cache.set(key, friends);
          resolve(friends);
        })
        .catch(reject);
      }
    });
  }

  isFriend(profileId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          userId: this.userService.getUid()
        },
        ['isFriend']
      )
      .then((response: any) => {
        resolve(!!response.value);
      })
      .catch(reject);
    });
  }

  pick(options: any) {
    return new Promise(async (resolve, reject) => {

      let config: chooseConfig = Object.assign(options, {
        labelKey: 'firstname',
        data: await this.getAll(),
        valueKey: 'uid',
      });

      this.chooser.choose(config)
      .then((response: any) => {
        console.log('> pick friends response', response);
        resolve(response);
      })
      .catch(reject);
    })
  }

  removeFriend(profileId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          user: this.userService.getUid()
        },
        ['remove']
      )
      .then(response => {
        this.cache.remove(`profile_${profileId}_{}`);
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  search(query: string, blForceRefresh: boolean = true) {
    return new Promise(async (resolve, reject) => {
      query = query.replace(/\s+$/, '');

      /*
      this.searchService.run(
        {
          query: query,
          filter: {
            active: true,
          }
        },
        {},
        blForceRefresh
      )
      .then((response: searchResponse) => {
        resolve(response);
      })
      .catch(reject);
      */
     
    });
  }

  sendFriendFollowedNotification(profileId: number) {

    return new Promise((resolve, reject) => {
      let user: user = this.userService.getUser() || {};

      if(!user || !user.uid) {
        reject('error_missing_user_uid');
        return;
      }

      this.userService.getByUid(profileId)
      .then((profile: user) => {

        if(!profile || !profile.uid) {
          reject('error_profile_not_found');
          return;
        }

        if(profile.uid === user.uid) {
          reject('error_profile_uid_is_user_uid');
          return;
        }
        
        if(!profile.classifications.pushId || !profile.classifications.pushId.length) {
          reject('error_missing_profile_pushId');
          return;
        }
  
        this.translations.get([
          'user_is_following_you',
          'view_profile_action',
        ])
        .subscribe((translations: any) => {
          let config = {
            title: translations.user_is_following_you.replace(/\$ /g, '$').replace('$user', (user.nickname || (user.firstname + ' ' + user.lastname[0] + '.'))),
            description: translations.view_profile_action || 'view_profile_action',
            data: {
              action: 'viewProfile',
              parent: profile.uid,
              user: user.uid,
              timestamp: moment().unix(),
            },
            recipients: [profile.classifications.pushId],
          };

          this.events.publish('push:send', config);
          resolve(config);
        });
      })
      .catch(reject);
    });
  }

}
