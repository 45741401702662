<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="dismiss()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title [innerHTML]="product.name"></ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>

    <div *ngIf="product && product.items">
        <ion-card *ngFor="let related of product.items; let iRelated = index;">
            <ion-card-header>
                <ion-grid>
                    <ion-row>

                        <ion-col size="9">

                            <ion-card-subtitle [innerHTML]="related.name"></ion-card-subtitle>
                            <p [innerHTML]="related.price_html"></p>

                            <ion-button size="small"  *ngIf="!related.configureMode" color="white" (click)="configure(related)">
                                <ion-icon name="create" slot="start"></ion-icon>
                                <ion-label>Anpassen</ion-label>
                            </ion-button>
                            <ion-button size="small"  *ngIf="related.configureMode" color="success" (click)="saveConfiguration(related)">
                                <ion-icon name="save" slot="start"></ion-icon>
                                <ion-label>Speichern</ion-label>
                            </ion-button>
                        </ion-col>

                        <ion-col size="1">
                            <ion-button fill="clear" size="small" (click)="removeFromProduct(related)">
                                <ion-icon name="remove" slot="start"></ion-icon>
                            </ion-button>
                        </ion-col>

                        <ion-col size="1">
                            <input type="tel" class="count" [(ngModel)]="related.amount" placeholder="0" />
                        </ion-col>

                        <ion-col size="1">
                            <ion-button fill="clear" size="small" (click)="addToProduct(related)">
                                <ion-icon name="add" slot="start"></ion-icon>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-card-header>
            <ion-list [hidden]="!related.configureMode" *ngIf="related.configItems">
                <div *ngFor="let item of related.configItems; let iItem = index;">
                    <ion-item>
                        <ion-label text-wrap>
                            <h3 [innerHTML]="item.title"></h3>
                            <p [innerHTML]="item.description || 'Keine Anpassungen'"></p>
                        </ion-label>
                        <ion-note slot="end" [innerHTML]="item.price_html"></ion-note>
                        <ion-button color="white"  slot="end" class="configure-btn" (click)="configureItem(item, iRelated, iItem)">
                            <ion-icon *ngIf="!item.configureMode" name="settings"></ion-icon>
                            <ion-icon *ngIf="item.configureMode" name="checkmark"></ion-icon>
                        </ion-button>
                    </ion-item>
                    <ion-list lines="none" [hidden]="!item.configureMode">
                        <ion-item *ngFor="let option of item.options; let iOption = index;">
                            <ion-checkbox slot="start" *ngIf="option.type == 'checkbox'" (click)="updated()" [(ngModel)]="product.items[iRelated].configItems[iItem].options[iOption].checked"></ion-checkbox>
                            <ion-label text-wrap [innerHTML]="option.title"></ion-label>
                        </ion-item>
                    </ion-list>
                </div>
            </ion-list>
            <ion-card-header *ngIf="related.configureMode">
                <ion-button size="small"  color="success" (click)="saveConfiguration(related)">
                    <ion-icon name="save" slot="start"></ion-icon>
                    <ion-label>Speichern</ion-label>
                </ion-button>
            </ion-card-header>
        </ion-card>
    </div>

</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-button (click)="dismiss()" size="block" >
            <ion-icon name="checkmark" slot="start"></ion-icon>
            <ion-label>
                Übernehmen
                <span *ngIf="!product.price_html">({{product.price}}€)</span>
                <span *ngIf="product.price_html" [innerHTML]="'(' + product.price_html + ')'"></span>
            </ion-label>
        </ion-button>
    </ion-toolbar>
</ion-footer>