import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { EventsService } from '../../../services/events.service';

import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-choose-action-sheet',
  templateUrl: './choose-action-sheet.page.html',
  styleUrls: ['./choose-action-sheet.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseActionSheetPage implements OnInit {

  data: any;

  search: searchOptions = {
    query: '',
  };

  view: any = {
    data: [],
    itemSize: 48,
    labelPrefix: '',
    multiple: false,
    title: 'choose',
  };

  constructor(
    private changeDetector: ChangeDetectorRef,
    private events: EventsService,
    private modalCtrl: ModalService,
    private navParams: NavParams,
    private zone: NgZone,
  ) {
    let data: any = this.navParams.get('data');
    let labelKey: any = this.navParams.get('labelKey');
    let multiple: any = this.navParams.get('multiple');
    let subLabelKey: any = this.navParams.get('subLabelKey');
    let title: any = this.navParams.get('title');
    let service: any = this.navParams.get('service');
    let valueKey: any = this.navParams.get('valueKey');

    this.data = data || this.data;
    this.view.labelKey = labelKey || this.view.labelKey;
    this.view.multiple = multiple || this.view.multiple;
    this.view.service = service || this.view.service;
    this.view.subLabelKey = subLabelKey || this.view.subLabelKey;
    this.view.title = title || this.view.title;
    this.view.valueKey = valueKey || this.view.valueKey;
  }

  detectChanges() {
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
  }

  dismiss(data: any = null) {
    this.modalCtrl.dismiss(data);
  }

  ionViewWillEnter() {
    this.detectChanges();
  }

  ngOnInit() {
  }

  onItemClickedInMultipleMode(item: any) {
    console.log('> onItemClickedInMultipleMode', item);
  }

  onMultiItemSelected(item: any) {
    console.log('> onMultiItemSelected', item);
  }

  runGlobalSearch() {
    try {
      this.view.service.search(this.search.query)
      .then((searchResponse: any) => {
        if(!!searchResponse && searchResponse.length) {
          this.view.data = searchResponse;
          delete this.view.data_backup;

          this.detectChanges();

          if(!!this.view.data && !!this.view.data.length) {
            this.runSearch(null, false);
          }
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    } catch(error) {
      console.warn('> search failed', error);
    }
  }

  runSearch(event: any = null, blAllowGlobal: boolean = true) {
    let key = 'data', backupKey = `${key}_backup`;
    let searchKeys = ['firstname', 'lastname', 'email', 'nickname', 'title', 'excerpt', 'url', 'description', 'name'];

    if(!this.view.hasOwnProperty(backupKey) && this.view[key] && this.view[key].length) {
      this.view[backupKey] = JSON.parse(JSON.stringify(this.view[key]));
    } else
    if(this.view[backupKey] && this.view[backupKey].length) {
      this.view[key] = JSON.parse(JSON.stringify(this.view[backupKey]));
    }

    blAllowGlobal = blAllowGlobal && !!this.search.query && (this.search.query.length > 2);
    
    if(this.view[key] && this.view[key].length) {

      this.view[key] = this.view[key].filter((item: any) => {
        let blShow: boolean = false;
        searchKeys.forEach((searchKey: string) => {
          if(item.hasOwnProperty(searchKey)) {
            blShow = blShow || `${item[searchKey] ? item[searchKey] : ''}`.toLowerCase().indexOf(this.search.query.toLowerCase()) !== -1;
          }
        });
        return blShow;
      });

      if((!!blAllowGlobal && !this.view[key] || !this.view[key].length)) {
        this.runGlobalSearch();
      }

    } else
    if(!!blAllowGlobal) {
      this.runGlobalSearch();
    }

    this.detectChanges();
  }

  select(item: any) { 
    if(!this.view.multiple) {
      this.dismiss({
        item: item,
        items: [item],
      });
    }
  }

  submitMultiple() {
    this.dismiss({
      items: this.view.data.filter((item: any) => {
        return !!item.checked;
      }),
    });
  }

  submit() {
    let items: any[] = [];

    if(!!this.data && this.data.length) {
      items = this.data.filter((item: any) => {
        return !!item.checked;
      });
    }

    this.dismiss({
      items: items,
    });
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = null;
    this.detectChanges();
  }

  toggleSelectAll() {

    this.view.data.forEach((item: any) => {
      if(!item.hidden) {
        if(this.view.allSelected) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      }
    });

    this.view.allSelected = !this.view.allSelected;
  }

}
