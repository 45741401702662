import { ElementRef, Injectable, ViewChild } from '@angular/core';

import { CacheService } from './cache.service';
import { TranslationService } from './translation.service';
import { EventsService } from './events.service';
import { UserService } from './user.service';
import { ConfigService } from './config.service';

import * as introJs from 'intro.js/intro.js';

@Injectable({
  providedIn: 'root'
})
export class IntroService {
  @ViewChild('tabBar', { read: ElementRef }) tabBarElement: ElementRef;

  _loadedSliderPage: boolean = false;

  introJS: any;

  constructor(
    private cache: CacheService,
    private config: ConfigService,
    private events: EventsService,
    private translate: TranslationService,
    public userService: UserService,
  ) {
  }

  async getDiscoverOptions() {
    return new Promise((resolve, reject) => {
      this.translate.get([
        'next', 'prev', 'skip', 'done',
        'intro_discover_1',
        'intro_finished',
      ])
        .subscribe((response: any) => {
          let intro1element = document.querySelectorAll('#discoverTitle')[0];

          resolve({
            nextLabel: response.next,
            prevLabel: response.prev,
            skipLabel: response.skip,
            doneLabel: response.done,
            steps: [
              {
                element: intro1element,
                intro: response.intro_discover_1,
                position: 'left',
              },
            ]
          });
        });
    });
  }

  async getHomeOptions() {
    return new Promise((resolve, reject) => {
      this.translate.get([
        'next', 'prev', 'skip', 'done',
        'intro_home_1',
        'intro_home_2',
        'intro_finished',
      ])
        .subscribe((response: any) => {
          let intro1element = document.querySelectorAll('#homeTitle')[0];
          let intro2element = document.querySelectorAll('#homeGrid')[0];

          resolve({
            nextLabel: response.next,
            prevLabel: response.prev,
            skipLabel: response.skip,
            doneLabel: response.done,
            steps: [
              {
                element: intro1element,
                intro: response.intro_home_1,
                position: 'left',
              },
              {
                element: intro2element,
                intro: response.intro_home_2,
                position: 'bottom',
              },
            ]
          });
        });
    });
  }

  getIntroConfig() {
    let config: pipelineAppConfig = this.config.getConfig();
    return config.introConfig;
  }

  async getLocalOptions() {
    return new Promise((resolve, reject) => {
      this.translate.get([
        'next', 'prev', 'skip', 'done',
        'intro_local_1',
        'intro_local_2',
        'intro_finished',
      ])
        .subscribe((response: any) => {
          let intro1element = document.querySelectorAll('#localTitle')[0];
          let intro2element = document.querySelectorAll('#noCitySelectedCard')[0];

          resolve({
            nextLabel: response.next,
            prevLabel: response.prev,
            skipLabel: response.skip,
            doneLabel: response.done,
            steps: [
              {
                element: intro1element,
                intro: response.intro_local_1,
                position: 'left',
              },
              {
                element: intro2element,
                intro: response.intro_local_2,
                position: 'bottom',
              },
            ]
          });
        });
    });
  }

  async getOptions() {
    return new Promise((resolve, reject) => {
      this.translate.get([
        'next', 'prev', 'skip', 'done',
        'intro_1',
        'intro_2', 'intro_2_1',
        'intro_3',
        'intro_4',
        'intro_5',
        'intro_6',
        'intro_finished',
      ])
        .subscribe((response: any) => {
          let intro5element = document.querySelectorAll('ion-slide:nth-child(2) ion-row:nth-child(2) ion-col:nth-child(1)')[0];

          resolve({
            nextLabel: response.next,
            prevLabel: response.prev,
            skipLabel: response.skip,
            doneLabel: response.done,
            steps: [
              {
                intro: response.intro_1,
              },
              {
                element: document.querySelectorAll('ion-slide:nth-child(1) ion-row:nth-child(1) ion-col:nth-child(1)')[0],
                intro: response.intro_2,
              },
              {
                element: document.querySelectorAll('ion-slide:nth-child(1) ion-row:nth-child(2) ion-col:nth-child(1)')[0],
                intro: response.intro_2_1,
                position: 'top',
              },
              {
                element: document.querySelectorAll('ion-buttons[slot=start] ion-button')[0],
                intro: response.intro_3,
                position: 'right'
              },
              {
                element: document.querySelectorAll('ion-menu-button')[0],
                intro: response.intro_4,
                position: 'left'
              },
              {
                element: intro5element,
                intro: response.intro_5,
                position: 'bottom'
              },
              {
                intro: response.intro_finished
              }
            ]
          });
        });
    });
  }

  async getPeopleOptions() {
    return new Promise((resolve, reject) => {
      this.translate.get([
        'next', 'prev', 'skip', 'done',
        'intro_people_1',
        'intro_people_2',
        'intro_finished',
      ])
        .subscribe((response: any) => {
          let intro1element = document.querySelectorAll('#peopleTitle')[0];
          let intro2element = document.querySelectorAll('#peopleGrid')[0];

          resolve({
            nextLabel: response.next,
            prevLabel: response.prev,
            skipLabel: response.skip,
            doneLabel: response.done,
            steps: [
              {
                element: intro1element,
                intro: response.intro_people_1,
                position: 'left',
              },
              {
                element: intro2element,
                intro: response.intro_people_2,
                position: 'bottom',
              },
            ]
          });
        });
    });
  }

  async getProfileOptions() {
    return new Promise((resolve, reject) => {
      this.translate.get([
        'next', 'prev', 'skip', 'done',
        'intro_profile_1',
        'intro_profile_2',
        'intro_finished',
      ])
        .subscribe((response: any) => {
          let intro1element = document.querySelectorAll('#profileSettingsBtn')[0];
          let intro2element = document.querySelectorAll('#noEntriesCardContent')[0];

          resolve({
            nextLabel: response.next,
            prevLabel: response.prev,
            skipLabel: response.skip,
            doneLabel: response.done,
            steps: [
              {
                element: intro1element,
                intro: response.intro_profile_1,
                position: 'left',
              },
              {
                element: intro2element,
                intro: response.intro_profile_2,
                position: 'bottom',
              },
            ]
          });
        });
    });
  }

  async init() {
    let blShouldUse = await this.shouldUse();
    if (blShouldUse) {
      await this.setup();
      this.show();
    }
  }

  async isIntroCardHidden(cardName: string, bl: boolean = null) {
    let key: string = `introCardHidden_${cardName}`;
    if(bl !== null) {
      this.cache.set(key, bl);
    } else {
      let fromCache: cacheItem = await this.cache.get(key, -1);
      return (fromCache && fromCache.data ? !!fromCache.data : false);
    }
  }

  async loadedSliderPage(bl: boolean = null) {
    if (bl !== null) {
      this._loadedSliderPage = !!bl;
      this.cache.set('loadedSliderPage', this._loadedSliderPage);
    } else {
      let fromCache: cacheItem = await this.cache.get('loadedSliderPage', -1);
      return fromCache && fromCache.data ? !!fromCache.data : this._loadedSliderPage;
    }
  }

  onBeforeChange(target: any) {
    switch (this.introJS._currentStep) {
      case 2:
        this.events.publish('home:view:category', {
          uid: 'discover',
          speed: 500
        });
        this.introJS.refresh();
        break;
      case 5:
        this.events.publish('home:view:category', {
          uid: 'discover',
          speed: 500
        });
        this.introJS.refresh();
        break;
    }
  }

  async setup() {
    let options = await this.getOptions();

    this.introJS = introJs(document.querySelector("app-home"));

    this.introJS.onbeforechange((target: any) => {
      this.onBeforeChange(target);
    });

    this.introJS.onexit(() => {
      this.shouldUse('blShouldUseIntro', false);
    });

    this.introJS.setOptions(options);
  }

  async setupDiscover() {
    let options = await this.getDiscoverOptions();
    
    this.introJS = introJs(document.querySelector("app-discover"));

    this.introJS.onbeforechange((target: any) => {
      this.onBeforeChange(target);
    });

    this.introJS.onexit(() => {
      this.shouldUse('blShouldUseDiscoverIntro', false);
    });

    this.introJS.setOptions(options);
  }

  async setupHome() {
    let options = await this.getHomeOptions();
    
    this.introJS = introJs(document.querySelector("app-home"));

    this.introJS.onbeforechange((target: any) => {
      this.onBeforeChange(target);
    });

    this.introJS.onexit(() => {
      this.shouldUse('blShouldUseHomeIntro', false);
    });

    this.introJS.setOptions(options);
  }

  async setupLocal() {
    let options = await this.getLocalOptions();

    this.introJS = introJs(document.querySelector("app-local"));

    this.introJS.onbeforechange((target: any) => {
      this.onBeforeChange(target);
    });

    this.introJS.onexit(() => {
      this.shouldUse('blShouldUseLocalIntro', false);
    });

    this.introJS.setOptions(options);
  }

  async setupPeople() {
    let options = await this.getPeopleOptions();

    this.introJS = introJs(document.querySelector("app-people"));

    this.introJS.onbeforechange((target: any) => {
      this.onBeforeChange(target);
    });

    this.introJS.onexit(() => {
      this.shouldUse('blShouldUsePeopleIntro', false);
    });

    this.introJS.setOptions(options);
  }

  async setupProfile() {
    let options = await this.getProfileOptions();

    this.introJS = introJs(document.querySelector("app-profile"));

    this.introJS.onbeforechange((target: any) => {
      this.onBeforeChange(target);
    });
    
    this.introJS.onexit(() => {
      this.shouldUse('blShouldUseProfileIntro', false);
    });

    this.introJS.setOptions(options);
  }

  async shouldUse(key: string = null, bl: boolean = null) {
    if (bl !== null) {
      let set = await this.userService.setClassification(key, bl);
      return set;
    } else {
      key = key || 'blShouldUseIntro';
      let should: cacheItem = await this.userService.getClassification(key);
      return should !== false;
    }
  }

  async show() {
    this.introJS.start();
  }

}