import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'pipeline-post-reader',
  templateUrl: './post-reader.component.html',
  styleUrls: ['./post-reader.component.scss'],
})
export class PostReaderComponent implements OnInit {
  @Input() post: post;

  constructor(
    private events: EventsService,
  ) {

    this.events.subscribe('post:reader:update', (post: post) => {
      this.post = post;
    });

  }

  ngOnInit() {

  }

}