import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { EventsService } from '../../../services/events.service';
import { PostsService } from '../../../services/posts.service';
import { ToolsService } from '../../../services/tools.service';

import SwiperCore, { Autoplay, Navigation, Pagination} from 'swiper';
SwiperCore.use([Autoplay, Navigation, Pagination]);

@Component({
  selector: 'pipeline-post-suggestions-slider',
  templateUrl: './post-suggestions-slider.component.html',
  styleUrls: ['./post-suggestions-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostSuggestionsSliderComponent implements OnInit {
  @Input() posts: post[];
  
  postCardOptions: any = {
    mini: true,
    show_excerpt: false,
  };

  private postSuggestionsSlider: any;

  postSuggestionsSliderOptions: any = {
    slidesPerGroup: 2,
    slidesPerView: 2,
  };

  view: any = {
    placeholders: [{},{}],
    posts: [],
  };

  constructor(
    private events: EventsService,
    private postsService: PostsService,
    private tools: ToolsService,
  ) {
  }


  loadPosts() {
    if(!!this.posts) {
      this.posts.forEach((post: post) => {
        post.size = 6;
      });
      this.view.posts = this.posts;
      this.events.publish('view:suggestions:updated', this.view.posts);
      
      setTimeout(() => {
        this.updateSlider();
      }, 1500);

    } else {
      this.view.posts = JSON.parse(JSON.stringify(this.view.placeholders));

      this.postsService.getPostsSuggestions()
      .then((posts: post[]) => {
        if(posts && posts.length) {

          posts.forEach((post: post) => {
            post.size = 6;
          });
  
          this.view.posts = this.tools.shuffle(posts);
          this.events.publish('view:suggestions:updated', this.view.posts);
          
          setTimeout(() => {
            this.updateSlider();
          }, 1000);
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }
  }

  ngOnInit() {
    this.loadPosts();
  }

  setSwiperInstance(event: any) {
    this.postSuggestionsSlider = event;
  }

  updateSlider() {
    try {
      if(this.postSuggestionsSlider) {
        this.postSuggestionsSlider.update();
      }
    } catch(e) {
      console.warn('e', e);
    }
  }

}
