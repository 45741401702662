import { Injectable } from '@angular/core';

import { ReportContentPage } from '../pages/security/report-content/report-content.page';

import { AppcmsService } from './appcms.service';
import { EventsService } from './events.service';
import { ModalService } from './modal.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private AppCMS: AppcmsService,
    private events: EventsService,
    private modalService: ModalService,  
    private translations: TranslationService,
  ) {

  }

  async create(options: any = {}) {
    let modal = await this.modalService.create({
      component: ReportContentPage,
      componentProps: Object.assign({
        reportingService: this,
      }, options),
      animated: true,
      canDismiss: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'reportContentPage'
    });

    modal.onWillDismiss().then(() => {
    });

    modal.present();
  }

  onReportSubmitted(response: any)
  {
    this.translations.get([
      'report_submitted_toast_message',
    ])
    .subscribe((translations: any) => {
      this.events.publish('toast', {
        message: translations.report_submitted_toast_message || 'report_submitted_toast_message',
        color: 'primary',
      });
    });
  }

  submit(report: any) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        report: report,
      }, ['reporting', 'submit'])
      .then((response: any) => {
        this.onReportSubmitted(response);

        resolve(response);
      })
      .catch(reject);
    });
  }

}