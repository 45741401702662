import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

import { EventsService } from './events.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  state: any = {

  };

  constructor(
    private events: EventsService,
    private loginService: LoginService,
    private toast: ToastController
  ) {
    
  }
  
  async handleActionRequiresUserLoginError() {
    return this.loginService.show(true);
  }

  init() {
    this.events.subscribe('toast', (data: any) => this.onToastReceived(data));
  }

  async onToastReceived(toastConfig: toastConfig = {}) {
    toastConfig.duration = toastConfig.duration || 2000;

    if( (!!toastConfig && !!toastConfig.message) &&
        (toastConfig.message === 'Der Benutzer konnte nicht gefunden werden') || 
        ((toastConfig.message || '').indexOf('missing_user') !== -1)) {
      return this.handleActionRequiresUserLoginError();
    }

    if(!this.state.toastsBlocked) {
      this.state.toastsBlocked = true;

      setTimeout(() => {
        this.state.toastsBlocked = false;
      }, (toastConfig.duration));

      let toast = await this.toast.create(Object.assign({
        message: 'Ein unbekannter Fehler ist aufgetreten',
      }, toastConfig));
      toast.present();
    }

  }
  async show(text: string, color: string = 'primary') {
    
    this.events.publish('toast', {
      message: text,
      color: color,
    });
  }

}
