import { Component, OnInit } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';

import { ConfigService } from '../../../services/config.service';
import { EventsService } from 'src/app/services/events.service';
import { ModalService } from 'src/app/services/modal.service';
import { TeamsService } from 'src/app/services/teams.service';
import { ToolsService } from 'src/app/services/tools.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-account',
  templateUrl: './change-account.page.html',
  styleUrls: ['./change-account.page.scss'],
})
export class ChangeAccountPage implements OnInit {

  accounts: any;
  
  fallbackImg: string = './assets/img/fallback.webp';

  user: user;

  view: any = {
    accounts: [],
  }

  constructor(
    private config: ConfigService,
    private events: EventsService,
    private modalCtrl: ModalService,
    private navCtrl: NavController,
    private navParams: NavParams,
    private teams: TeamsService,
    private tools: ToolsService,
    private userService: UserService,
  ) {
    this.accounts = this.navParams.get('accountsService');

    this.user = this.userService.getUser() || {};
  }

  async addAccount() {
    this.accounts.setMultiMode(true);
    this.events.publish('login:show');
  }

  afterLogout() {
    this.loadAccounts()
      .then((accounts: user[]) => {
        console.log('> accounts after logout', accounts);

        if (accounts && accounts.length && (accounts[0] && accounts[0].uid && accounts[0].uid !== -1)) {
          this.doRefresh();
        } else {
          this.dismiss();
          this.navCtrl.navigateRoot('/login');
        }
      });
  }

  calcViewData() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.isWeb = this.tools.isWeb();

    if(this.view.accounts && this.view.accounts.length) {
      this.view.accounts.forEach((account: user) => {
        account.checked = (account.uid === this.user.uid);
      });
    }
  }

  async dismiss() {
    (await this.modalCtrl).dismiss();
  }

  doRefresh(event: any = null) {
    this.loadAccounts(true)
      .then(() => {
        if (event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (event) {
          event.target.complete();
        }
      });
  }

  loadAccounts(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.accounts.getAll(blForceRefresh)
        .then((accounts: user[]) => {

          this.view.accounts = accounts;
          this.calcViewData();

          this.loadAccountsTeams(blForceRefresh)
          .then((accounts: user[]) => {
            resolve(accounts);
          })
          .catch(() => {
            resolve(accounts);
          });
        })
        .catch(reject);
    });
  }

  loadAccountsTeams(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if(this.view.accounts && this.view.accounts.length) {
        if(!this.config.useTeamsExtension()) {
          resolve(this.view.accounts);
        } else {

          this.view.accounts.forEach(async (account: user, index: number) => {
            try {
              let teamsByUser: any = await this.teams.getByUserUid(account.uid, blForceRefresh);
  
              if(teamsByUser && teamsByUser.length) {
                account.teams = teamsByUser;
              }
  
              if(index === (this.view.accounts.length-1)) {
                resolve(this.view.accounts);
              }
            } catch(e) {
              console.warn('> teamsByUser loading failed', e);
              
              if(index === (this.view.accounts.length-1)) {
                resolve(this.view.accounts);
              }
            }
          });

        }
      }
    });
  }

  logout(account: user) {
    this.accounts.logout(account)
      .then(() => {
        this.afterLogout();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  logoutAll() {
    this.userService.logout()
      .then(() => {
        this.afterLogout();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {
    this.calcViewData();
    this.user = this.userService.getUser() || {};

    window.addEventListener('resize', () => {
      this.calcViewData();
    });

    this.events.subscribe("appcms:user:updated", () => {
      this.doRefresh();
    });

    this.loadAccounts()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  switchTo(account: user) {
    this.userService.setUser(account);

    if(!!account && !!account.teams && !!account.teams.length) {
      this.teams.setCurrentTeam(account.teams[0]);
    }

    this.dismiss();
  }

  switchToTeam(team: team, user: user = null) {
    this.teams.setCurrentTeam(team);

    if(!!user && (user.uid !== this.user.uid)) {
      return this.switchTo(user);
    } else {
      this.dismiss();
    }
  }

  thumbnailLoadingFailed(account: user|team, photo: string = null) {
    account.photo = (photo || this.fallbackImg);
  }

}
