<ion-header>
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-button icon-only (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'change_account'|translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <ion-spinner name="circular" *ngIf="!view.accounts"></ion-spinner>

  <div class="container">

    <ion-grid *ngIf="view.accounts">

      <ion-row>
        <ion-col *ngFor="let account of view.accounts">

          <ion-card>
            <ion-list lines="none">

              <!-- main account -->
              <ion-item-sliding>

                <ion-item (click)="switchTo(account)" [hidden]="!account.uid">
                  <ion-checkbox slot="start" [(ngModel)]="account.checked"></ion-checkbox>

                  <ion-avatar slot="start">
                    <ion-img [src]="account.photo"></ion-img>
                  </ion-avatar>
                  <ion-label class="ion-text-wrap">
                    <h3
                      [innerHTML]="account.displayName || account.nickname || (!!account.firstname && !!account.lastname ? (account.firstname + ' ' + account.lastname) : ('guest'|translate))">
                    </h3>
                    <p [innerHTML]="account.email"></p>
                  </ion-label>

                  <ion-button slot="end" *ngIf="view.isDesktop" color="danger" fill="clear" (click)="logout(account)">
                    <ion-icon name="log-out-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'log_out'|translate"></ion-label>
                  </ion-button>

                </ion-item>

                <ion-item-options *ngIf="!view.isDesktop" slot="end">
                  <ion-item-option color="danger" (click)="logout(account)">
                    <ion-icon name="log-out-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'log_out'|translate"></ion-label>
                  </ion-item-option>
                </ion-item-options>

              </ion-item-sliding>

              <!-- teams -->
              <ion-radio-group *ngIf="account.teams" [(ngModel)]="account.team">
                <ion-item *ngFor="let team of account.teams" class="team" (click)="switchToTeam(team, account)" [hidden]="!team.uid">
                  <ion-radio slot="start" [value]="team"></ion-radio>

                  <ion-avatar slot="start">
                    <ion-img [src]="team.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(team, fallbackImg)"></ion-img>
                  </ion-avatar>
                  <ion-label class="ion-text-wrap">
                    <h3
                      [innerHTML]="team.name">
                    </h3>
                  </ion-label>

                </ion-item>
              </ion-radio-group>

            </ion-list>
          </ion-card>
        </ion-col>
      </ion-row>

    </ion-grid>
  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-no-border container">
    <div class="ion-padding">
      <ion-button size="block" color="primary" (click)="addAccount()">
        <ion-label [innerHTML]="'add_account'|translate"></ion-label>
      </ion-button>
      <ion-button size="block" color="primary" fill="clear" (click)="logoutAll()">
        <ion-label [innerHTML]="'logout_all'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>