<ion-header class="ion-no-border" translucent="true">
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-button icon-only (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="collection.name|translate"></ion-title>
    <ion-buttons slot="end" *ngIf="view.admin">
      <ion-button icon-only (click)="settings()">
        <ion-icon name="settings-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>
  
  <ion-header collapse="condense" class="ion-no-border">
    <ion-toolbar class="ion-no-border container">
      <ion-title size="large" [innerHTML]="collection.name|translate"></ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="container">
    <ion-card id="noEntriesCardContent" *ngIf="!collection || !collection.items || !collection.items.length">
      <ion-card-header>
        <ion-card-title *ngIf="!view.segment || view.segment == 'post' || view.segment == 'custom'" [innerHTML]="'no_entries'|translate"></ion-card-title>
        <ion-card-title *ngIf="view.segment == 'product'" [innerHTML]="'no_products'|translate"></ion-card-title>
        <ion-card-title *ngIf="view.segment == 'people'" [innerHTML]="'no_friends'|translate"></ion-card-title>
        <ion-card-subtitle [innerHTML]="'no_entries_subtitle_1'|translate"></ion-card-subtitle>
      </ion-card-header>
    </ion-card>
  
    <ion-grid *ngIf="collection.items && collection.items.length">
      <ion-row>
        
        <ion-col *ngFor="let col of collection.items" [hidden]="col.indent && (!col.name || !col.style)" class="col-size-{{col.size || 12}}" [size]="col.size||12">
          <ion-card class="profileCard" [color]="col.color||'light'" (click)="onColClick(col)">
            <ion-fab class="vipBadge" horizontal="end" *ngIf="col.vip" vertical="bottom">
              <ion-fab-button color="warning" size="small">
                <ion-icon name="lock-closed"></ion-icon>
              </ion-fab-button>
            </ion-fab>
            <ion-fab class="verifiedBadge" horizontal="end" *ngIf="col.verified && !col.vip" vertical="bottom">
              <ion-fab-button color="primary" size="small">
                <ion-icon name="shield-checkmark-outline"></ion-icon>
              </ion-fab-button>
            </ion-fab>
            <ion-card-header>
              <ion-card-title *ngIf="col.name" [innerHTML]="col.name"></ion-card-title>
              <ion-skeleton-text [hidden]="col.name" style="width:90%;" animated></ion-skeleton-text>
              <ion-skeleton-text [hidden]="col.name" style="width:50%;" animated></ion-skeleton-text>
  
              <ion-card-subtitle *ngIf="col.subTitle" [innerHTML]="col.subTitle"></ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
  
      </ion-row>
    </ion-grid>
    
  </div>

</ion-content>
