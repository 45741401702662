<ion-header class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-button icon-only (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title [innerHTML]="'comments'|translate"></ion-title>
  </ion-toolbar>
</ion-header>

<pipeline-reactions-stage></pipeline-reactions-stage>

<ion-content>
  <div class="container">
    <pipeline-post-comments [post]="post"></pipeline-post-comments>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container">
    <ion-item lines="none">
      <ion-input
        [(ngModel)]="view.input"
        [placeholder]="'write_comment'|translate"
        (ionSubmit)="submitComment()"
      ></ion-input>
      <ion-button
        icon-only
        fill="clear"
        slot="end"
        color="primary"
        (click)="submitComment()"
        [disabled]="!view.input"
      >
        <ion-icon name="send-outline"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-toolbar>
</ion-footer>
