import { Component, NgZone, OnInit, ViewChild } from "@angular/core";

import {
  NavController,
  AlertController,
  IonInput,
  Platform,
  IonContent,
} from "@ionic/angular";

import { DomSanitizer } from "@angular/platform-browser";

import * as $ from "jquery";

import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';

import { AppcmsService } from "../../../services/appcms.service";
import { UserService } from "../../../services/user.service";
import { EventsService } from "../../../services/events.service";
import { LogfileService } from "../../../services/logfile.service";
import { ToolsService } from "../../../services/tools.service";
import { PipelineService } from "../../../services/pipeline.service";
import { NetworkService } from "../../../services/network.service";
import { TranslationService } from "../../../services/translation.service";
import { IntroService } from "../../../services/intro.service";
import { AccountsService } from "../../../services/accounts.service";
import { WebService } from "../../../services/web.service";
import { ConfigService } from "../../../services/config.service";
import { TrackingService } from "../../../services/tracking.service";
import { HomeService } from "../../../services/home.service";
import { ModalService } from "../../../services/modal.service";

declare var AppleID: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("loginEmail") loginEmail: IonInput;
  @ViewChild("registerFirstname") registerFirstname: IonInput;
  @ViewChild("phoneNumber") phoneNumber: IonInput;

  // google phone validation
  windowRef: any;
  prefix: any;

  agbPageId: number = 1;

  config: pipelineAppConfig;

  error: any;

  privacyPolicyPageId: number = 2;

  registerOptions: registerOptions = {};

  user: user = {
    classifications: {},
    email: "",
    firstname: "",
    lastname: "",
  };

  view: any = {
    code: "",
    countryCodes: [],
    loading: true,
    phoneCodeFlag: "./assets/img/flags/de.svg",
    phonePrefix: "+49",
    redeemHeadlineMessage: "redeemHeadlineMessage",
    redeemHeadlineMessageDefault: "redeemHeadlineMessage",
    redeemHeadlineMessageSuccess: "redeemHeadlineMessageSuccess",
    redeemHeadlineMessageFailed: "redeemHeadlineMessageFailed",
    security_headline: "security_headline",
    security_subHeadline: "security_subHeadline",
    showRedeemCodeButton: false,
    verifyPhoneNumberCardMessage: "verifyPhoneNumberCardMessage",
    verifyPhoneNumberCardMessageDefault: "verifyPhoneNumberCardMessage",
  };

  constructor(
    private accounts: AccountsService,
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private configService: ConfigService,
    private events: EventsService,
    private home: HomeService,
    private intro: IntroService,
    private log: LogfileService,
    private modalCtrl: ModalService,
    private navCtrl: NavController,
    private network: NetworkService,
    private pipeline: PipelineService,
    private platform: Platform,
    private sanitizer: DomSanitizer,
    private tools: ToolsService,
    private tracking: TrackingService,
    private translations: TranslationService,
    public userService: UserService,
    private zone: NgZone,
    private webService: WebService,
  ) {
    this.config = this.configService.getConfig();
    this.user = this.userService.getUser() || this.user;
    this.view.isWeb = this.tools.isWeb();

    this.view.platform = (this.platform.is("ios")
      ? "ios"
      : this.platform.is("android")
      ? "android"
      : "undefined");

    this.view.isMultiMode = this.accounts.isMultiMode();

    if(!this.view.isMultiMode) {
      this.validateUser();
    }
    
  }

  afterLogin() {
    if(this.view.isMultiMode) {
      this.dismiss();
    } else {
      let url = this.userService.getAfterLoginRedirectUrl() || "/home";
      this.view.loggedIn = true;
      this.navCtrl.navigateForward(url);
    }
  }

  async alert(message: string, title: string = "Fehler") {
    const alert = await this.alertCtrl.create({
      message: message,
      buttons: [
        {
          text: "Okay",
        },
      ],
    });
    await alert.present();
  }

  calcLoginLegalInfo() {
    this.translations
      .get(["agb", "login_legal_info", "privacy_policy"])
      .subscribe((translations: any) => {
        setTimeout(() => {
          this.zone.run(() => {
            let loginLegalInfo = $("<span></span>");

            loginLegalInfo.html(
              translations.login_legal_info
                .replace(/\$ /g, '$')
                .replace(
                  "$1",
                  `<a href="/login#/dynamic/1" id="agbButton">${translations.agb}</a>`
                )
                .replace(
                  "$2",
                  `<a href="/login#/dynamic/2" id="privacyPolicyButton">${translations.privacy_policy}</a>`
                )
            );

            this.view.loginLegalInfo = this.sanitizer.bypassSecurityTrustHtml(
              loginLegalInfo.html()
            );
            
            window.addEventListener("hashchange", () => {
              let path = location.hash.replace('#/', '').split('/');

              switch(path[0]) {
                case 'dynamic':
                  if(!!path[1]) {
                    this.events.publish('view:page', parseInt(path[1]));
                  }
                  break;
                default:
                  //console.log('unknown path', path);
                  break;
              }

            }, false);
          });
        });
      });
  }

  countryCodeChanged() {
    this.view.phoneCodeFlag = this.view.countryCode.image;
  }

  dismiss() {
    this.accounts.setMultiMode(false);
    this.modalCtrl.dismiss();
  }

  forgotPassword() {
    this.modalCtrl.closeAll();
    this.navCtrl.navigateForward("/forgot-password", { animated: !this.tools.isDesktop() });
  }

  handleSIWAWebError(error: any) {
    console.warn('> handleSIWAWebError', error);
  }

  handleSIWAWebSuccess(data: any) {
    let authData: any = (data && data.detail && data.detail.authorization ? data.detail.authorization : {});
  }

  async initIntro() {
    let bl: boolean = await this.intro.loadedSliderPage();

    if(!!this.config.useIntro && (!bl && !this.accounts.isMultiMode() && !this.userService.getUid() && !this.tools.isDesktop())) {
      this.navCtrl.navigateRoot('/intro-slider');
    } else
    if(!this.config.useIntro && (!bl && !this.accounts.isMultiMode() && !this.userService.getUid() && !this.tools.isDesktop())) {
      this.tracking.askTrackingPermission();
    }
  }

  inputBlur(event: any = null) {
    this.zone.run(() => {
      this.view.scrollable = false;
      this.content.scrollToBottom();
    });
  }

  inputFocus(event: any = null) {
    this.zone.run(() => {
      this.view.scrollable = true;
      this.content.scrollToBottom();
    });
  }

  ionViewWillEnter() {
    this.validateUser();
    this.initIntro();

    try {
      this.renderWindow();
    } catch(e) {
      console.warn('render Sign in with Apple failed', e);
    }
  }

  login(credentials = null) {
    credentials = credentials || {
      email: this.user.email,
      password: this.user.password,
    };

    this.pipeline
      .login(credentials)
      .then((response: loginResponse) => {
        this.user = response.user;
        this.user.password = this.user.password;

        this.afterLogin();
      })
      .catch((error: any) => {
        this.error = error;
      });
  }

  loginWithFacebook() {
    
    if (this.tools.isWeb()) {
      return this.webService.appFeaturesRequested();
    }

    /*
    FacebookLogin
      .login(["public_profile", "email"])
      .then((res: FacebookLoginResponse) => {
        this.fb
          .api(
            "me?fields=id,name,email,first_name,picture.width(720).height(720).as(picture_large)",
            []
          )
          .then((profile: any) => {
            let lastnameExplode = profile.name.split(" "),
              lastname = lastnameExplode[lastnameExplode.length - 1];
            this.user.email = profile.email || this.user.email;
            this.user.firstname = profile.first_name || this.user.firstname;
            this.user.lastname = lastname || this.user.lastname;
            this.user.email = profile.email || this.user.email;
            this.user.photo = profile.picture_large.data.url || this.user.photo;
            this.user.nickname = profile.name || this.user.nickname;
            this.userService.setUser(this.user).then(() => {
              this.register();
            });
          })
          .catch((e: any) => {
            if (
              !e.errorMessage ||
              e.errorMessage.indexOf("User cancelled") === false
            ) {
              this.events.publish("error", e.errorMessage || e);
            }
          });
      })
      .catch((e) => {
        if (
          !e.errorMessage ||
          e.errorMessage.indexOf("User cancelled") === false
        ) {
          this.events.publish("error", e.errorMessage || e);
        }
      });
    */
  }

  ngOnInit() {
    this.calcLoginLegalInfo();
  }

  redeemCode() {
    this.showRedeemCard(true);
  }

  register() {
    this.modalCtrl.closeAll();
    this.navCtrl.navigateForward("/register", { animated: !this.tools.isDesktop() });
  }

  removeRedeemCodeLetter() {
    this.view.code = this.view.code.substring(0, this.view.code.length - 1);
    this.view.redeemHeadlineMessage = this.view.redeemHeadlineMessageDefault;
    delete this.view.codeRedeemSuccess;
  }

  renderWindow() {
    if (!this.platform.is("ios") || this.tools.isWeb()) {

      if (this.tools.isWeb()) {
        
        AppleID.auth.init({
          clientId: "page.pipeline.web",
          scope: "name email",
          redirectURI: "https://web.pipeline.page",
          state: "origin:web",
          usePopup: true,
          nonce: 'nonce',
        });

        document.addEventListener('AppleIDSignInOnSuccess', (data) => {
          this.handleSIWAWebSuccess(data);
        });

        //Listen for authorization failures
        document.addEventListener('AppleIDSignInOnFailure', (error) => {
          this.handleSIWAWebError(error);
        });

      }
    }
  }
 
  showBetaRegisterCard(bl: boolean) {

    //if (this.tools.isWeb()) {
    //  return this.webService.appFeaturesRequested();
    //}

    this.view.showBetaRegisterCard = bl;
    setTimeout(() => {
      if (this.registerFirstname && bl) {
        this.registerFirstname.setFocus();
      }
    }, 500);
  }

  showLoginCard(bl: boolean) {
    this.view.showLoginCard = bl;
    setTimeout(() => {
      if (this.loginEmail && bl) {
        this.loginEmail.setFocus();
      }
    }, 500);
  }

  showRedeemCard(bl: boolean) {
    this.view.showRedeemCard = bl;
    if (!bl) {
      this.view.code = "";
      this.view.redeemHeadlineMessage = this.view.redeemHeadlineMessageDefault;
      delete this.view.codeRedeemSuccess;
    }
  }

  showVerifyPhoneNumberCard(bl: boolean) {
    this.view.showVerifyPhoneNumberCard = bl;

    if (!bl) {
      this.view.code = "";
      this.view.verifyPhoneNumberCardMessage =
        this.view.verifyPhoneNumberCardMessageDefault;
      delete this.view.codeRedeemSuccess;
    } else {
      setTimeout(() => {
        if (this.phoneNumber) {
          this.phoneNumber.setFocus();
        }
      }, 500);
    }
  }

  signInWithApple() {

    if (this.tools.isWeb()) {
      return this.webService.appFeaturesRequested();
    }

    let options: SignInWithAppleOptions = {
      clientId: "page.pipeline.web",
      redirectURI: "https://web.pipeline.page",
      scopes: 'email name',
      state: "origin:web",
      nonce: 'nonce',
    };
    
    SignInWithApple.authorize(options)
      .then((result: SignInWithAppleResponse) => {
        let response: any = result && !!result.response ? result.response : {};
        console.log('> siwa response', response);

        if (
          (response.email && response.email.length) ||
          (response.givenName && response.givenName.length)
        ) {
          this.user.email = response.email || this.user.email;
          this.user.firstname =
            response.givenName || this.user.firstname;
          this.user.lastname =
            response.familyName || this.user.lastname;
          this.user.nickname =
            ("user" + Math.floor(100000 + Math.random() * 900000));
          this.user.identity_token = response.identityToken;

          console.log('> siwa: prepared this.user', this.user);
          this.register();
        } else {
          this.validateAppleUser(response);
        }
      })
      .catch(error => {
        console.warn('> error', error);
        this.events.publish('error', error);
      });
  }
 
  validateAppleUser(response: any) {
    this.userService.loginWithAppleSignIn(response)
      .then((response: any) => {
        if (response && response.action) {
          switch (response.action) {
            case "cancel":
              //console.log("cancel!");
              break;
            case "createSIWAAccount":
              this.validateUser();
              break;
            case "connectWithSIWA":
              this.showLoginCard(true);
              break;
          }
        }

        if (response && response.success) {
          this.afterLogin();
        }
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  async validateUser() {

    if (this.view.verify) {
      return false;
    }

    this.view.loading = true;
    this.view.loggedIn = false;
    this.view.verify = true;

    this.user = this.userService.getUser() || this.user;

    if (this.user && this.user.uid) {

      if(this.user.uid === -1) {
        if(!this.view.redirectedGuestUser) {
          this.navCtrl.navigateRoot(
            this.userService.getAfterLoginRedirectUrl(),
            { animated: false }
          );
          this.view.redirectedGuestUser = true;
        }
        this.view.loading = false;
        this.view.loggedIn = false;
        this.view.verify = false;
        return false;
      }

      this.AppCMS.storeCurrentAuthDetails(this.user);
      
      let networkStatus = await this.network.getStatus();

      if (!networkStatus.connected) {
        this.network.showOfflineMessage();
        this.navCtrl.navigateRoot(this.userService.getAfterLoginRedirectUrl(), {
          animated: false,
        });
        this.view.loading = false;
        this.view.loggedIn = false;
        this.view.verify = false;
        return false;
      }

      let requireValidation = await this.userService.shouldValidate();

      if (!requireValidation) {
        this.userService.setUser(this.user, false).then(() => {
          this.navCtrl.navigateRoot(
            this.userService.getAfterLoginRedirectUrl(),
            { animated: false }
          );

          this.view.loading = false;
          this.view.verify = false;
          this.view.loggedIn = !!(this.user && this.user.uid);

          this.log.loggedIn();
        });
      } else {
        this.home
          .getHome(true, {
            people: false,
            posts: false,
            profile: true,
            includeRating: false,
            includeRelatedArticles: false,
            includeReactions: false,
          })
          .then((homeData: homeData) => {
            this.view.loading = false;
            this.view.verify = false;

            if (!homeData) {
              this.view.loggedIn = false;
              this.events.publish("error");
            } else if (
              !homeData.user ||
              !homeData.user.uid ||
              !homeData.user.active
            ) {
              this.view.loggedIn = false;
              this.events.publish("error", "Dieser Account wurde gesperrt.");
              this.userService.logout();
            } else {
              this.view.loggedIn = !!(homeData.user && homeData.user.uid);
              this.userService.setUser(homeData.user, false).then(() => {
                this.navCtrl.navigateRoot(
                  this.userService.getAfterLoginRedirectUrl(),
                  { animated: false }
                );
                this.userService.shouldValidate(false);
                this.log.loggedIn();
              });
            }
          })
          .catch((error: any) => {
            this.zone.run(() => {
              this.view.loading = false;
              this.view.loggedIn = false;
              this.view.verify = false;
  
              if(error !== 'error_offline') {
                this.events.publish("error", error);
              }
            });
          });
      }
    } else {
      this.view.loading = false;
      this.view.loggedIn = false;
      this.view.verify = false;
    }
  }
  
  viewAgb() {
    this.events.publish("view:page", this.agbPageId);
  }

  viewPrivacyPolicy() {
    this.events.publish("view:page", this.privacyPolicyPageId);
  }

}
