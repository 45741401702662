<ion-header class="ion-no-border">
    <ion-toolbar class="ion-no-border">
        <ion-buttons slot="start">
            <ion-button (click)="dismiss()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-card>
        <ion-card-header>
            <ion-card-title [innerHTML]="'coupon_code'|translate"></ion-card-title>
            <ion-card-subtitle [innerHTML]="'coupon_code_subtitle'|translate"></ion-card-subtitle>
        </ion-card-header>
        <ion-list lines="none">
            <ion-item>
                <ion-input type="text" [placeholder]="'coupon_code'|translate" [(ngModel)]="data.code"></ion-input>
            </ion-item>
        </ion-list>
    </ion-card>

</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="ion-no-border">
        <ion-button (click)="use()" size="block" color="primary">
            <ion-icon name="checkmark" slot="start"></ion-icon>
            <ion-label [innerHTML]="'use_coupon'|translate"></ion-label>
        </ion-button>
    </ion-toolbar>
</ion-footer>