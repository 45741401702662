import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import * as $ from 'jquery';

import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';
import { ModalService } from './modal.service';
import { SearchPage } from '../pages/search/search.page';
import { LanguageService } from './language.service';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  apiUrl: string = 'https://search.pipeline.page/api/';
  
  proxyUrl: string = 'https://search.pipeline.page/api/searchengine/proxy.raw?url=';
  
  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private events: EventsService,
    private language: LanguageService,
    private loading: LoadingController,
    private modalCtrl: ModalService,
  ) {
    this.initEvents();
  }

  async addToHistory(query: string) {
    let history = await this.getHistory();

    query = $.trim(query);

    if(history.indexOf(query) === -1) {
      history.push(query);
    }

    return this.cache.set('searchHistory', history);
  }

  async deleteHistoryItem(query: string) {
    let history = await this.getHistory();
    history = history.filter((historyItem: string) => {
      return historyItem !== query;
    });
    return this.cache.set('searchHistory', history);
  }

  findBlogAdminResults(query: string, options: any = {}) {
    return this.run({
      query: query,
    }, Object.assign(options, {
      active: false,
    }));
  }

  getApiUrl() {
    return this.apiUrl;
  }

  async getHistory() {
    let fromCache: cacheItem = await this.cache.get('searchHistory', (60 * 24 * 7)) || {};
    return fromCache.data || [];
  }

  getProxyUrl() {
    return this.proxyUrl;
  }

  initEvents() {
    this.events.subscribe('view:search', () => {
      this.search();
    });
  }

  prepareSearchResponse(response: searchResponse) {
    return response;
  }

  run(options: searchOptions = {}, filter: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let language = this.language.getCurrentLanguage();

      let key = 'searchResponse_' + language + '_' + JSON.stringify(options) + '_' + JSON.stringify(filter),
          fromCache: cacheItem = await this.cache.get(key, 30 * 60);
      
      if(!options.query || !options.query.length) {
          reject('error_query_empty');
      } else
      if(options.query && (options.query.length < 3)) {
         reject('error_query_too_short');
      } else
      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else { 
        this.AppCMS.loadPluginData('pipeline', {
          search: options,
          filter: Object.assign({
            active: true,
          }, filter),
          language: language,
        }, ['search'], {
          apiUrl: this.apiUrl
        })
        .then((searchResponse: searchResponse) => {
          searchResponse = this.prepareSearchResponse(searchResponse);
          this.cache.set(key, searchResponse)
          .then(() => {
            resolve(searchResponse);
          })
          .catch(() => {
            resolve(searchResponse);
          });
        })
        .catch(reject); 
      }
    });
  }

  search(options: any = {}) {
    return new Promise(async (resolve, reject) => {

      let loading = await this.loading.create({
        backdropDismiss: false,
        spinner: 'circular',
      });
      loading.present();

      let searchModal = await this.modalCtrl.create({
        component: SearchPage,
        componentProps: Object.assign(options, {
          searchService: this,
        }),
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: "searchModal",
      });

      searchModal.onWillDismiss().then((response: any) => {
        response.data = response.data || {};
        resolve(response.data);
      });

      searchModal.present()
      .then(() => {
        loading.dismiss();
      })
      .catch(reject);
    });

  }

}