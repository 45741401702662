import { Component, OnInit } from '@angular/core';

import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-verified-info',
  templateUrl: './verified-info.page.html',
  styleUrls: ['./verified-info.page.scss'],
})
export class VerifiedInfoPage implements OnInit {

  constructor(
    private modalCtrl: ModalService,
  ) {
    
  }

  async dismiss() {
    (await this.modalCtrl).dismiss();
  }

  ngOnInit() {
  }

}
