<swiper *ngIf="!!(view.products && view.products.length)" [navigation]="true" [pagination]="true" [slidesPerGroup]="shopProductsSliderOptions.slidesPerGroup"
  [slidesPerView]="shopProductsSliderOptions.slidesPerView" (swiper)="setSwiperInstance($event)">
  
  <ng-template swiperSlide *ngFor="let product of view.products" [hidden]="product.hidden">
    <pipeline-product-card [product]="product"></pipeline-product-card>
  </ng-template>

  <div class="swiper-pagination"></div>

  <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div>

</swiper>