import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, throwError, BehaviorSubject, of } from 'rxjs';

import {
    catchError,
    finalize,
    switchMap,
    filter,
    take,
} from 'rxjs/operators';

import { ToastController } from '@ionic/angular';

import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    api_url: string = 'https://api.pipeline.page/api';

    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    isRefreshingToken = false;

    constructor(
        private authService: AuthService,
        private toastCtrl: ToastController
    ) {

    }

    private async handle400Error(err) {
        const toast = await this.toastCtrl.create({
            message: 'Logged out due to authentication mismatch',
            duration: 2000
        });
        toast.present();
        this.authService.logout();
        return of(null);
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!this.isRefreshingToken) {

            this.tokenSubject.next(null);
            this.isRefreshingToken = true;
            this.authService.clearAccessToken();

            return this.authService.getNewAccessToken().pipe(
                switchMap((token: any) => {
                    if (token) {
                        const accessToken = token.accessToken;
                        return this.authService.setAccessToken(accessToken).pipe(
                            switchMap(_ => {
                                this.tokenSubject.next(accessToken);
                                return next.handle(this.addToken(request));
                            })
                        );
                    } else {
                        return of(null);
                    }
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(request));
                })
            );
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isInBlockedList(request.url)) {
            return next.handle(request);
        } else {
            return next.handle(this.addToken(request)).pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        switch (err.status) {
                            case 400:
                                return this.handle400Error(err);
                            case 401:
                                return this.handle401Error(request, next);
                            default:
                                return throwError(err);
                        }
                    } else {
                        return throwError(err);
                    }
                })
            );
        }
    }

    private isInBlockedList(url: string): Boolean {
        if (url == `${this.api_url}/auth` ||
            url == `${this.api_url}/auth/logout`) {
            return true;
        } else {
            return false;
        }
    }

    private addToken(req: HttpRequest<any>) {
        if (!!this.authService.currentAccessToken) {
            return req.clone({
                headers: new HttpHeaders({
                    Authorization: `Bearer ${this.authService.currentAccessToken}`
                })
            });
        } else {
            return req;
        }
    }

}