import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';

import { AppcmsService } from './appcms.service';
import { BlogService } from './blog.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BlogAdminService {

  view: any = {};

  constructor(
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private blog: BlogService,
    private loading: LoadingController,
    public userService: UserService,
  ) {

  }

  accept(post: post) {
    //console.log('accept', post);

    return new Promise(async (resolve, reject) => {
      let loading = await this.loading.create({
        spinner: 'circular',
      });
      loading.present();

      let blForceRefresh = !this.view.categoriesLoaded;
      
      this.blog
        .getMainCategories(blForceRefresh, false)
        .then(async (mainCategories: category[]) => {
          loading.dismiss();
          let inputs = [];
          this.view.categoriesLoaded = true;

          mainCategories.forEach((mainCategory: category) => {
            inputs.push({
              name: "mainCategory",
              value: mainCategory.term_id,
              label: mainCategory.name,
              type: "radio",
            });
          });

          this.showCategoriesPickerAlert(inputs, mainCategories)
            .then(async (data: any) => {
              loading.present();
              
              post.category = data.category;
              post.type = 'post';

              this.blog.removePublic();

              delete post.parsed;
              delete post.rating;
              delete post.reactions;
              delete post.relatedArticles;

              this.AppCMS.loadPluginData(
                "pipeline",
                {
                  post: post,
                  user: this.userService.getUid(),
                },
                ["posts", "accept"]
              )
                .then((response: any) => {
                  loading.dismiss();
                  resolve(response);
                })
                .catch((error: any) => {
                  loading.dismiss();
                  reject(error);
                });
            })
            .catch(reject);
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  async showCategoriesPickerAlert(inputs: any, mainCategories: any) {
    return new Promise(async (resolve, reject) => {
      let alert = await this.alertCtrl.create({
        header: "Kategorie wählen",
        inputs: inputs,
        buttons: [
          {
            text: "Okay",
            role: "submit",
            handler: (data: any) => {
              let categorySelect = mainCategories.filter((_category: category) => {
                  return _category.term_id == data;
                }),
                category = categorySelect[0] || null;
              if (!category) {
                reject("error_missing_category_id");
              } else if (
                category &&
                (!category.categories || !category.categories.length)
              ) {
                resolve({
                  category: category.term_id,
                });
              } else {
                this.showSubcategoriesPickerAlert(category)
                  .then(resolve)
                  .catch(reject);
              }
            },
          },
          {
            role: "cancel",
            text: "Abbrechen",
          },
        ],
      });
      alert.onWillDismiss().then((response: any) => {
        if (response.role !== "submit") {
          reject(response);
        }
      });
      await alert.present();
    });
  }

  async showSubcategoriesPickerAlert(mainCategory: any) {
    return new Promise(async (resolve, reject) => {
      let inputs = [];

      mainCategory.categories.forEach((mainCategory: any) => {
        inputs.push({
          name: "mainCategory",
          value: mainCategory.term_id,
          label: mainCategory.name,
          type: "radio",
        });
      });

      let alert = await this.alertCtrl.create({
        header: "Unterkategorie wählen",
        inputs: inputs,
        buttons: [
          {
            text: "Okay",
            role: "submit",
            handler: (categoryId: number) => {
              //console.log("categoryId", categoryId);
              resolve({ category: categoryId || mainCategory.term_id });
            },
          },
          {
            text: "In " + mainCategory.name + " speichern",
            role: "submit",
            handler: () => {
              resolve({ category: mainCategory.term_id });
            },
          },
          {
            role: "cancel",
            text: "Abbrechen",
            handler: reject,
          },
        ],
      });
      await alert.present();
    });
  }

}
