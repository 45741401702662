import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';
import { EventsService } from './events.service';
import { SettingsService } from './settings.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private events: EventsService,
    private settings: SettingsService,
    private tools: ToolsService,
  ) {
  }

  getCategories(blForceRefresh: boolean = false, blFilterResults: boolean = true) {
    return new Promise(async (resolve, reject) => {
      let key = "pipeline_categories";
      let categoriesFromCache: cacheItem = await this.cache.get(key, 10 * 60);

      if (!blForceRefresh && (categoriesFromCache && categoriesFromCache.data)) {
        this.events.publish("categories:loaded", categoriesFromCache.data);
        resolve(categoriesFromCache.data);
      } else {
        this.AppCMS.loadPluginData("pipeline", {}, ["categories"])
          .then((categories: any) => {
            this.settings
              .getSetting("categories", blForceRefresh)
              .then((categoriesSettings: any) => {

                if(categories && categories.length) {

                  categories.forEach((category: category, index: number) => {
                    let id = (category.id || category.uid) || (category.remote_uid || category.term_id);
                    if(category) {
                      categories[index] = this.getFullCategory(category);
                    }
                    this.cache.set(`category_${id}`, categories[index]);
                  });

                  let filteredCategories: category[];
                  
                  if(blFilterResults) {
                    filteredCategories = categories.filter((_category: category) => {
                      let blReturn: boolean = false;
                      if (
                        categoriesSettings &&
                        categoriesSettings[_category.remote_uid]
                      ) {
                        blReturn = categoriesSettings[_category.remote_uid].rank > 1;
                      }
                      blReturn = _category.name !== 'Allgemein';
                      return blReturn;
                    });
                  }

                  categories = filteredCategories && filteredCategories.length ? filteredCategories : categories;
                  this.cache.set(key, categories);
                }
                this.events.publish("categories:loaded", categories);
                resolve(categories);
              })
              .catch(reject);
          })
          .catch(reject);
      }
    });
  }

  getCategory(categoryId: number, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let cacheKey: string = `category_${categoryId}`,
          fromCache: cacheItem = await this.cache.get(cacheKey, (60 * 60 * 24));

      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(this.getFullCategory(fromCache.data));
      } else {
        this.getCategories()
        .then((categories: category[]) => {
          let categorySelect = categories.filter((_category: category) => {
            return _category.term_id == categoryId;
          });
          let category = categorySelect && categorySelect[0] ? this.getFullCategory(categorySelect[0]) : null;
          this.cache.set(cacheKey, category);
          resolve(category);
        })
        .catch(reject); 
      }
    });
  }

  getFullCategory(category: category) {
    category.name = category.name.replace("&amp;", "&");
    category.icon = this.tools.getCategoryIcon(category.name);

    return category;
  }

  getMainCategories(blForceRefresh: boolean = false, blFilterResults: boolean = true) {
    return new Promise((resolve, reject) => {
      this.getCategories(blForceRefresh, blFilterResults)
        .then((categories: category[]) => {

          let mainCategories = categories.filter((category: category) => {
            if(category.hasOwnProperty('categories') && !category.hasOwnProperty('parent')) {
              return category.categories && category.categories.length;
            }
            return !category.parent;
          });
          
          this.events.publish("categories:main:loaded", mainCategories);
          resolve(mainCategories);
        })
        .catch(reject);
    });
  }

  getSelectedCategories(blForceRefresh: boolean = false)
  {
    return new Promise(async (resolve, reject) => {
      let selectedCategories: any = (await this.settings.getSetting('categories', blForceRefresh) || {});
      let selectedCategoriesIds = Object.keys(selectedCategories || {});
      let categories: category[] = [];

      if(selectedCategoriesIds && selectedCategoriesIds.length) {
        this.getCategories()
        .then(() => {
          selectedCategoriesIds.forEach((selectedCategoriesId: string, index: number) => {
            let selectedCategory: category = selectedCategories[selectedCategoriesId];
            
            new Promise(async (resolve, reject) => {
              if(selectedCategory && (selectedCategory.rank > 1)) {
                try {
                  let fullSelectedCategory: category = await this.getCategory(parseInt(selectedCategoriesId));
                  let childrenKeys = Object.keys(selectedCategory.children);
    
                  if(fullSelectedCategory && fullSelectedCategory.name) {
                    categories.push(fullSelectedCategory);
                  }
    
                  if(childrenKeys && childrenKeys.length) {
                    childrenKeys.forEach(async (childId: string, index: number) => {
                      let bl: boolean = selectedCategory.children[childId],
                          fullSelectedSubCategory: category = await this.getCategory(parseInt(childId));
                      
                      if(bl && (fullSelectedSubCategory && fullSelectedSubCategory.name)) {
                        categories.push(fullSelectedSubCategory);
                      }

                      if(index === (childrenKeys.length-1)) {
                        resolve({});
                      }

                    });
                  } else {
                    resolve({});
                  }
    
                } catch(e) {
                  console.warn('> e', e);
                }
              }
            })
            .then(() => {
              if(index === (selectedCategoriesIds.length-1)) {
                resolve(categories);
              }
            })
            .catch(reject);
          });
        })
        .catch(reject); 
      } else {
        this.getMainCategories(blForceRefresh).then(resolve).catch(reject);
      }
    });
  }

  async getSelectedCategoriesNames()
  {
    let selectedCategories: any = await this.getSelectedCategories();
    let selectedCategoriesNames = [];

    if(selectedCategories && selectedCategories.length) {
      selectedCategories.forEach((selectedCategory: category) => {
        selectedCategoriesNames.push(selectedCategory.name);
      });
    }

    return selectedCategoriesNames;
  }

}
