import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pipeline-post-collection-card',
  templateUrl: './post-collection-card.component.html',
  styleUrls: ['./post-collection-card.component.scss'],
})
export class PostCollectionCardComponent implements OnInit {
  @Input() post: post;

  constructor(

  ) {

  }

  goToCollection(collectionItem: any) {
    //console.log('goToCollection', collectionItem);
  }
  
  ngOnInit() {

  }

}
