import { Injectable } from '@angular/core';

import { AppcmsService } from './appcms.service';
import { UserService } from './user.service';

import { ChooserService } from './chooser.service';

@Injectable({
  providedIn: 'root'
})
export class JobtitlesService {

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private userService: UserService,
  ) {

  }

  get(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('jobtitles', {
      filter: options,
      language: this.userService.getLanguage(),
    }, [], null, blForceRefresh);
  }

  getByUid(jobtitleId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('jobtitles', {}, [jobtitleId], null, blForceRefresh);
  }

  getByParentUid(parentId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('jobtitles', {
      filter: {
        parent: parentId
      }
    }, null, null, blForceRefresh);
  }

  getMain(options: any = {}, blForceRefresh: boolean = false) {
    return this.get(Object.assign(options, {
      parent: 0,
    }), blForceRefresh);
  }

  pick() {
    return new Promise(async (resolve, reject) => {
      let chooseConfig: chooseConfig = {
        data: await this.get(),
        labelKey: 'label',
        service: this,
        valueKey: 'uid',
      };
      this.chooser.choose(chooseConfig)
      .then((chooseResponse: chooseResponse) => {
        resolve(chooseResponse);
      })
      .catch(reject);
    });
  }

  search(query: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('jobtitles', {
      query: query,
      language: this.userService.getLanguage(),
    }, ['search'], null, blForceRefresh);
  }

}
