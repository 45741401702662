import { Component, Input, OnInit } from '@angular/core';

import { VerifiedInfoPage } from '../../pages/account/verified-info/verified-info.page';

import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'pipeline-verified-badge',
  templateUrl: './verified-badge.component.html',
  styleUrls: ['./verified-badge.component.scss'],
})
export class VerifiedBadgeComponent implements OnInit {
  @Input() profile: user;

  constructor(
    private modalCtrl: ModalService,
    public userService: UserService,
  ) {
    
  }

  ngOnInit() {

  }


  async verifiedInfo() {
    let verifiedModal = await this.modalCtrl.create({
      component: VerifiedInfoPage,
      componentProps: {
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'verifiedInfoModal'
    });
    verifiedModal.present();
  }

}
