<ion-card *ngIf="!!post.collection">
  <ion-card-header>
    <ion-card-subtitle [innerHTML]="'post_series_headline'|translate"></ion-card-subtitle>
  </ion-card-header>
  <ion-list>
    <ion-item *ngFor="let collectionItem of post.collection" (click)="goToCollection(collectionItem)">
      <ion-label>
        <h3 [innerHTML]="collectionItem.name"></h3>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-card>