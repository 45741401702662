import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { CardOptionsPage } from '../../../pages/legacy/card-options/card-options.page';
import { VerifiedInfoPage } from '../../../pages/account/verified-info/verified-info.page';

import { EventsService } from '../../../services/events.service';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'pipeline-post-related-articles',
  templateUrl: './post-related-articles.component.html',
  styleUrls: ['./post-related-articles.component.scss'],
})
export class PostRelatedArticlesComponent implements OnInit {
  @Input() post: post;

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private popoverCtrl: PopoverController,
  ) {

  }

  ngOnInit() {
    
  }

  async options(post: post, event: any = null) {
    event.preventDefault();
    let optionsPopover = await this.popoverCtrl.create({
      animated: true,
      component: CardOptionsPage,
      componentProps: {
        col: post,
      },
      cssClass: 'cardOptionsPopover',
      event: event,
      translucent: true
    });
    await optionsPopover.present();
    return false;
  }

  readRelated(post: post, event: any = null) {
    this.events.publish('view:post', post);
  }

  async verifiedInfo() {
    let verifiedModal = await this.modalService.create({
      component: VerifiedInfoPage,
      componentProps: {
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'verifiedInfoModal'
    });
    verifiedModal.present();
  }

}
