import { Injectable } from "@angular/core";
import { LoadingController } from '@ionic/angular';

import { BasketPage } from '../pages/shop/basket/basket.page';
import { CheckoutPage } from '../pages/shop/checkout/checkout.page';
import { CouponDetailsPage } from "../pages/coupons/coupon-details/coupon-details.page";
import { CustomCollectionViewerPage } from '../pages/collections/custom-collection-viewer/custom-collection-viewer.page';
import { DetailPage } from '../pages/shop/detail/detail.page';
import { DynamicPage } from '../pages/dynamic/dynamic.page';
import { EventDetailPage } from "../pages/events/event-detail/event-detail.page";
import { PaymentPage } from '../pages/shop/payment/payment.page';
import { PostPage } from '../pages/post/post/post.page';
import { ProfilePage } from '../pages/profile/profile/profile.page';
import { StoryPage } from '../pages/stories/story/story.page';
import { SurveyPage } from '../pages/surveys/survey/survey.page';

import { ModalService } from '../services/modal.service';
import { CalendarService } from "./calendar.service";
import { EventsService } from "./events.service";
import { PagesService } from "./pages.service";
import { PostsService } from "./posts.service";
import { ShopService } from "./shop.service";
import { SurveysService } from "./surveys.service";
import { UserService } from "./user.service";
import { CouponsService } from "./coupons.service";

@Injectable({
  providedIn: "root",
})
export class AppEventsService {

  constructor(
    private calendar: CalendarService,
    private coupons: CouponsService,
    private events: EventsService,
    private loading: LoadingController,
    private pages: PagesService,
    private posts: PostsService,
    private shop: ShopService,
    private surveys: SurveysService,
    private modalService: ModalService,
    public userService: UserService,
  ) {

  }

  init() {

    this.events.subscribe("view:basket", () => {
      this.viewBasket();
    });

    this.events.subscribe("view:checkout", () => {
      this.viewCheckout();
    });
    
    this.events.subscribe("view:collection", (data: any) => {
      if(typeof data === 'number') {
        this.viewCollection(data);
      } else
      if(data && !!data.uid) {
        this.viewCollection(data.uid, data);
      }
    });

    this.events.subscribe("view:coupon", (data: any) => {
      if(typeof data === 'number') {
        this.viewCoupon(data);
      } else
      if(data && !!data.uid) {
        this.viewCoupon(data.uid, data);
      }
    });

    this.events.subscribe("view:event", (data: any) => {
      if(typeof data === 'number') {
        this.viewEvent(data);
      } else
      if(data && !!data.uid) {
        this.viewEvent(data.uid, data);
      }
    });

    this.events.subscribe("view:page", (data: any) => {
      if(typeof data === 'number') {
        this.viewPage(data);
      } else
      if(data && !!data.uid) {
        this.viewPage(data.uid, data);
      }
    });
    
    this.events.subscribe("view:payments", () => {
      this.viewPayments();
    });

    this.events.subscribe("view:post", (data: any) => {
      if(typeof data === 'number') {
        this.viewPost(data);
      } else
      if(data && !!data.uid) {
        this.viewPost(data.uid, data);
      }
    });

    this.events.subscribe("view:product", (data: any) => {
      if(typeof data === 'number') {
        this.viewProduct(data);
      } else 
      if(data && !!data.uid) {
        this.viewProduct(data.uid, data);
      }
    });

    this.events.subscribe("view:profile", (data: any) => {
      if(typeof data === 'number') {
        this.viewProfile(data);
      } else
      if(data && !!data.uid) {
        this.viewProfile(data.uid, data);
      }
    });

    this.events.subscribe("view:story", (data: any) => {
      if(typeof data === 'number') {
        this.viewStory(data);
      } else
      if(data && !!data.uid) {
        this.viewStory(data.uid, data);
      }
    });

    this.events.subscribe("view:survey", (data: any) => {
      if(typeof data === 'number') {
        this.viewSurvey(data);
      } else
      if(data && !!data.uid) {
        this.viewSurvey(data.uid, data);
      }
    });

  }

  async viewBasket() {
    let basketModal = await this.modalService.create({
      component: BasketPage,
      componentProps: {
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById("ion-router-outlet-content"),
      cssClass: "basketModal",
    });
    await basketModal.present();
  }

  viewCategory(category: any) {
    this.events.publish("view:category", category);
  }

  async viewCheckout() {
    //this.modalService.closeAll();
    this.modalService.setIsModal(true);

    let checkoutModal = await this.modalService.create({
      component: CheckoutPage,
      componentProps: {
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById("ion-router-outlet-content"),
      cssClass: "checkoutModal",
    });

    checkoutModal.onWillDismiss().then(() => {
      this.modalService.setIsModal(false);
    });

    await checkoutModal.present();
  }

  async viewCollection(
    itemId: number,
    collection: collectionsResponseItem = null
  ) {
    this.modalService.setIsModal(true);

    let collectionModal = await this.modalService.create({
      component: CustomCollectionViewerPage,
      componentProps: {
        collection: collection,
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById("ion-router-outlet-content"),
      cssClass: "collectionModal",
    });

    collectionModal.onWillDismiss().then(() => {
      this.modalService.setIsModal(false);
    });

    await collectionModal.present();
  }

  viewCoupon(itemId: number, coupon: coupon = null) {
    new Promise(async (resolve, reject) => {
      if (coupon) {
        resolve(coupon);
      } else {
        let loading = await this.loading.create({
          backdropDismiss: false,
          spinner: "circular",
        });
        await loading.present();
        this.coupons
          .getByUid(itemId)
          .then((coupon: coupon) => {
            loading.dismiss();
            resolve(coupon);
          })
          .catch((error: any) => {
            loading.dismiss();
            reject(error);
          });
      }
    })
      .then(async (coupon: coupon) => {
        this.modalService.setIsModal(true);

        let couponModal = await this.modalService.create({
          component: CouponDetailsPage,
          componentProps: {
            modalCtrl: this.modalService.getController(),
            coupon: coupon,
            couponsService: this.coupons,
          },
          animated: true,
          //canDismiss: true,
          //presentingElement: document.getElementById('ion-router-outlet-content'),
          cssClass: "couponModal",
        });

        couponModal.onWillDismiss().then(() => {
          this.modalService.setIsModal(false);
        });

        await couponModal.present();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  viewEvent(itemId: number, event: calendarEvent = null) {
    new Promise(async (resolve, reject) => {
      if (event) {
        resolve(event);
      } else {
        let loading = await this.loading.create({
          backdropDismiss: false,
          spinner: "circular",
        });
        await loading.present();
        this.calendar
          .getEventByUid(itemId)
          .then((event: calendarEvent) => {
            loading.dismiss();
            resolve(event);
          })
          .catch((error: any) => {
            loading.dismiss();
            reject(error);
          });
      }
    })
      .then(async (event: calendarEvent) => {
        this.modalService.setIsModal(true);

        let eventModal = await this.modalService.create({
          component: EventDetailPage,
          componentProps: {
            modalCtrl: this.modalService.getController(),
            event: event,
          },
          animated: true,
          //canDismiss: true,
          //presentingElement: document.getElementById('ion-router-outlet-content'),
          cssClass: "eventDetailModal",
        });

        eventModal.onWillDismiss().then(() => {
          this.modalService.setIsModal(false);
        });

        await eventModal.present();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  viewPage(itemId: number, page: page = null) {
    new Promise(async (resolve, reject) => {
      if (page) {
        resolve(page);
      } else {
        let loading = await this.loading.create({
          backdropDismiss: false,
          spinner: "circular",
        });
        await loading.present();
        this.pages
          .getByUid(itemId)
          .then((page: page) => {
            loading.dismiss();
            resolve(page);
          })
          .catch((error: any) => {
            loading.dismiss();
            reject(error);
          });
      }
    })
      .then(async (page: page) => {

        this.pages.setCurrent(page);
        this.pages.isModal(true);

        let pageModal = await this.modalService.create({
          component: DynamicPage,
          componentProps: {
            modalCtrl: this.modalService.getController(),
          },
          animated: true,
          canDismiss: true,
          presentingElement: document.getElementById(
            "ion-router-outlet-content"
          ),
          cssClass: "pageModal",
        });
        pageModal.onWillDismiss().then(() => {
          this.pages.isModal(false);
        });
        await pageModal.present();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  async viewPayments() {
    //this.modalService.closeAll();
    this.modalService.setIsModal(true);

    let paymentsModal = await this.modalService.create({
      component: PaymentPage,
      componentProps: {
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById("ion-router-outlet-content"),
      cssClass: "paymentsModal",
    });

    paymentsModal.onWillDismiss().then(() => {
      this.modalService.setIsModal(false);
    });

    await paymentsModal.present();
  }

  viewPost(itemId: number, post: post = null) {
    new Promise(async (resolve, reject) => {
      if (post) {
        resolve(post);
      } else {
        let loading = await this.loading.create({
          backdropDismiss: false,
          spinner: "circular",
        });
        await loading.present();
        this.posts
          .getPostByUid(itemId)
          .then((post: post) => {
            loading.dismiss();
            resolve(post);
          })
          .catch((error: any) => {
            loading.dismiss();
            reject(error);
          });
      }
    })
      .then(async (post: post) => {
        this.modalService.setIsModal(true);

        let postModal = await this.modalService.create({
          component: PostPage,
          componentProps: {
            post: post,
          },
          animated: true,
          canDismiss: true,
          presentingElement: document.getElementById('ion-router-outlet-content'),
          cssClass: "postModal",
        });

        postModal.onWillDismiss().then(() => {
          this.modalService.setIsModal(false);
        });

        await postModal.present();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  async viewProfile(userId: number, user: user = null) {
    new Promise(async (resolve, reject) => {
      if (user) {
        resolve(user);
      } else {
        let loading = await this.loading.create({
          backdropDismiss: false,
          spinner: "circular",
        });
        await loading.present();
        this.userService
          .getByUid(userId, false, false)
          .then((user: user) => {
            loading.dismiss();
            resolve(user);
          })
          .catch((error: any) => {
            loading.dismiss();
            reject(error);
          });
      }
    })
      .then(async (user: user) => {
        this.userService.setActiveProfile(user);
        this.modalService.setIsModal(true);

        let profileModal = await this.modalService.create({
          component: ProfilePage,
          animated: true,
          canDismiss: true,
          componentProps: {
            profile: user,
          },
          cssClass: "profileModal",
        });

        profileModal.onWillDismiss().then(() => {
          this.modalService.setIsModal(false);
        });

        await profileModal.present();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  async viewProduct(productId: number, product = null) {
    new Promise(async (resolve, reject) => {
      if (product) {
        resolve(product);
      } else {
        let loading = await this.loading.create({
          backdropDismiss: false,
          spinner: "circular",
        });
        await loading.present();
        this.shop
          .getProductByUid(productId)
          .then((product: product) => {
            loading.dismiss();
            resolve(product);
          })
          .catch((error: any) => {
            loading.dismiss();
            reject(error);
          });
      }
    })
      .then(async (product: product) => {
        this.modalService.setIsModal(true);

        let productModal = await this.modalService.create({
          component: DetailPage,
          animated: true,
          canDismiss: true,
          componentProps: {
            modalCtrl: this.modalService.getController(),
            product: product,
          },
          /*
        presentingElement: document.getElementById('ion-router-outlet-content'),
        */
          cssClass: "productModal",
        });

        productModal.onWillDismiss().then(() => {
          this.modalService.setIsModal(false);
        });

        await productModal.present();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  async viewStory(storyId: number, data: any = null) {
    let story: story = (data && data.story ? data.story : data);
    let index: number = (data.index ? data.index : null);

    new Promise(async (resolve, reject) => {
      if (story) {
        resolve(story);
      } else {
        reject("error_missing_story");
      }
    })
      .then(async (story: story) => {
        this.modalService.setIsModal(true);

        let storyModal = await this.modalService.create({
          component: StoryPage,
          componentProps: {
            modalCtrl: this.modalService.getController(),
            index: index,
            story: story,
          },
          animated: true,
          cssClass: "storyModal",
        });

        storyModal.onWillDismiss().then(() => {
          this.modalService.setIsModal(false);
        });

        await storyModal.present();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  async viewSurvey(surveyId: number, data: any = null) {
    let survey: survey = (data && data.survey ? data.survey : data);
    let index: number = (data.index ? data.index : null);

    new Promise(async (resolve, reject) => {
      if (survey) {
        resolve(survey);
      } else {
        reject("error_missing_survey");
      }
    })
      .then(async (survey: survey) => {
        this.modalService.setIsModal(true);

        let surveyModal = await this.modalService.create({
          component: SurveyPage,
          componentProps: {
            modalCtrl: this.modalService.getController(),
            index: index,
            survey: survey,
          },
          animated: true,
          cssClass: "defaultModal",
        });

        surveyModal.onWillDismiss().then(() => {
          this.modalService.setIsModal(false);
        });

        await surveyModal.present();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

}
