<ion-header translucent="true" class="ion-no-border">
    <ion-toolbar class="ion-no-border container">
        <ion-buttons slot="start">
            <ion-back-button *ngIf="!isModal"></ion-back-button>
            <ion-button *ngIf="isModal" icon-only (click)="dismiss()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title [innerHTML]="page.name"></ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

    <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
        <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
    </ion-refresher>
    
    <div class="container">
        <ion-spinner name="circular" [hidden]="page"></ion-spinner>
        <p *ngIf="page && page.value" [innerHTML]="page.value"></p>
    </div>

</ion-content>