import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { EventsService } from './events.service';

import { Network } from '@capacitor/network';
import { BackgroundService } from './background.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  interval: any;

  status: any;

  constructor(
    private background: BackgroundService,
    private events: EventsService,
    private translation: TranslateService,
  ) {
  }

  async getConnectionType() {
    let status = await this.getStatus();
    return status.connectionType;
  }

  getStatus() {
    return Network.getStatus();
  }

  init() {
    this.watch();

    Network.addListener('networkStatusChange', (status: any) => {
      this.events.publish('network:changed', status);
    });
  }

  async isConnected(blForceRefresh: boolean = false) {
    let blUseCachedConnectedState = (this.status && !blForceRefresh);
    this.status = blUseCachedConnectedState ? this.status : await this.getStatus();
    return !!this.status.connected;
  }

  showOfflineMessage() {
    let blIsBackground = this.background.isBackground();
    
    if(!blIsBackground) {
      this.translation.get('offline_toast_message')
      .subscribe((response: any) => {
        this.events.publish('toast', {
          message: response || 'offline_toast_message',
          color: 'primary',
        });
      });
    }
  }

  watch() {
    this.interval = setInterval(async () => {
      try {
        this.isConnected(true);
      } catch(e) {
        console.warn('network connection lookup failed', e);
      }
    }, (10 * 1000));
  }

}
