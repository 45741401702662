import { Injectable } from '@angular/core';
import { LoginPage } from '../pages/account/login/login.page';
import { AccountsService } from './accounts.service';
import { EventsService } from './events.service';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private accountsService: AccountsService,
    public events: EventsService,
    private modalService: ModalService,
  ) {

  }

  show(blShowLoginAlert: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.accountsService.setMultiMode(true);
      
      let loginModal = await this.modalService.create({
        component: LoginPage,
        componentProps: {
        },
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: 'loginModal'
      });

      loginModal.present();
      loginModal.onWillDismiss().then(resolve);

      if(!!blShowLoginAlert) {
        this.events.publish('error', 'error_action_requires_user_login');
      }

    });

  }

}
