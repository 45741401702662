<ion-grid class="pagination" [class.hasRelatedArticles]="
    post.relatedArticles && post.relatedArticles.length
  " *ngIf="user && user.uid">
  <ion-row>
    <ion-col>
      <ion-button fill="clear" color="dark" size="large" icon-only (click)="back()">
        <ion-icon name="arrow-back-circle-outline"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button fill="clear" color="dark" size="large" icon-only (click)="next()">
        <ion-icon name="arrow-forward-circle-outline"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>