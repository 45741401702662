<ion-header class="ion-no-border" translucent="true">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">
        <ion-button (click)="dismiss()" icon-only>
            <ion-icon name="close"></ion-icon>
        </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'add_to_collection'|translate"></ion-title>
    <ion-buttons slot="end">

      <ion-button (click)="createCollection()" icon-only>
        <ion-icon name="add-outline" slot="start"></ion-icon>
      </ion-button>

  </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true">

  <ion-card>
    <ion-card-header>
      <ion-card-subtitle [innerHTML]="'collection_picker_intro_headline'|translate"></ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <p [innerHTML]="'collection_picker_intro_text'|translate"></p>
    </ion-card-content>
  </ion-card>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <ion-spinner *ngIf="!collectionsData"></ion-spinner>
  
  <ion-grid *ngIf="collectionsData">

    <ion-row>

      <ion-col [size]="view.colSize" *ngFor="let collection of collectionsData">
        <ion-card [color]="view.selectedCollectionName === collection.name ? 'primary' : 'light'" (click)="use(collection)">
          <ion-card-header *ngIf="previewImages[collection.name]">
            <img *ngFor="let image of previewImages[collection.name]" [src]="image.src" />
          </ion-card-header>
          <ion-card-content>
            <ion-icon slot="start" *ngIf="icons[collection.name]" [name]="icons[collection.name]"></ion-icon>
            <ion-card-title [innerHTML]="collection.name|translate"></ion-card-title>
          </ion-card-content>
        </ion-card>
      </ion-col>

    </ion-row>

  </ion-grid>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-no-border">
    <ion-button (click)="save()" size="block" color="primary" [disabled]="!view.canSave" icon-only>
      <ion-icon name="checkmark-outline"></ion-icon>
      <ion-label [innerHTML]="'save'|translate"></ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>