<ion-list lines="none">

  <ion-item (click)="information($event)" *ngIf="col.type !== 'person'" class="ion-text-wrap">
    <ion-icon name="information-circle-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap" [innerHTML]="'information'|translate"></ion-label>
  </ion-item>

  <ion-item (click)="openExternal($event)" *ngIf="col.type !== 'person'" class="ion-text-wrap">
    <ion-icon name="open-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap" [innerHTML]="'open_in_browser'|translate"></ion-label>
  </ion-item>

  <ion-item (click)="openProfile($event)" *ngIf="!!col.host_uid" class="ion-text-wrap">
    <ion-icon name="person-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap" [innerHTML]="'open_profile'|translate"></ion-label>
  </ion-item>

  <ion-item (click)="delete($event)" *ngIf="col.type !== 'person' && (view.isOwnPost || view.isModerator)" class="ion-text-wrap">
    <ion-icon name="trash-outline" color="danger" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap" [innerHTML]="'delete'|translate"></ion-label>
  </ion-item>

  <ion-item (click)="rate($event)" *ngIf="col.type !== 'person'" class="ion-text-wrap">
    <ion-icon name="flag-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap" [innerHTML]="'rate'|translate"></ion-label>
  </ion-item>
  
  <ion-item (click)="report($event)" *ngIf="!view.isOwnPost && config.useReportingExtension" class="ion-text-wrap">
    <ion-icon name="warning-outline" color="warning" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap" [innerHTML]="'report_content'|translate"></ion-label>
  </ion-item>

  <ion-item (click)="move($event)" *ngIf="col.type !== 'person' && (view.isOwnPost || view.isModerator)" class="ion-text-wrap">
    <ion-icon name="folder-open-outline" color="warning" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap" [innerHTML]="'move'|translate"></ion-label>
  </ion-item>

</ion-list>