<ion-card color="primary" id="viewShortCard">
  <ion-card-header>
    <ion-card-title [innerHTML]="'post_view_short_register_headline'|translate"></ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <p [innerHTML]="'post_view_short_register_text'|translate"></p>
  </ion-card-content>
  <ion-card-header>
    <ion-button (click)="createAccount()" color="white" size="block">
      <ion-label [innerHTML]="'create_account'|translate"></ion-label>
      <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>
    </ion-button>
  </ion-card-header>
</ion-card>