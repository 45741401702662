<ion-card color="medium" class="relatedCard" *ngIf="post.relatedArticles && post.relatedArticles.length">
  <ion-card-header>
    <ion-card-title [innerHTML]="'related_articles'|translate"></ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col *ngFor="let post of post.relatedArticles" [hidden]="!post.name || !post.style" class="col-size-6"
          size="6">
          <ion-card [color]="post.color||'light'" (click)="readRelated(post, $event)">
            <ion-chip color="primary" class="sponsored" *ngIf="post.sponsored">
              <ion-label [innerHTML]="'sponsored'|translate"></ion-label>
            </ion-chip>

            <ion-fab class="vipBadge" horizontal="end" *ngIf="post.vip" vertical="bottom">
              <ion-fab-button color="warning" size="small">
                <ion-icon name="lock-closed"></ion-icon>
              </ion-fab-button>
            </ion-fab>
            <ion-fab class="verifiedBadge" horizontal="end" *ngIf="post.verified && !post.vip" vertical="bottom"
              (click)="verifiedInfo()">
              <ion-fab-button color="primary" size="small">
                <ion-icon name="shield-checkmark-outline"></ion-icon>
              </ion-fab-button>
            </ion-fab>
            <ion-card-header>
              <ion-button icon-only class="optionsButton" (click)="options(post, $event)" [hidden]="!post.allowed"
                [color]="post.color == 'light' ? 'dark' : 'light'" fill="clear">
                <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
              </ion-button>
              <ion-card-title *ngIf="post.name" [innerHTML]="post.name"></ion-card-title>
              <ion-card-subtitle *ngIf="post.subTitle" [innerHTML]="post.subTitle"></ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>