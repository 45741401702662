import { Component, OnInit, NgZone, Input } from '@angular/core';
import { Platform } from '@ionic/angular';

declare var google: any;

@Component({
  selector: 'pipeline-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  @Input() config: googleMapConfig;

  fallbackConfig: googleMapConfig = {
    id: 'googleMap',
    classes: 'map',
    size: {
      height: window.innerHeight,
      width: window.innerWidth,
    },
  };

  map: any;

  markers: any[] = [];

  place: any;

  search: any = {
    query: '',
  };

  view: any = {

  };

  constructor(
    private platform: Platform,
    private zone: NgZone,
  ) {
    this.config = this.config || this.fallbackConfig;
    this.config.size = this.config.size || this.fallbackConfig.size;
  }

  addCaseAfterMapClick(data: any) {
    this.zone.run(() => {
      this.cleanMap();

      let target = {
        lat: data[0].lat,
        lng: data[0].lng,
      };

      let markerData = {
        data: {
          value: {
            position: target,
            radius: 0,
          },
          temp: true,
        },
        draggable: true,
        position: target,
      };

      this.view.createMarker = this.addMarker(markerData);
      //this.showCreateModal();
    });
  }

  addMarker(markerData: any = {}) {
    if (!markerData.position) {
      console.warn("missing position", markerData);
      return false;
    }

    markerData.data = markerData.data || {};
    markerData.radius = markerData.radius || 0;

    let markerOptions: any = Object.assign(markerData, {
      title: markerData.title,
      data: markerData.data,
      animation: markerData.animation || "DROP",
      position: markerData.position,
      zIndex: 2,
    });

    let color = '#cc3000';

    let marker: any = new google.maps.Marker({
      position: markerData.position,
      map: this.map,
    });
    let circle = new google.maps.Circle({
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color,
      fillOpacity: 0.35,
      map: this.map,
      center: markerData.position,
      radius: 100
    });

    if (markerOptions.draggable) {
      /*
      marker.on(GoogleMapsEvent.MARKER_DRAG_END).subscribe(() => {
        this.geocoder
          .getCurrentLocation(marker.getPosition())
          .then((response: any) => {
            this.zone.run(() => {
              if (response.route && response.route.length) {
                this.footerCardData.blockUpdateTmpMarkerPosition = true;
                this.footerCardData.create.street = response.route;
                if (response.street_number && response.street_number.length) {
                  this.footerCardData.create.streetNo = response.street_number;
                }
              }
            });
          })
          .catch((error: any) => {
            console.error("error", error);
          });
      });
      */
    }

    if (!markerData.data.temp) {
      /*
      marker.on(GoogleMapsEvent.MARKER_CLICK).subscribe((data: any) => {
        this.loadMarkerDataToView(data);
      });
      circle.on(GoogleMapsEvent.CIRCLE_CLICK).subscribe((data: any) => {
        this.loadMarkerDataToView([data[0], data[1]]);
      });
      */
    }

    let params = {
      circle: circle,
      marker: marker,
    };

    this.markers.push(params);

    return params;
  }

  cleanMap(mapFilterOptions: any = {}) {
    let blRemoveIndexes = [];

    this.markers.forEach((params: any, index: number) => {

      let marker: any = <any>Object.values(params.marker)[0],
          circle: any = <any>Object.values(params.circle)[0],
          markerData = marker.get('data') || {};

      let blRemove = markerData.temp || mapFilterOptions.removeAllMarkers;

      if (blRemove) {
        blRemoveIndexes.push(index);
        marker.remove();
        if(circle) {
          circle.remove();
        }
      }

      if (circle && (mapFilterOptions.removeAllMarkers || mapFilterOptions.removeAllCircles)) {
        circle.remove();
      }
    });

    this.markers = this.markers.filter((params: any, index: number) => {
      return blRemoveIndexes.indexOf(index) !== -1;
    });
  }

  getMapOptions() {

    let target = {
      lat: 51.133481,
      lng: 10.018343,
    };

    let mapOptions = {
      camera: {
        target: target,
        zoom: 6,
        tilt: 30,
      },
      controls: {
        compass: false,
        myLocationButton: false,
        myLocation: false, // (blue dot)
        indoorPicker: false,
        zoom: false, // android only
        mapToolbar: false, // android only
      },
    };

    return mapOptions;
  }

  loadMap() {
    return new Promise((resolve, reject) => {
      let mapOptions = this.getMapOptions();
      console.log('mapOptions', mapOptions);

      this.map = new google.maps.Map(document.getElementById(this.config.id) as HTMLElement, {
        center: mapOptions.camera.target,
        zoom: mapOptions.camera.zoom,
        disableDefaultUI: true,
      });

      google.maps.event.addListener(this.map, 'click', (event: any) => {
        this.onMapClick(event);
      });

      resolve(this.map);
    });
  }

  ngOnInit() {
    this.config = this.config || this.fallbackConfig;
    this.config.size = this.config.size || this.fallbackConfig.size;
    
    this.platform.ready().then(() => {
      this.loadMap();
    })
  }

  onMapClick(data: any) {
    let zoom = this.map.getZoom();

    if(zoom <= 10) {
      return false;
    } else
    if (data && data.latLng && data.latLng.lat() && data.latLng.lng() && this.place) {
      this.addCaseAfterMapClick([{
        lat: data.latLng.lat(),
        lng: data.latLng.lng(),
      }]);
    }

  }

}