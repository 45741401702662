import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(
  ) {
  }

  async create(
    url: string,
  ) {
    try {
      await Browser.open({
        url: url,
        presentationStyle: 'popover',
      });
    } catch(e) {
      console.warn('> browser error', e);
    }
  }

}
