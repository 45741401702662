import { Injectable } from '@angular/core';

import { AppcmsService } from './appcms.service';
import { BasketService } from './basket.service';
import { BrowserService } from './browser.service';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root'
})
export class KlarnaService {

  paymentOptions: any;

  constructor(
    private AppCMS: AppcmsService,
    private basket: BasketService,
    private browser: BrowserService,
    private device: DeviceService,
  ) {

  }

  buildKlarnaHiddenInputs(data: any, key: string) {
    if (!data) {
      return;
    }
    if (typeof data === 'string') {
      return '<input type="hidden" name="' + key + '" value="' + data + '" />';
    } else
      if (data instanceof Array) {
        let html = '';
        data.forEach((dataItem: any, index: number) => {
          if (dataItem) {
            if (typeof dataItem == 'object') {
              Object.keys(dataItem).forEach((dataSubItemKey: any) => {
                if (data[index][dataSubItemKey]) {
                  if (typeof data[index][dataSubItemKey] == 'object') {
                    Object.keys(data[index][dataSubItemKey]).forEach((dataSubSubItemKey: any) => {
                      if (data[index][dataSubItemKey][dataSubSubItemKey]) {
                        if (typeof data[index][dataSubItemKey][dataSubSubItemKey] == 'object') {
                          Object.keys(data[index][dataSubItemKey][dataSubSubItemKey]).forEach((dataSubSubSubItemKey: any) => {
                            if (data[index][dataSubItemKey][dataSubSubItemKey][dataSubSubSubItemKey]) {
                              html += '<input type="hidden" name="' + key + '[' + index + '][' + dataSubItemKey + '][' + dataSubSubItemKey + '][' + dataSubSubSubItemKey + ']" value="' + data[index][dataSubItemKey][dataSubSubItemKey][dataSubSubSubItemKey] + '" />';
                            }
                          });
                        } else {
                          html += '<input type="hidden" name="' + key + '[' + index + '][' + dataSubItemKey + ']" value="' + data[index][dataSubItemKey] + '" />';
                        }
                        html += '<input type="hidden" name="' + key + '[' + index + '][' + dataSubItemKey + '][' + dataSubSubItemKey + ']" value="' + data[index][dataSubItemKey][dataSubSubItemKey] + '" />';
                      }
                    });
                  } else {
                    html += '<input type="hidden" name="' + key + '[' + index + '][' + dataSubItemKey + ']" value="' + data[index][dataSubItemKey] + '" />';
                  }
                }
              });
            } else {
              html += '<input type="hidden" name="' + key + '[' + index + ']" value="' + dataItem + '" />';
            }
          }
        });
        return html;
      } else
        if (typeof data === 'object') {
          let html = '';
          //console.log('data (b)', data);
          Object.keys(data).forEach((dataItemKey: any) => {
            if (!data[dataItemKey]) {
              return;
            }
            if (typeof data[dataItemKey] == 'object') {
              Object.keys(data[dataItemKey]).forEach((dataSubItemKey: any) => {
                if (data[dataItemKey][dataSubItemKey]) {
                  html += '<input type="hidden" name="' + key + '[' + dataItemKey + '][' + dataSubItemKey + ']" value="' + data[dataItemKey][dataSubItemKey] + '" />';
                }
              });
            } else
              if (data[dataItemKey]) {
                html += '<input type="hidden" name="' + key + '[' + dataItemKey + ']" value="' + data[dataItemKey] + '" />';
              }
          });
          return html;
        } else {
          console.warn('unknown klarna hidden input type', data, key);
          return;
        }
  }


  buildKlarnaPageContent(Params: any) {
    let inputs: string = '';

    if(typeof Params === 'object') {
      Object.keys(Params).forEach((key: string) => {
        inputs += this.buildKlarnaHiddenInputs(Params[key], key)
      });
    }

    return '<html><head></head><body>' +
      '<form id="form" action="' + this.AppCMS.getApiUrl() + '/payments/klarna/create.json" method="post">' + inputs + '</form>' +
      '<script type="text/javascript">document.getElementById("form").submit();</script>' +
      '</body></html>';
  }

  getPaymentOptions() {
    return this.paymentOptions;
  }

  async pay() {

    let Articles = [];

    let basketInfo: basketInfo = await this.basket.calculateBasketInfo();
    let basketStorage: any = await this.basket.getBasket();
    let paymentOptions: any = this.getPaymentOptions();

    if(basketStorage) {
      basketStorage.forEach((Article: any) => {
        Articles.push(this.basket.toBasketItem(Article));
      });
    }
    
    var Params = {
      articles: Articles,
      basketInfo: basketInfo,
      total: basketInfo.price_a,
      device: this.device.getDeviceUid(),
      apiUrl: this.AppCMS.getApiUrl(),
      paymentMethod: paymentOptions.paymentMethod
    };

    var pageContent = this.buildKlarnaPageContent(Params);
    var pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);

    let browserWindow = this.browser.create(
      pageContentUrl,
    );
    console.log('> browserWindow', browserWindow);
    
    /*
    if (browserWindow) {
      browserWindow.on('loadstart').subscribe((data: any) => {
        //console.log('browser: loadstart', data.url);
        if (data.url.indexOf('klarna/failed.json') > 0) {
          browserWindow.close();
          this.orderFailed();
        }
      });
      browserWindow.on('loadstop').subscribe((data: any) => {
        //console.log('browser: loadstop', data.url);
        if (data.url.indexOf('klarna/success.json') > 0) {
          browserWindow.close();
          this.block = true;
          let device: any = this.device.getDevice() || {};
          this.orders.getByTime(2, device.uid)
            .then((Response: any) => {
              this.block = true;
              if (Response.success === false || Response.message) {
                this.orderFailed(Response.message);
              } else {
                this.afterSuccessPay();
              }
            })
            .catch((error: any) => {
              this.events.publish('error', error);
            });
        }
      });
      browserWindow.on('exit').subscribe(() => {
        this.block = false;
      });
    }
    */

  }

  setPaymentOptions(paymentOptions: any) {
    this.paymentOptions = paymentOptions;
  } 

}
