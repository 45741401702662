<ion-card
  [color]="post.color || 'light'"
  class="postCard homeCard hasBackground accelerated card-size-{{
    post.size || 12
  }}"
  [class.admin]="!!(options && options.admin)"
  [class.full-width]="post.size === 12 || !post.size"
  [class.hasHeader]="post.avatar || post.hostLabel"
  [class.person]="post.type === 'person'"
  [class.mini]="!!(options && options.mini)"
  [class.reel]="!!(options && options.reel)"
  [class.video]="!!post.video"
>
  <ion-fab
    class="vipBadge"
    horizontal="end"
    *ngIf="post.vip"
    [vertical]="post.host ? 'top' : 'bottom'"
    [edge]="post.host"
  >
    <ion-fab-button color="warning" size="small">
      <ion-icon name="lock-closed"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-fab
    class="verifiedBadge"
    horizontal="end"
    *ngIf="
      config.showPostCardVerifiedBadges &&
      post.verified &&
      !post.vip &&
      post.host
    "
    vertical="top"
    edge
    (click)="verifiedInfo()"
  >
    <ion-fab-button color="primary" size="small">
      <ion-icon name="shield-checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-card-header (click)="onColClick(post)" [class.loaded]="!!post.uid">
    <ion-thumbnail *ngIf="!!post.thumbnail && !view.video_formatted">
      <ion-img
        [src]="post.thumbnail"
        (ionError)="thumbnailLoadingFailed(post)"
      ></ion-img>
    </ion-thumbnail>
    
    <div class="video-wrapper" *ngIf="!!view.video_formatted" [innerHTML]="view.video_formatted"></div>
    <ion-spinner *ngIf="!!post.video"></ion-spinner>

    <ion-skeleton-text
      class="image"
      *ngIf="!post.thumbnail"
      [animated]="!post.uid"
    ></ion-skeleton-text>
  </ion-card-header>

  <ion-chip color="primary" class="sponsored" *ngIf="post.sponsored">
    <ion-label [innerHTML]="'sponsored' | translate"></ion-label>
  </ion-chip>

  <ion-item
    class="postHeaderItem"
    lines="none"
    *ngIf="
      !(options && options.mini) &&
      (post.avatar ||
        post.name ||
        post.host ||
        post.hostLabel ||
        post.date_gmt_formatted ||
        !post.uid)
    "
    [color]="post.color || 'light'"
    [class.hasAvatar]="post.avatar"
  >
    <ion-avatar slot="start" *ngIf="!!post.avatar">
      <ion-img [src]="post.avatar"></ion-img>
    </ion-avatar>

    <ion-label (click)="openProfile(post)">
      <h3
        *ngIf="config.showPostCardHostLabel && post.host"
        [innerHTML]="post.host"
      ></h3>
      <ion-skeleton-text
        *ngIf="!post.uid"
        style="width: 40%; margin-right: 40%;"
        animated
      ></ion-skeleton-text>

      <h4
        class="ion-text-wrap"
        [innerHTML]="post.hostLabel | translate"
        *ngIf="post.hostLabel"
      ></h4>
      <p
        [innerHTML]="post.date_gmt_formatted"
        *ngIf="post.date_gmt_formatted"
      ></p>
    </ion-label>

    <ion-button
      slot="end"
      icon-only
      class="optionsButton"
      (click)="cardOptions(post, $event)"
      [hidden]="!post.uid"
      *ngIf="config.showPostCardOptions"
      color="dark"
      fill="clear"
    >
      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-card-content [class.after]="!!(options && options.reel)">
    <ion-card-title
      (click)="onColClick(post)"
      *ngIf="!!post.name"
      [hidden]="!post.name"
      [innerHTML]="post.name"
    ></ion-card-title>

    <ion-skeleton-text
      class="big"
      *ngIf="!post.uid"
      style="width: 55%;"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="big"
      *ngIf="!post.uid"
      style="width: 30%; margin-left: 10px;"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="big"
      *ngIf="!post.uid"
      style="width: 80%;"
      animated
    ></ion-skeleton-text>

    <p
      (click)="onColClick(post)"
      *ngIf="
        config.showPostCardExcerpt &&
        options &&
        options.show_excerpt !== false &&
        !!post.excerpt
      "
      [innerHTML]="post.excerpt"
    ></p>

    <ion-skeleton-text
      class="excerpt"
      style="width: 40%;"
      *ngIf="!post.uid"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="excerpt"
      style="width: 50%; margin-left: 10px;"
      *ngIf="!post.uid"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="excerpt"
      style="width: 70%;"
      *ngIf="!post.uid"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="excerpt"
      style="width: 20%; margin-left: 10px;"
      *ngIf="!post.uid"
      animated
    ></ion-skeleton-text>
    <ion-skeleton-text
      class="excerpt margin-bottom"
      style="width: 35%;"
      *ngIf="!post.uid"
      animated
    ></ion-skeleton-text>
  </ion-card-content>

  <ion-card-header
    class="footer-bar"
    [hidden]="!post.uid"
    *ngIf="config.useReactions || config.useCommentsExtension"
  >
    <ion-segment
      [class.has-comments-btn]="config.useCommentsExtension"
      scrollable
      (ionChange)="onReactionsSegmentChanged()"
      [(ngModel)]="view.reactionsSegment"
      class="reactions"
      *ngIf="config.useReactions"
    >
      <ion-segment-button
        *ngFor="let emoji of view.emojis; let iEmoji = index"
        (click)="react(emoji)"
        [hidden]="iEmoji > 2 && !view.expandReactionsView"
        [value]="emoji"
        icon-only
      >
        <ion-label>
          <span [innerHTML]="emoji.emoji"></span>
          <small
            *ngIf="post && post.reactions && post.reactions[emoji.name]"
            [innerHTML]="post.reactions[emoji.name].count"
          ></small>
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="expand" icon-only>
        <ion-icon
          [name]="
            !view.expandReactionsView
              ? 'chevron-forward-outline'
              : 'chevron-back-outline'
          "
        ></ion-icon>
      </ion-segment-button>
    </ion-segment>

    <ion-button
      *ngIf="config.useCommentsExtension"
      class="comment-btn"
      fill="clear"
      color="primary"
      (click)="commentPost()"
    >
      <ion-icon name="chatbubbles-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'comment_post'|translate" *ngIf="!config.useReactions"></ion-label>
    </ion-button>
  </ion-card-header>

  <ion-fab
    id="adminBadge"
    *ngIf="config.showPostCardAdminFab && options.admin"
    vertical="bottom"
    horizontal="end"
    edge
    size="small"
  >
    <ion-fab-button fill="clear" size="small">
      <ion-icon name="lock-closed-outline" color="white"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-fab-button (click)="accept()" icon-only color="success">
        <ion-icon name="checkmark-outline" color="white"></ion-icon>
      </ion-fab-button>
      <ion-fab-button (click)="reject()" icon-only color="danger">
        <ion-icon name="hand-left-outline" color="white"></ion-icon>
      </ion-fab-button>
      <ion-fab-button (click)="editPost()" icon-only color="primary">
        <ion-icon name="create-outline" color="white"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
</ion-card>