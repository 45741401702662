import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from '../../../services/events.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'pipeline-post-pagination',
  templateUrl: './post-pagination.component.html',
  styleUrls: ['./post-pagination.component.scss'],
})
export class PostPaginationComponent implements OnInit {
  @Input() post: post;

  user: user;

  constructor(
    private events: EventsService,
    public userService: UserService,
  ) {
    this.user = this.userService.getUser() || {};
  }
  
  back() {
    this.events.publish('post:back');
  }

  next() {
    this.events.publish('post:next');
  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};
  }

}
