import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { LoadingController } from '@ionic/angular';

import { EventsService } from './events.service';
import { UserService } from './user.service';
import { CpmService } from './cpm.service';
import { BasketService } from './basket.service';

import * as moment from 'moment';
import { PostsService } from './posts.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  addAddons: adAddon[] = [
    {
      icon: 'text-outline',
      subtitle: '(800 - 1000 Wörter)',
      title: 'Redaktioneller Beitrag',
      type: 'content-creation',
      price: 400,
    },
    {
      icon: 'color-palette-outline',
      subtitle: 'für alle Kanäle',
      title: 'Grafische Erstellung',
      type: 'graphics-creation',
      price: 200,
    },
    {
      icon: 'language-outline',
      subtitle: 'pro Sprache',
      title: 'Übersetzung',
      type: 'translations',
      price: 40,
    },
    {
      icon: 'notifications-outline',
      subtitle: '10 Stück',
      title: 'Benachrichtigungen',
      type: 'push-notifications',
      price: 50,
    },
    {
      icon: 'newspaper-outline',
      subtitle: '(600 - 800 Wörter)',
      title: 'Exklusives Interview',
      type: 'exclusive-interview',
      price: 300,
    },
    {
      icon: 'share-social-outline',
      subtitle: 'Externe Platzierung',
      title: 'Social Media Promo',
      type: 'social-media-promo',
      price: 200,
    },
    {
      icon: 'star-outline',
      subtitle: 'Prominente Platzierung',
      title: 'Beitrag der Woche',
      type: 'post-of-the-week',
      price: 450,
    },
  ];

  adTypes: adType[] = [
    {
      icon: 'text-outline',
      title: 'Text-Beitrag',
      type: 'text',
    },
    {
      icon: 'person-outline',
      title: 'Profil',
      type: 'profile',
    },
    {
      icon: 'bag-handle-outline',
      title: 'Produkt',
      type: 'product',
    },
    /*
    {
      icon: 'heart-circle-outline',
      title: 'Story',
      type: 'story',
    },
    */
    {
      icon: 'mic-outline',
      title: 'Podcast',
      type: 'podcast',
    },
    {
      icon: 'videocam-outline',
      title: 'Video',
      type: 'video',
    },
  ];
  
  config: adsConfig = {
    amountUsers: 10000,
    basePricePerClick: 0.5,
  };

  iPostsBetweenAds: number = 10;
  
  locations: any = {
    text: [
      {
        description: 'ad_location_posts_between_description',
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.between',
        name: 'ad_location_posts_between',
      },
      {
        description: 'ad_location_posts_small_description',
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.small',
        name: 'ad_location_posts_small',
      },
      {
        description: 'ad_location_posts_large_description',
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.large',
        name: 'ad_location_posts_large',
      },
      {
        description: 'ad_location_posts_related_description',
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_reader_aehnliche.svg',
        indent: 'ad.location.posts.related',
        name: 'ad_location_posts_related',
      },
      {
        description: 'ad_location_posts_web_description',
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_reader.svg',
        indent: 'ad.location.posts.web',
        name: 'ad_location_posts_web',
      },
      {
        description: 'ad_location_profiles_default_description',
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.profiles.default',
        name: 'ad_location_profiles_default',
      },
      {
        description: 'ad_location_stories_between_description',
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
    ],
    podcast: [
      {
        description: 'ad_location_posts_between_description',
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_feed.svg',
        indent: 'ad.location.posts.between',
        name: 'ad_location_posts_between',
      },
      {
        description: 'ad_location_posts_small_description',
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_feed.svg',
        indent: 'ad.location.posts.small',
        name: 'ad_location_posts_small',
      },
      {
        description: 'ad_location_posts_large_description',
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_feed.svg',
        indent: 'ad.location.posts.large',
        name: 'ad_location_posts_large',
      },
      {
        description: 'ad_location_posts_related_description',
        image: './assets/img/ad-manager/placements/podcast/placement_podcasts_in_reader_aehnliche.svg',
        indent: 'ad.location.posts.related',
        name: 'ad_location_posts_related',
      },
      {
        description: 'ad_location_posts_web_description',
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_reader.svg',
        indent: 'ad.location.posts.web',
        name: 'ad_location_posts_web',
      },
      {
        description: 'ad_location_profiles_default_description',
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_feed.svg',
        indent: 'ad.location.profiles.default',
        name: 'ad_location_profiles_default',
      },
      {
        description: 'ad_location_stories_between_description',
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
    ],
    products: [
      {
        description: 'ad_location_products_between_description',
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_feed.svg',
        indent: 'ad.location.posts.between',
        name: 'ad_location_products_between',
      },
      {
        description: 'ad_location_products_small_description',
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_feed.svg',
        indent: 'ad.location.posts.small',
        name: 'ad_location_products_small',
      },
      {
        description: 'ad_location_products_large_description',
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_feed.svg',
        indent: 'ad.location.posts.large',
        name: 'ad_location_products_large',
      },
      {
        description: 'ad_location_products_related_description',
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_reader_aehnliche.svg',
        indent: 'ad.location.posts.related',
        name: 'ad_location_products_related',
      },
      {
        description: 'ad_location_products_web_description',
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_reader.svg',
        indent: 'ad.location.posts.web',
        name: 'ad_location_products_web',
      },
      {
        description: 'ad_location_products_default_description',
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_feed.svg',
        indent: 'ad.location.profiles.default',
        name: 'ad_location_products_default',
      },
      {
        description: 'ad_location_stories_between_description',
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
    ],
    profile: [
      {
        description: 'ad_location_profiles_between_description',
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_feed.svg',
        indent: 'ad.location.profiles.between',
        name: 'ad_location_profiles_between',
      },
      {
        description: 'ad_location_profiles_small_description',
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_feed.svg',
        indent: 'ad.location.profiles.small',
        name: 'ad_location_profiles_small',
      },
      {
        description: 'ad_location_profiles_large_description',
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_feed.svg',
        indent: 'ad.location.profiles.large',
        name: 'ad_location_profiles_large',
      },
      {
        description: 'ad_location_profiles_related_description',
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_reader_aehnliche.svg',
        indent: 'ad.location.profiles.related',
        name: 'ad_location_profiles_related',
      },
      {
        description: 'ad_location_profiles_web_description',
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_reader.svg',
        indent: 'ad.location.profiles.web',
        name: 'ad_location_profiles_web',
      },
      {
        description: 'ad_location_profiles_default_description',
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_feed.svg',
        indent: 'ad.location.profiles.default',
        name: 'ad_location_profiles_default',
      },
      {
        description: 'ad_location_stories_between_description',
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
    ],
    video: [
      {
        description: 'ad_location_posts_between_description',
        image: './assets/img/ad-manager/placements/video/placement_video_in_feed.svg',
        indent: 'ad.location.posts.between',
        name: 'ad_location_posts_between',
      },
      {
        description: 'ad_location_posts_small_description',
        image: './assets/img/ad-manager/placements/video/placement_video_in_feed.svg',
        indent: 'ad.location.posts.small',
        name: 'ad_location_posts_small',
      },
      {
        description: 'ad_location_posts_large_description',
        image: './assets/img/ad-manager/placements/video/placement_video_in_feed.svg',
        indent: 'ad.location.posts.large',
        name: 'ad_location_posts_large',
      },
      {
        description: 'ad_location_posts_related_description',
        image: './assets/img/ad-manager/placements/video/placement_videos_in_reader_aehnliche.svg',
        indent: 'ad.location.posts.related',
        name: 'ad_location_posts_related',
      },
      {
        description: 'ad_location_posts_web_description',
        image: './assets/img/ad-manager/placements/video/placement_video_in_reader.svg',
        indent: 'ad.location.posts.web',
        name: 'ad_location_posts_web',
      },
      {
        description: 'ad_location_profiles_default_description',
        image: './assets/img/ad-manager/placements/video/placement_video_in_feed.svg',
        indent: 'ad.location.profiles.default',
        name: 'ad_location_profiles_default',
      },
      {
        description: 'ad_location_stories_between_description',
        image: './assets/img/ad-manager/placements/video/placement_video_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
    ],
  };

  locationTranslations: any;

  constructor(
    private AppCMS: AppcmsService,
    private basket: BasketService,
    private cpm: CpmService,
    private events: EventsService,
    private loadingCtrl: LoadingController,
    private posts: PostsService,
    private tools: ToolsService,
    public userService: UserService,
  ) {
  }

  addAdsToPosts(ads: ad[], posts: post[]) {
    ads = this.tools.shuffle(ads);
    
    return new Promise(async (resolve, reject) => {
      if(ads && ads.length && posts && posts.length) {
        let iCurrentAd: number = 0,
            iMax: number = (ads.length-1),
            iPostsMax: number = (posts.length-1);

        posts.forEach((post: post, index: number) => {
          let blAddAd: boolean = !!index && (index % this.iPostsBetweenAds === 0),
              ad = ads[iCurrentAd];

          if(!!blAddAd) {
            if(!!ad) {
              iCurrentAd++;
              posts.splice(index, 0, ad as post);
  
              if(iCurrentAd === iMax) {
                resolve(posts as post[]);
              }
            } else {
              resolve(posts as post[]);
            }

          } else
          if((iCurrentAd === iMax) || !ad) {
            resolve(posts as post[]);
          } else
          if(index === iPostsMax) {
            resolve(posts as post[]);
          }
        });
      } else {
        resolve(posts as post[]);
      }
    });
  }

  calcStats(ad: ad, adAddons: adAddon[] = null) {
    return new Promise(async (resolve, reject) => {
      try {
        
        let diff: number = moment(ad.date_end).diff(moment(ad.date_start)),
            durationInDays: number = parseInt((parseInt(moment.duration(diff).asHours() as any) / 24) as any);

        ad.stats.durationInDays = durationInDays;

        let cpmPrice = await this.cpm.getCpmPrice(ad);

        let pricePerView: number = 0,
            budgetVolume: number = parseFloat((durationInDays * ad.budgetPerDay).toFixed(2));
  
        let factor = await this.getFactor(ad);

        if(factor !== 1 && cpmPrice) {
          cpmPrice = (parseFloat((cpmPrice as string)) * factor);
        }

        budgetVolume = parseFloat(budgetVolume.toFixed(2));

        let viewsOverTime = parseInt((budgetVolume / ((cpmPrice as number) / 1000)) + ''),
            viewsPerDay = parseInt((viewsOverTime / durationInDays) + '');

        if(cpmPrice) {
          pricePerView = this.cpm.getCostPerView(cpmPrice as number);
        } else {
          pricePerView = this.cpm.getCostPerView(
            ((budgetVolume / viewsOverTime) / factor) as number
          );
        }

        viewsOverTime = parseInt((budgetVolume / ((cpmPrice as number) / 1000)) + '');
        viewsPerDay = parseInt((viewsOverTime / durationInDays) + '');

        let stats: adStats = {
          budgetVolume: budgetVolume,
          durationInDays: durationInDays,
          pricePerView: pricePerView,
          viewsOverTime: viewsOverTime,
          viewsPerDay: viewsPerDay,
        };
        
        if(adAddons && adAddons.length) {
          let addon_price: number = 0;
          adAddons.forEach((adAddon: adAddon) => {
            if(adAddon.checked) {
              addon_price += adAddon.price;
            }
          });
          stats.addons = {
            price: addon_price,
          };
          stats.budgetVolume = stats.budgetVolume + addon_price;
        }
          
        if(cpmPrice) {
          stats.cpmPrice = cpmPrice as number;
        }

        resolve(stats);
      } catch(e) {
        reject(e);
      };
    });
  }

  async create(ad: ad) {

    let loading = await this.loadingCtrl.create({
      spinner: 'circular',
    });

    if(ad.stats) {
      ad.budgetVolume = ad.budgetVolume || ad.stats.budgetVolume;
      ad.pricePerView = ad.pricePerView || ad.stats.pricePerView;
      ad.viewsOverTime = ad.viewsOverTime || ad.stats.viewsOverTime;
      ad.viewsPerDay = ad.viewsPerDay || ad.stats.viewsPerDay;
    }

    return new Promise((resolve, reject) => {  
      this.pay(ad)
      .then((response) => {
        loading.present();

        this.AppCMS.loadPluginData('pipeline', {
          ad: ad,
          
        }, ['ads', 'create'])
        .then((response: any) => {
          loading.dismiss();
          resolve(response);
        })
        .catch((error) => {
          loading.dismiss();
          reject(error);
        });
      })
      .catch(reject);
    });
  }

  async createTargetGroup(targetGroup: targetGroup) {

    let loading = await this.loadingCtrl.create({
      spinner: 'circular',
    });
    loading.present();
    
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        targetGroup: targetGroup,
        
        
      }, ['ads', 'target_groups', 'create'])
      .then((response: any) => {
        loading.dismiss();
        resolve(response);
      })
      .catch((error) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

  async delete(adId: number) {

    let loading = await this.loadingCtrl.create({
      spinner: 'circular',
    });
    loading.present();
    
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        ad: adId,
        
      }, ['ads', 'delete'])
      .then((response: any) => {
        loading.dismiss();
        resolve(response);
      })
      .catch((error) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

  async deleteTargetGroup(groupId: number) {

    let loading = await this.loadingCtrl.create({
      spinner: 'circular',
    });
    loading.present();
    
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        group: groupId,
      }, ['ads', 'target_groups', 'delete'])
      .then((response: any) => {
        loading.dismiss();
        resolve(response);
      })
      .catch((error) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

  getAdAddons() {
    return this.addAddons;
  }
  
  getAdTypes() {
    return this.adTypes;
  }

  getAll(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        
      }, ['ads'], {}, blForceRefresh)
      .then((ads: ad[]) => {
        if(ads && ads.length) {
          ads.forEach((ad: ad) => {
            ad = this.getFullAd(ad);
          });
        }
        resolve(ads);
      })
      .catch(reject);
    });
  }

  async getFactor(ad: ad) {
    let factorAll: number = 0;
    let entries: cpmEntry[] = await this.cpm.getMatchingCpmEntriesForAd(ad);

    entries.forEach((entry: cpmEntry) => {
      factorAll += entry.factor;
    });
    
    return (factorAll / entries.length) || 1;
  }

  getFullAd(ad: ad) {
    ad.date_end_formatted = moment(ad.date_end).format('DD.MM.YYYY');
    ad.date_start_formatted = moment(ad.date_start).format('DD.MM.YYYY');
    return ad;
  }

  getFullTargetGroup(targetGroup: targetGroup)
  {
    return targetGroup;
  }

  getLocations() {
    return this.locations;
  }

  getLocationTranslations() {
    if(!this.locationTranslations) {
      let locations = this.getLocations();
      let locationTranslations: any = {};
  
      Object.keys(locations).forEach((locationKey: string) => {
        locations[locationKey].forEach((location: adLocation) => {
          locationTranslations[location.indent] = location.name;
        });
      });
  
      this.locationTranslations = locationTranslations;
    }
    return this.locationTranslations;
  }
  
  async getTargetGroups(blForceRefresh: boolean = false)
  {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
      }, ['ads', 'target_groups'], {}, blForceRefresh)
      .then((target_groups: targetGroup[]) => {
        if(target_groups && target_groups.length) {
          target_groups.forEach((targetGroup: targetGroup) => {
            targetGroup = this.getFullTargetGroup(targetGroup);
          });
        }
        resolve(target_groups);
      })
      .catch(reject);
    });
  }

  async parseAdToPost(ad: ad) {

    if(!ad.post && !!ad.item && (ad.type === 'text')) {
      try {
        ad.post = (await this.posts.getPostByUid(ad.item)) as post;
      } catch(e) {
        console.warn('> load ad post error', e);
      }
    }

    if(!!ad.post) {
      ad.post.category = 'discover';
      ad.post.sponsored = true;
      ad.post.vip = false;
    }

    return ad.post;
  }

  pay(ad: ad) {
    return new Promise((resolve, reject) => {
      let user = this.userService.getUser(),
          isAdmin: boolean = this.userService.isType('Admin');

      // @debug
      //isAdmin = false;

      if(isAdmin) {
        resolve({});
      } else {
        this.basket.add({
          amount: 1,
          name: 'Werbeanzeige: ' + ad.label,
          price: ad.budgetVolume,
        })
        .then(() => {
          this.events.publish('view:basket');
        })
        .catch(reject);
      }
    });
  }

  async update(ad: ad) {
    ad = JSON.parse(JSON.stringify(ad));
    delete ad.date_end_formatted;
    delete ad.date_start_formatted;

    let loading = await this.loadingCtrl.create({
      spinner: 'circular',
    });
    loading.present();

    if(ad.stats) {
      ad.budgetVolume = ad.budgetVolume || ad.stats.budgetVolume;
      ad.pricePerView = ad.pricePerView || ad.stats.pricePerView;
      ad.viewsOverTime = ad.viewsOverTime || ad.stats.viewsOverTime;
      ad.viewsPerDay = ad.viewsPerDay || ad.stats.viewsPerDay;
    }

    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        ad: ad,
        
      }, ['ads', 'update'])
      .then((response: any) => {
        loading.dismiss();
        resolve(response);
      })
      .catch((error) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

  async updateTargetGroup(targetGroup: targetGroup) {

    let loading = await this.loadingCtrl.create({
      spinner: 'circular',
    });
    loading.present();
    
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        targetGroup: targetGroup,
      }, ['ads', 'target_groups', 'update'])
      .then((response: any) => {
        loading.dismiss();
        resolve(response);
      })
      .catch((error) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

}
