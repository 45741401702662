import { Injectable } from '@angular/core';

import { AbonnementService } from './abonnement.service';
import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';
import { SourcesService } from './sources.service';
import { ToolsService } from './tools.service';
import { UserService } from './user.service';

import * as moment from 'moment';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(
    private abo: AbonnementService,
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private config: ConfigService,
    private sources: SourcesService,
    private tools: ToolsService,
    public userService: UserService,
  ) {
    this.initOrderBy();
  }

  cachePost(post: post, options: any = {}) {
    let postId = post.uid || post.ID,
        key = "pipeline_fullPost_" + postId + "_" + JSON.stringify(options);

    post = JSON.parse(JSON.stringify(post));
    delete post.parsed;

    this.cache.set(key, post);
  }

  deletePost(postId: number) {
    return this.AppCMS.loadPluginData("pipeline", {}, [
      "posts",
      postId,
      "delete",
    ]);
  }

  async getCachedOrderByState() {
    let fromCache: cacheItem = await this.cache.get('_orderBy', -1);
    return (fromCache && !!fromCache.data ? fromCache.data : 'auto');
  }

  getFullPost(post: any) {
    
    if(!!post.parsed) {
      return post;
    }

    post.excerpt = this.tools.stripHtml(
      (this.tools.cleanContent(post, 'excerpt') || this.tools.cleanContent(post, 'post_excerpt'))
    );

    post.thumbnail = (post.thumbnail && post.thumbnail.length ? post.thumbnail : post.image);
    post.vip = this.abo.calcVip(post);

    post.date_gmt = (post.date_gmt || post.post_date_gmt);
    post.modified_gmt = (post.modified_gmt || post.post_modified_gmt);

    if(!!post && (post.type === 'feed_post') && !post.url && !!post.uid) {
      post.hostLabel = `${post.name}`;
      post.name = '';
      post.url = `https://open.pipeline.page/${post.uid}`;
    }

    return {
      active: post.active,
      allowed: !!(post.allowed !== null ? post.allowed : !post.vip),
      app: post.app,
      avatar: post.avatar,
      category: (post.category || 'discover'),
      collection: post.collection,
      date_gmt: post.date_gmt,
      date_gmt_formatted: post.date_gmt_formatted || (post.date_gmt ? moment(post.date_gmt).format('DD.MM.YYYY HH:mm') : null),
      excerpt: post.excerpt,
      external: !!(post.external || false),
      genre: post.genre,
      host: post.host,
      host_uid: post.host_uid,
      hostLabel: post.hostLabel,
      local: !!(post.local || false),
      modified_gmt: post.modified_gmt,
      modified_gmt_formatted: post.modified_gmt_formatted || (post.modified_gmt ? moment(post.modified_gmt).format('DD.MM.YYYY HH:mm') : null),
      name: (post.name || post.post_title ? this.tools.decodeEntities(post.name || post.post_title) : null),
      parsed: true,
      post_content: post.post_content,
      price: post.price,
      rating: post.rating,
      reactions: post.reactions,
      region: post.region,
      relatedArticles: post.relatedArticles,
      sponsored: !!post.sponsored,
      timestamp: post.timestamp,
      timestamp_formatted: (post.timestamp_formatted || moment(post.modified_gmt || post.timestamp, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm')),
      thumbnail: post.thumbnail,
      type: post.type || 'post',
      uid: (post.uid || post.ID),
      url: (post.url || post.permalink),
      user: post.user,
      verified: !!(post.verified || true),
      vip: !!post.vip,
    };
  }

  getFullPosts(posts: any) {

    if(posts && posts.length) {
      
      posts.forEach((post: any, index: number) => {
        post.thumbnail = post.thumbnail || post.image;

        if(post && post.type) {
          switch(post.type) {
            case 'post':
              posts[index] = this.getFullPost(post);
              break;
          }
        }
      });
  
      posts = posts.filter((post: post) => {
        return post && post.thumbnail && post.thumbnail.length;
      });
    }

    return posts;
  }

  getFullRelatedArticles(relatedArticles: number[] | post[]) {
    return new Promise((resolve, reject) => {
      let iDone = 0;

      if (relatedArticles.length > 10 && (typeof relatedArticles[0] === 'number')) {
        relatedArticles = relatedArticles.slice(0, 10);
      }

      relatedArticles.forEach(
        (relatedArticle: number | post, index: number) => {
          if (typeof relatedArticle === "number") {
            setTimeout(() => {
              this.getPostByUid(relatedArticle)
                .then((post: post) => {
                  iDone++;
                  relatedArticles[index] = post;
                  if (iDone === relatedArticles.length) {
                    resolve(relatedArticles);
                  }
                })
                .catch((error) => {
                  iDone++;
                  console.warn("error", error);
                  if (iDone === relatedArticles.length) {
                    resolve(relatedArticles);
                  }
                });
            }, index * 1000);
          } else {
            iDone++;
            relatedArticles[index] = this.getFullPost(relatedArticle);
            if (iDone === relatedArticles.length) {
              resolve(relatedArticles);
            }
          }
        }
      );
    });
  }

  getPostByUid(postId: number, options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let key = "pipeline_fullPost_" + postId + "_" + JSON.stringify(options),
        fromCache: cacheItem = await this.cache.get(key, 60 * 60 * 24);

      if (!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData("pipeline", options, ["posts", postId])
          .then((post: post) => {
            post = this.getFullPost(post);
            this.cachePost(post, options);
            resolve(post);
          })
          .catch(reject);
      }
    });
  }

  getPostBySlug(slug: string) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        "pipeline",
        {
          filter: {
            url: slug,
          },
          like: true,
        },
        ["posts"]
      )
        .then((posts: post[]) => {
          resolve(posts ? posts[0] || null : null);
        })
        .catch(reject);
    });
  }

  getPostInfo(postId: number, options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['posts', postId, 'info'],
      {},
      blForceRefresh
    );
  }

  getPosts(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      let user = this.userService.getUser() || {},
        language = (user.classifications || {}).language || "de";

      options.language = options.language || language;

      this.AppCMS.loadPluginData("pipeline", options, ["posts"], null, blForceRefresh)
        .then((posts: post[]) => {
          if(posts && posts.length) {
            posts.forEach((post: post) => {
              post = this.getFullPost(post);
            });
          }
          resolve(posts);
        })
        .catch(reject);
    });
  }

  getPostsByCategory(categoryId: string | number, options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let key =
          "pipeline_postsByCategory_" +
          categoryId +
          "_" +
          JSON.stringify(options),
        fromCache: cacheItem = await this.cache.get(key, 30 * 60),
        user = this.userService.getUser() || {},
        language = (user.classifications || {}).language || "de";

      options.language = options.language || language;
      options.limit = options.limit || 25;

      if(this.config.isWhitelabel() && !!this.config.getContentHostUrl()) {
        options.filter = options.filter || {};
        options.filter.host = this.config.getContentHostUrl();
        options.like = this.config.getContentHostUrlLike();
      }

      if (!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else
      if (typeof categoryId === "string") {
        this.getPosts(options).then(resolve).catch(reject);
      } else {
        this.AppCMS.loadPluginData("pipeline", options, [
          "posts",
          "category",
          categoryId,
        ])
          .then((posts: any) => {
            if (posts) {
              posts.forEach((post: any, index: number) => {
                posts[index] = this.getFullPost(post);
              });
              this.cache.set(key, posts);
              resolve(posts);
            } else {
              reject();
            }
          })
          .catch(reject);
      }
    });
  }

  getPostsByHost(url: string, blForceRefresh: boolean = false) {
    return this.getPosts({
      filter: {
        host: url,
      }
    }, blForceRefresh);
  }

  getPostsBySourceUid(sourceId: number, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.sources.getSourceByUid(sourceId)
      .then((source: source) => {
        this.getPostsByHost(source.url, blForceRefresh).then(resolve).catch(reject);
      })
      .catch(reject);
    });
  }

  getPostsSuggestions() {
    return this.getPosts({
      limit: 10,
    });
  }

  hidePostByUid(postId: number, blHide: boolean) {
    //console.log('> hide post by uid', postId, blHide);
  }
  
  async initOrderBy() {
    let setting: string|null = await this.getCachedOrderByState();

    if(!!setting) {
      this.orderBy(setting);
    }
  }

  orderBy(orderBy: string|null = null) {

    if(orderBy !== null) {
      this.AppCMS.setRequestParam('orderBy', (orderBy === 'timestamp' ? 'timestamp' : 'auto')); // add support for more filter types
      this.cache.set('_orderBy', orderBy);
      return this;
    }

    return this.getCachedOrderByState();
  }

  rejectPost(postId: number) {
    this.cache.remove("blog_public");
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        uid: postId,
      },
      ["posts", "reject"]
    );
  }

  updatePost(post: post) {
    return this.AppCMS.loadPluginData('pipeline', {
      post: post,
      user: this.userService.getUid()
    }, ['posts', post.uid, 'update']);
  }

}
