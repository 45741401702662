import { Injectable } from "@angular/core";

import { ToolsService } from './tools.service';

declare var applewatch: any;

@Injectable({
  providedIn: "root",
})
export class AppleWatchService {
  
  appGroupId: any;

  constructor(
    private tools: ToolsService,
  ) {
  }

  success: boolean = false;

  init(groupId: string) {
    try {
      if(!this.tools.isDesktop()) {
        applewatch.init(
          (appGroupId: any) => {
            this.appGroupId = appGroupId;
            this.success = true;
          },
          (err: any) => {
            console.warn("Apple Watch init failed: " + err);
            this.success = false;
          },
          groupId
        );
      }
    } catch(e) {
      console.warn("apple watch: init error", e);
    }
  }

  sendMessage(message: string, queueName: string) {
    try {
      if (this.success) {
        applewatch.sendMessage(message, queueName);
        //console.log("Apple Watch message send.");
      } else {
        //console.log("Apple Watch not initialized: message not send.");
      }
    } catch (e) {
      console.warn("apple watch: sendMessage error", e);
    }
  }

  addListener(queueName: string) {
    try {
      if (this.success) {
        //console.log("Apple Watch listener added.");
        applewatch.addListener(queueName, (message: string) => {
          //console.log("Message received:: " + message);
          alert("Message received: " + message);
        });
      } else {
        //console.log("Apple Watch not initialized: listener not added.");
      }
    } catch (e) {
      console.warn("apple watch: addListener error", e);
    }
  }

}
