import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class ShortcutsService {

  iosShortcutList: any = [];

  constructor(
    private platform: Platform,
    //private siriShortcuts: SiriShortcuts,
    private translation: TranslationService,
  ) {

  }

  init() {
    /*
    this.platform.ready().then(() => {
      setTimeout(() => {
        this.initShortcutLists()
        .catch((error: any) => {
          console.warn('shortcuts error', error);
        });
      }, 2000);
    });
    */
  }

  /*
  async initIOSShortcutList() {
    return new Promise((resolve, reject) => {

      let shortcutKeys = [
        "shortcut_open_pipeline",
        "shortcut_read_article",
        "shortcut_pipeline_stats",
        "shortcut_whats_new_on_pipeline",
      ];

      this.translation.get(shortcutKeys)
      .subscribe((translations: any) => {
        let list = [];
        shortcutKeys.forEach((key: string) => {
          let item = {
            persistentIdentifier: key.replace('shortcut_', ''),
            title: translations[key],
            suggestedInvocationPhrase: translations[key],
            userInfo: {},
            isEligibleForSearch: true,
            isEligibleForPrediction: true,
          };
          list.push(item);
          this.siriShortcuts.donate(item);
        });
        this.iosShortcutList = list;
        resolve(this.iosShortcutList);
      });
    });
  }

  initShortcutLists() {
    return Promise.all([  
      this.initIOSShortcutList(),
    ]);
  }
  */

}
