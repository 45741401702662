<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'choose_cover'|translate"></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="update()" icon-only>
        <ion-icon name="checkmark-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="view.covers">
    <ion-row>
      <ion-col size="6" *ngFor="let cover of view.covers" (click)="use(cover)" [class.active]="view.cover && (view.cover.src == cover.src)">
        <img [src]="cover.src" />
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
