<ion-header class="ion-no-border" translucent="true">
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>
    </ion-buttons>
    <ion-title [innerHTML]="'payment_method'|translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="container">
    <ion-card class="paymentsCard" color="white"
      *ngIf="!deliveryOptions.repeat || (deliveryOptions.repeat && tmpOrder.interval && (tmpOrder.time || tmpOrder.datetime))">
      
      <ion-card-header>
        <ion-card-title [innerHTML]="'choose_your_payment_method'|translate"></ion-card-title>
      </ion-card-header>

      <ion-card-content color="white" class="payments">

        <ion-button size="block" *ngFor="let paymentMethod of view.paymentMethods" [fill]="paymentOptions.paymentMethod == (paymentMethod.uid) ? 'solid' : 'clear'" [color]="paymentOptions.paymentMethod == (paymentMethod.uid) ? 'primary' : ''"
          (click)="use(paymentMethod.uid)">
          <ion-icon [name]="paymentMethod.icon || 'extension-puzzle-outline'" slot="start"></ion-icon>
          <ion-label [innerHTML]="paymentMethod.name|translate"></ion-label>
        </ion-button>

        <ion-button size="block" *ngIf="config.useWallet" [fill]="paymentOptions.paymentMethod == 'wallet' ? 'solid' : 'clear'" [color]="paymentOptions.paymentMethod == ('wallet') ? 'primary' : ''"
          (click)="use('wallet')">
          <ion-icon name="wallet-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'pay_with_wallet'|translate"></ion-label>
        </ion-button>

        <ion-button size="block" *ngIf="applePayAvailable" [fill]="paymentOptions.paymentMethod == 'apple_pay' ? 'solid' : 'clear'" [color]="paymentOptions.paymentMethod == ('apple_pay') ? 'primary' : ''"
          (click)="use('apple_pay')">
          <ion-icon name="logo-apple" slot="start"></ion-icon>
          <ion-label [innerHTML]="'apple_pay'|translate"></ion-label>
        </ion-button>

        <ion-button size="block" *ngIf="googlePayAvailable" [fill]="paymentOptions.paymentMethod == 'google_pay' ? 'solid' : 'clear'" [color]="paymentOptions.paymentMethod == ('google_pay') ? 'primary' : ''"
          (click)="use('google_pay')">
          <ion-icon name="logo-apple" slot="start"></ion-icon>
          <ion-label [innerHTML]="'google_pay'|translate"></ion-label>
        </ion-button>

        <p class="or" *ngIf="config.usePayPalExtension && (applePayAvailable || googlePayAvailable || config.useWallet)">
          <span [innerHTML]="'or'|translate"></span>
        </p>

        <div class="ion-padding" *ngIf="config.usePayPalExtension">
          <div id="paypal-button-container"></div>
        </div>
        
      </ion-card-content>

    </ion-card>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container">
    <ion-item lines="none">
      <ion-checkbox [(ngModel)]="view.saveChoice" slot="start"></ion-checkbox>
      <ion-label class="ion-text-wrap" [innerHTML]="'payments_save_choice'|translate"></ion-label>
    </ion-item>
  </ion-toolbar>
  <ion-toolbar class="ion-no-border container">
    <ion-button (click)="next()" [disabled]="!((!deliveryOptions.repeat || (deliveryOptions.repeat && tmpOrder.interval && (tmpOrder.time || tmpOrder.datetime))) && paymentOptions.paymentMethod)" size="block" color="primary">
      <ion-icon name="checkmark" slot="start"></ion-icon>
      <ion-label [innerHTML]="'pay'|translate"></ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>