import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EventsService } from '../../../services/events.service';

import { ReactionsService } from '../../../services/reactions.service';
import { UserService } from '../../../services/user.service';
import { WebService } from '../../../services/web.service';

@Component({
  selector: 'pipeline-reactions-toolbar',
  templateUrl: './reactions-toolbar.component.html',
  styleUrls: ['./reactions-toolbar.component.scss'],
})
export class ReactionsToolbarComponent implements OnInit {
  @Input() post: any;
  @Input() type: string;

  view: any = {};

  user: user;

  constructor(
    private events: EventsService,
    private reactions: ReactionsService,
    public userService: UserService,
    private _sanitizer: DomSanitizer,
    private webService: WebService,
  ) {
    
  }

  detectChanges() {

  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};
    
    if(!!(this.user && this.user.uid)) {
      this.view.emojis = this.reactions.getEmojis();
    }
  }

  react(emoji: emoji) {

    if(!this.userService.getUid()) {
      return this.webService.appFeaturesRequested();
    }

    this.events.publish('reactions:stage:render', {
      emoji: emoji.emoji,
    });

    setTimeout(() => {
      this.view.reaction = null;
      this.detectChanges();
    }, 3 * 1000);

    // submit reaction to server
    this.reactions.reactOnPost({
      emoji: emoji.name,
      item: this.post.uid,
      type: 'post',
    })
    .then((response: any) => {
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });

  }

}
