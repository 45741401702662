import { Injectable } from '@angular/core';

import { textVide } from 'text-vide';
import { CacheService } from './cache.service';

import { EventsService } from './events.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root'
})
export class BionicReadingService {

  state: boolean = false;

  constructor(
    private cache: CacheService,
    private events: EventsService,
    private tools: ToolsService,
  ) {

  }

  convert(input: string) {
    return new Promise((resolve, reject) => {
      resolve({
        success: true,
        output: textVide(this.tools.stripHtml(input)),
      });
    });
  }

  async isEnabled() {
    let fromCache: cacheItem = (await this.cache.get('bionicReading_isEnabled', -1));
    return (fromCache && fromCache.data ? !!fromCache.data : false);
  }

  toggle(bl: boolean = false) {
    this.state = !!bl;
    
    this.cache.set('bionicReading_isEnabled', this.state);
    this.events.publish('bionic-reading:toggled', this.state);
  }

}
