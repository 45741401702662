import { Injectable } from '@angular/core';

import { ChangeAccountPage } from '../pages/account/change-account/change-account.page';

import { CacheService } from './cache.service';
import { ConfigService } from './config.service';
import { ModalService } from './modal.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  
  _multiMode: boolean = false;

  key: 'pipeline_accounts';

  constructor(
    private cache: CacheService,
    private config: ConfigService,
    private modalService: ModalService,
    public userService: UserService,
  ) {

  }

  async add(account: user) {
    let accounts: user[] = (await this.getAll() as user[]);
    let blExists = false;

    accounts.forEach((_account: user) => {
      if(_account.uid === account.uid) {
        blExists = true;
      }
    });

    if(!blExists) {
      accounts.push(account);
    }

    return this.setAccounts(accounts);
  }

  getAll(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let fromCache: cacheItem = await this.cache.get(this.key, -1);
      let accounts: user[] = [];
      let user = this.userService.getUser();

      if(blForceRefresh || (!fromCache || !fromCache.data)) {
        accounts.push(user);
      } else {
        accounts = fromCache.data;
      }

      resolve(accounts);
    });
  }

  isMultiMode() {
    return !!this._multiMode;
  }

  logout(user: user = null) {
    return new Promise((resolve, reject) => {
      this.getAll()
      .then((accounts: user[]) => {

        if(accounts && accounts.length) {
          accounts = accounts.filter((account: user) => {
            return account.uid !== user.uid;
          });
        }

        this.setAccounts(accounts);

        if(accounts && accounts[0] && accounts[0].uid) {
          console.log('> accounts[0]', accounts[0]);
          this.userService.setUser(accounts[0]);
          resolve(accounts);
        } else {
          this.userService.logout().then(resolve).catch(reject);
        }

      })
      .catch(reject);
    });
  }

  setAccounts(accounts: user[]) {
    this.cache.set(this.key, accounts);
    return accounts;
  }

  setMultiMode(bl: boolean = false) {
    this._multiMode = !!bl;
  }

  switch() {
    return new Promise(async (resolve, reject) => {

      if(!this.config.allowUserLogin()) {
        reject('error_login_not_allowed');
        return false;
      }

      this.modalService.setIsModal(true);

      let changeAccountModal = await this.modalService.create({
        component: ChangeAccountPage,
        componentProps: {
          accountsService: this,
          modalService: this.modalService,
        },
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: 'changeAccountModal'
      });
  
      changeAccountModal.onWillDismiss().then((response: any) => {
        this.modalService.setIsModal(false);

        resolve(response);
      });
  
      changeAccountModal.present();
    });
  }

}
