<div class="vipCard">
  <ion-card-header>
    <ion-card-title [innerHTML]="'vip_card_headline'|translate"></ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <p>
      <span [innerHTML]="'vip_card_text_a'|translate"></span>
      <br />
      <span [innerHTML]="'vip_card_text_b'|translate"></span>
    </p>
    <p [innerHTML]="'vip_card_text_c'|translate"></p>
    <ion-button size="block" (click)="purchase(post)" color="dark">
      <ion-icon name="ticket-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'vip_card_button_label'|translate"></ion-label>
    </ion-button>
    <ion-button size="block" (click)="promptBetaTest()" fill="outline" color="dark">
      <ion-icon name="git-branch-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'prompt_beta_test_submitted_title_2'|translate"></ion-label>
    </ion-button>
    <ion-button size="block" (click)="changeAbonnement()" fill="outline" color="dark">
      <ion-icon name="card-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'change_abonnement'|translate"></ion-label>
    </ion-button>
  </ion-card-content>
</div>