import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';
import { CollectionsService } from './collections.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  key: string = 'collection_item';
  
  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private collections: CollectionsService,
    public userService: UserService,
  ) {

  }

  addToCollection(item: any, type: string) {
    return new Promise((resolve, reject) => {
      if(!this.userService.isLoggedIn()) {
        reject('error_missing_user_uid'); 
      } else {
        this.collections.showAddDialog(item, type).then(resolve).catch(reject);
      }
    });
  }

  getCollections(profileId: number = null, blForceRefresh: boolean = false) {
    return this.collections.getByProfileUid(profileId, blForceRefresh);
  }

  get(type: string) {
    return this.collections.get(type);
  }

  getProfile(profileId: number, blForceRefresh: boolean = false, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      let key = 'profile_' + profileId + '_' + JSON.stringify(options),
          fromCache: cacheItem = await this.cache.get(key, 10 * 60);
      
      if(!blForceRefresh && (fromCache && fromCache.data)) {
        let cached = fromCache.data;
        cached.collections = this.collections.parseCollections(cached.collectionData);
        resolve(cached);
      } else {
        this.AppCMS.loadPluginData('pipeline', Object.assign({
          user: this.userService.getUid(),
        }, options), ['profile', profileId])
        .then((profileData: profileData) => {
          
          if(profileData.collectionData && profileData.collectionData.custom) {
            profileData.collectionData.custom = {
              items: (profileData.collectionData.custom as any),
            }
          }

          profileData.collections = this.collections.parseCollections(profileData.collectionData);
          this.cache.set(key, profileData);
          resolve(profileData);
        })
        .catch(reject);
      }
    });
  }

  inCollection(item: any) {
    return this.collections.inCollection(item);
  }

  parseCollections(collections: any) {
    return this.collections.parseCollections(collections);
  }
  
  removeFromCollection(item: any, collection: string) {
    return this.collections.removeFromCollection(item, collection);
  }
  
}
