import { Component, OnInit, Input } from '@angular/core';
import { AccountsService } from '../../../services/accounts.service';

import { CommentsService } from '../../../services/comments.service';
import { ConfigService } from '../../../services/config.service';
import { EventsService } from '../../../services/events.service';
import { ReactionsService } from '../../../services/reactions.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'pipeline-post-comments',
  templateUrl: './post-comments.component.html',
  styleUrls: ['./post-comments.component.scss'],
})
export class PostCommentsComponent implements OnInit {
  @Input() config: postCommentsConfig = {};
  @Input() post: post;

  appConfig: pipelineAppConfig;

  user: user;

  view: any = {
    comments: [],
    input: '',
    placeholders: [{},{},{},{},{},{},{},{}],
  };

  constructor(
    private accounts: AccountsService,
    private commentsService: CommentsService,
    private configService: ConfigService,
    private events: EventsService,
    private reactions: ReactionsService,
    private userService: UserService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  deleteComment(comment: postComment) {
    this.commentsService.deleteComment(comment.uid)
    .then(() => {
      this.doRefresh();
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    })
  }

  doRefresh(event: any = null) {
    this.loadComments(true)
    .then(() => {
      if(event) {
        event.target.complete();
      }
    })
    .catch((error: any) => {
      if(event) {
        event.target.complete();
      }
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  loadComments(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.comments = JSON.parse(JSON.stringify(this.view.placeholders));

      this.commentsService.getByPost(this.post.uid, blForceRefresh)
      .then((comments: postComment[]) => {
        this.view.comments = comments;
        resolve(comments);
      })
      .catch((error: any) => {
        this.view.comments = [];
        reject(error);
      });
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};

    this.loadComments()
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  onReactionsSegmentChanged() {
    
  }

  performReact(itemId: number, emoji: emoji) {

    this.events.publish('reactions:stage:render', {
      emoji: emoji.emoji,
    });

    setTimeout(() => {
      this.view.reactionsSegment = null;
    }, 2 * 1000);

    // submit reaction to server
    this.reactions.reactOnPost({
      emoji: emoji.name,
      item: itemId,
      type: 'comment',
    })
    .then((response: any) => {
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  react(itemId: number, emoji: emoji) {
    if(!this.userService.isLoggedIn()) {
      this.accounts.switch()
      .then(() => {
        if(this.userService.isLoggedIn()) {
          this.performReact(itemId, emoji);
        }
      });
      return false;
    } else {
      this.performReact(itemId, emoji);
    }
  }

  replyToComment(comment: postComment) {
    
  }

  submitComment() {

    let comment = {
      description: this.view.input,
      item: this.post.uid,
      user: this.userService.getUid(),
    };

    this.commentsService.submit(comment)
    .then(() => {
      this.view.input = '';
      this.doRefresh();
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  viewProfile(profile: user|number) {
    this.events.publish('view:profile', profile);
  }

}
