import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

import { BrowserService } from '../../../services/browser.service';
import { EventsService } from '../../../services/events.service';
import { PipelineService } from '../../../services/pipeline.service';
import { RatingsService } from '../../../services/ratings.service';
import { UserService } from '../../../services/user.service';
import { PostsService } from '../../../services/posts.service';
import { ReportService } from '../../../services/report.service';
import { ConfigService } from '../../../services/config.service';

import { PostInformationPage } from '../../post/post-information/post-information.page';

@Component({
  selector: 'app-card-options',
  templateUrl: './card-options.page.html',
  styleUrls: ['./card-options.page.scss'],
})
export class CardOptionsPage implements OnInit {

  col: col|post;

  config: pipelineAppConfig;

  event: any;

  user: user;

  view: any = {

  };

  constructor(
    private browser: BrowserService,
    private configService: ConfigService,
    private events: EventsService,
    private navParams: NavParams,
    private pipeline: PipelineService,
    private popoverCtrl: PopoverController,
    private posts: PostsService,
    private ratings: RatingsService,
    private reportService: ReportService,
    public userService: UserService,
  ) {
    this.config = this.configService.getConfig();
    
    this.col = this.navParams.get('col');
    this.event = this.navParams.get('event');

    this.user = this.userService.getUser() || {};
  }

  delete(event: any = null) {
    this.posts.deletePost(this.col.uid)
    .then(() => {
      this.popoverCtrl.dismiss();

      this.events.publish('feed:refresh');
      this.events.publish('home:refresh');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  async information(event: any = null) {
    this.popoverCtrl.dismiss();
    let informationPopup = await this.popoverCtrl.create({
      animated: true,
      component: PostInformationPage,
      componentProps: {
        post: this.col,
      },
      cssClass: 'postInformationPopover',
      event: this.event,
    });
    informationPopup.present();
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
  }

  ngOnInit() {
    let isOwnPost: boolean = (this.col && !!this.col.host_uid && this.userService.isLoggedIn() && (this.col.host_uid === this.userService.getUid()));
    
    this.view.isAdmin = this.userService.isType('Admin');
    this.view.isModerator = !!(this.userService.isType('Admin') || this.userService.isType('Redaktion') || this.userService.isType('Moderator'));
    this.view.isOwnPost = isOwnPost;
  }

  rate(event: any = null) {
    this.popoverCtrl.dismiss();
    this.ratings.rate(this.col as post)
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  report(event: any = null) {
    this.reportService.create({
      item: this.col,
    })
    .then(() => {

    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  move(event: any = null) {
    this.popoverCtrl.dismiss();
    this.pipeline.move(this.col.uid)
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  openExternal(event: any = null) {
    this.popoverCtrl.dismiss();
    this.browser.create(this.col.url);
  }

  openProfile(event: any = null) {
    if(!!this.col.host_uid) {
      this.events.publish('view:profile', this.col.host_uid);
    }
    this.popoverCtrl.dismiss();
  }

}
