import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { EventsService } from '../../../services/events.service';
import { ModalService } from '../../../services/modal.service';
import { SurveysService } from '../../../services/surveys.service';

import SwiperCore, { Autoplay, Controller, Keyboard, Navigation, Pagination, Scrollbar, Virtual, Zoom } from 'swiper';
SwiperCore.use([Autoplay, Controller, Keyboard, Navigation, Pagination, Scrollbar, Virtual, Zoom]);

@Component({
  selector: 'app-survey',
  templateUrl: './survey.page.html',
  styleUrls: ['./survey.page.scss'],
})
export class SurveyPage implements OnInit {

  private swiper: any;

  survey: survey;

  surveySliderOptions: any = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    slidesPerGroup: 1,
    sliderPerView: 1,
    zoom: false,
    virtual: true,
    allowTouchMove: false,
  };

  view: any = {
    canBack: false,
    canNext: false,
    canSubmit: false,
    options: {},
    podcasts: [],
    placeholders: [{},{},{},{},{},{}],
  };
  
  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private navParams: NavParams,
    private surveys: SurveysService,
  ) {
    this.survey = this.navParams.get('survey');
    console.log('> survey', this.survey);
  }

  dismiss() {
    this.modalService.dismiss();
  }

  doRefresh(event: any = null) {
    this.loadSurvey(true)
    .then(() => {
      if(event) {
        event.target.complete();
      }
    })
    .catch((error: any) => {
      this.events.publish('error', error);

      if(event) {
        event.target.complete();
      }
    });
  }

  loadSurvey(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if(!this.survey || !this.survey.uid) {
        reject('error_missing_survey_uid');
      } else {
        this.surveys.getByUid(this.survey.uid, blForceRefresh)
        .then((survey: survey) => {
          this.survey = survey;
          resolve(this.survey);
        })
        .catch(reject);
      }
    });
  }

  next() {
    this.swiper.slideNext();
    this.validateUI();
  }

  ionViewWillEnter() {
    this.validateUI();
  }

  ngOnInit() {
    this.updateSlider();
  }

  prev() {
    this.swiper.slidePrev();
    this.validateUI();
  }
  
  setSwiperInstance(event: any) {
    this.swiper = event;
    this.updateSlider();
  }

  submit() {
    this.surveys.submit(this.survey)
    .then(() => {
      this.events.publish('toast', {
        color: 'primary',
        message: 'survey_submit_success_toast_message',
      })
      this.dismiss();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  updateSlider() {
    try {
      if(this.swiper) {
        this.swiper.update();
      }
    } catch(e) {
      console.warn('e', e);
    }
  }

  validateUI() {
    let blValid: boolean = true;

    if(!this.survey || !this.survey.slides) {
      return false;
    }

    this.view.canBack = (this.swiper.activeIndex > 0);
    this.view.hasIntro = (!!this.survey.description);

    let lookupIndex: number = (!!this.view.hasIntro ? this.swiper.activeIndex-1 : this.swiper.activeIndex);

    if(!!this.view.hasIntro && (this.swiper.activeIndex === 0)) {
      this.view.canBack = false;
      this.view.canNext = true;
      this.view.canSubmit = false;
      this.view.valid = true;

      return true;
    } else {
      let slide: surveySlide = this.survey.slides[lookupIndex];

      if(!slide || !slide.questions || !slide.questions.length) {
        return false;
      }
  
      slide.questions.forEach((question: surveyQuestion) => {
        blValid = (!!blValid && (!!question.answer || !!question.checked));
      });
    }

    this.view.canNext = (blValid && !!this.survey.slides[lookupIndex+1]);
    this.view.canSubmit = (blValid && !this.survey.slides[lookupIndex+1]);
    this.view.valid = blValid;
  }

}
