import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { CacheService } from './cache.service';
import { EventsService } from './events.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private _settings: any = {};

  constructor(
    private cache: CacheService,
    private events: EventsService,
    private translations: TranslationService,
    public userService: UserService,
  ) {

  }

  getSetting(key: string, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.getSettings(null, blForceRefresh)
      .then((settings: any) => {
        resolve(settings[key]);
      })
      .catch((error: any) => {
        if(error && error.toLowerCase().indexOf('error') !== -1) {
          reject(error);
        } else {
          resolve(error); // resolve(), because empty setting is okay here
          // @todo validate error
        }
      });
    });
  }

  getSettings(userId: number = null, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      userId = userId || this.userService.getUid();

      if(this._settings[userId] && !blForceRefresh) {
        resolve(this._settings[userId]);
      } else
      if(!userId || (userId === -1)) {
        resolve({});
      } else {
        this.userService.getByUid(userId, blForceRefresh)
        .then((user: user) => {
          this._settings[userId] = user && user.classifications && user.classifications.settings ? user.classifications.settings : {};
          resolve(this._settings[userId]);
        })
        .catch(reject);
      }
    });
  }

  setSettings(settings: settings, userId: number = null) {
    let user = this.userService.getUser();
    userId = userId || user.uid;
    return this.cache.set('user_settings_' + userId, settings);
  }

  async showUpdatedToast() {
    this.translations.get([
      'settings_updated',
    ])
    .subscribe((response: any) => {
      this.events.publish('toast', {
        icon: 'checkmark-outline',
        message: response.settings_updated || 'settings_updated',
        color: 'primary',
      });
    });
  }

  updateSettings(settings: settings) {
    return new Promise((resolve, reject) => {
      let user = this.userService.getUser() || {};
      if(!user.uid) {
        reject('error_missing_user_uid');
      } else
      if(user.uid === -1) {
        user.classifications = user.classifications || {};
        user.classifications.settings = user.classifications.settings || {};
        user.classifications.settings = Object.assign(user.classifications.settings, settings);
        this.userService.setUser(user, false);
        resolve(user);
      } else {
        this.getSettings(user.uid, true)
        .then((_settings: settings) => {
          settings = Object.assign(_settings, settings);
          user.classifications.settings = settings;
          
          this.setSettings(settings)
          .then(() => {
            this.userService.setUser(user, true).then(resolve).catch(reject);
          })
          .catch(reject);
        })
        .catch(reject);
      }
    });
  }

  updateSetting(key: string, value: any) {
    let settings = {};
    settings[key] = value;
    return this.updateSettings(settings);
  }

}
