import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BrowserService } from './browser.service';
import { ConfigService } from './config.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  appConfig: pipelineAppConfig;

  appDownloadUrl: string = "https://get.pipeline.page/";

  constructor(
    private alertCtrl: AlertController,
    private browser: BrowserService,
    public configService: ConfigService,
    private tools: ToolsService,
    private translate: TranslateService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  appFeaturesRequested() {
    this.translate
      .get([
        "app_required_header",
        "app_required_subHeader",
        "app_required_message",
        "okay",
        "cancel",
      ])
      .subscribe(async (response: any) => {
        let alert = await this.alertCtrl.create({
          header: (response.app_required_header || '').replace('pipeline', this.appConfig.appName),
          subHeader: (response.app_required_subHeader || '').replace('pipeline', this.appConfig.appName),
          message: (response.app_required_message || '').replace('pipeline', this.appConfig.appName),
          buttons: [
            {
              text: response.okay || "Okay",
              handler: () => {
                if(this.tools.getOS() === 'ios') {
                  this.browser.create(`${this.appDownloadUrl}/ios`);
                } else
                if(this.tools.getOS() === 'ios') {
                  this.browser.create(`${this.appDownloadUrl}/android`);
                } else {
                  this.browser.create(this.appDownloadUrl);
                }
              },
            },
            {
              text: response.cancel || "Cancel",
              role: "cancel",
            },
          ],
        });
        await alert.present();
      });
  }

}
