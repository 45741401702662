<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title [innerHTML]="view.title|translate"></ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="submitMultiple()" icon-only>
        <ion-icon name="checkmark-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <ion-toolbar class="ion-no-border container">
    <ion-searchbar [(ngModel)]="search.query" [placeholder]="'search'|translate" (ionChange)="runSearch()">
    </ion-searchbar>
  </ion-toolbar>

</ion-header>

<ion-content fullscreen="true">
  <div class="container">

    <ion-radio-group [(ngModel)]="view.item" *ngIf="view.data && view.data.length">
      <div class="cdk-wrapper">
        <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 25"
          [maxBufferPx]="view.itemSize * 25" class="ion-content-scroll-host">

          <div *cdkVirtualFor="let item of view.data" [hidden]="item.hidden" (click)="select(item)">
            <ion-item>
              
              <ion-icon *ngIf="!!item.icon && !item.photo" [name]="item.icon" slot="start"></ion-icon>
              
              <ion-thumbnail *ngIf="!!item.photo" slot="start">
                <ion-img [src]="item.photo" (ionError)="thumbnailLoadingFailed(item)"></ion-img>
              </ion-thumbnail>

              <ion-checkbox [(ngModel)]="item.checked" *ngIf="view.multiple" (ionChange)="onMultiItemSelected(item)"
                slot="start"></ion-checkbox>

              <ion-label class="ion-text-wrap">
                <h3 [innerHTML]="((!!view.labelPrefix ? view.labelPrefix : '') + item[view.labelKey])|translate"></h3>
                <p *ngIf="!!view.subLabelKey" [innerHTML]="((!!view.subLabelPrefix ? view.subLabelPrefix : '') + item[view.subLabelKey])|translate"></p>
              </ion-label>

              <ion-radio [value]="item[view.valueKey]" *ngIf="!view.multiple" slot="end"></ion-radio>

            </ion-item>
          </div>

        </cdk-virtual-scroll-viewport>
      </div>
    </ion-radio-group>

  </div>
</ion-content>

<ion-footer *ngIf="view.multiple">
  <ion-toolbar class="container">
    <ion-button (click)="toggleSelectAll()">
      <ion-label [innerHTML]="'select_all'|translate" [hidden]="view.allSelected"></ion-label>
      <ion-label [innerHTML]="'deselect_all'|translate" [hidden]="!view.allSelected"></ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>