import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pipeline-post-author-card',
  templateUrl: './post-author-card.component.html',
  styleUrls: ['./post-author-card.component.scss'],
})
export class PostAuthorCardComponent implements OnInit {
  @Input() post: post;

  constructor(

  ) {

  }

  ngOnInit() {
    
  }

}
