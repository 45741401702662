import { Injectable } from '@angular/core';

import { AppcmsService } from './appcms.service';

@Injectable({
  providedIn: 'root'
})
export class SimplyLocalService {

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  addCardToCustomer(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('addCardToCustomer', options, blForceRefresh);
  }

  addCardToCustomerBarcode(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('addCardToCustomerBarcode', options, blForceRefresh);
  }

  addCardToCustomerRFID(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('addCardToCustomerRFID', options, blForceRefresh);
  }

  answerFriendRequest(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('answerFriendRequest', options, blForceRefresh);
  }

  changeCustomer(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('changeCustomer', options, blForceRefresh);
  }

  changeCustomerCardInfo(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('changeCustomerCardInfo', options, blForceRefresh);
  }

  execute(action: string, options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.AppCMS.loadPluginData(
      'simplyx',
      options, [action], params, blForceRefresh
    );
  }

  getNews(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('getNews', options, blForceRefresh);
  }

  getOrderItems(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('getOrderItems', options, blForceRefresh);
  }

  getPartners(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('getPartners', options, blForceRefresh);
  }

  getPartnersAll(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('getPartnersAll', options, blForceRefresh);
  }

  login(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('login', options, blForceRefresh);
  }

  logout(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('logout', options, blForceRefresh);
  }

  pay() {
    return new Promise((resolve, reject) => {
      resolve({
        success: true,
      });
    });
  }

}
