import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { UserService } from './user.service';

import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  PERSISTENCE_KEY_DEVICE = "current_device";
  PERSISTENCE_KEY_DEVICES_LIST = "devicesList";

  devicesList: any;

  // for device sharing between pages
  tmp: any;

  constructor(
    private AppCMS: AppcmsService,
    private userProvider: UserService,
  ) {
  }

  // Persistence 

  private getFromStore() : Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(
        localStorage.getItem(this.PERSISTENCE_KEY_DEVICE) ? JSON.parse(localStorage.getItem(this.PERSISTENCE_KEY_DEVICE)) : null
      );
    });
  }
  
  getTmp() {
    return this.tmp;
  }

  // API Methods

  activate(serialNumber: string) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('devices', {
        serialNumber: serialNumber,
      }, ['authorize'])
      .then((json: any) => {
        if(typeof json != 'object'){
          reject("Ein Systemfehler ist aufgetreten! Versuche es später erneut.");
        } else
        if (json.status == false || json.success == false) {
          reject("Die Seriennummer konnte nicht aktiviert werden!");
        } else {
          resolve(json);
        }
      })
      .catch(reject);
    });
  }

  create(device: any = {}) {
    return this.AppCMS.loadPluginData('devices', {
      newDevice: device,
      user: this.userProvider.getApiCredentials(),
    }, ['create']);
  }

  delete(deviceId: number) {
    return this.AppCMS.loadPluginData('devices', {}, [deviceId, 'delete']);
  }

  getByUid(uid: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('devices', {}, [uid])
      .then((json: any) => {
        if(typeof json != 'object'){
          reject("Ein Systemfehler ist aufgetreten! Versuched es später erneut.");
        } else
        if (json.status == false || json.success == false) {
          reject("Keine Geräteinformationen vorhanden!");
        } else {
          resolve(json);
        }
      })
      .catch(reject);
    });
  }

  generateDeviceTitle(user: user, item: any = null) {
    user = user || {};
    
    let deviceTitle = '';
    try {
      let store = user.classifications && user.classifications.stores ? user.classifications.stores[0] : null;
      if(store && store.title) {
        deviceTitle = store.title;
        if(item) {
          deviceTitle += ': QR #' + item.uid;
        } else {
          deviceTitle += ' Fernbedienung';
        }
      } else {
        deviceTitle = user.firstname + ' ' + user.lastname;
        if(item) {
          deviceTitle += ': QR #' + item.uid;
        } else {
          deviceTitle += ' Fernbedienung';
        }
      }
    } catch(e) {
      console.warn('e', e);
      user = user || {};
      deviceTitle = (user.firstname || '') + ' ' + (user.lastname || '');
      if(item) {
        deviceTitle += ': QR #' + item.uid;
      } else {
        deviceTitle += ' Fernbedienung';
      }
    }
    return deviceTitle;
  }

  private isPersistedDeviceValid(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getFromStore().then((storedDevice) => {
        if(!storedDevice || !storedDevice.uid){
          return resolve(false);
        } else {
          this.getByUid(storedDevice.uid)
          .then((apiDevice: any) => {
            return resolve(apiDevice.uid == storedDevice.uid);
          })
          .catch(reject);
        }
      });
    });
  }

  async getDevice() {
    return Device.getInfo();
  }

  async getDeviceName() {
    let device = await this.getDevice();
    return device.name;
  }

  async getDeviceOsVersion() {
    let device = await this.getDevice();
    return device.osVersion;
  }

  async getDeviceUid() {
    return Device.getId();
  }

  private setInStorage (device: any, remove: boolean = false) : Promise<any> {
    return new Promise((resolve, reject) => {
      if(remove){
        localStorage.removeItem(this.PERSISTENCE_KEY_DEVICE);
      } else {
        localStorage.setItem(this.PERSISTENCE_KEY_DEVICE, JSON.stringify(device));
      }
      resolve(this.getFromStore());
    });
  }

  setDevicesList(devicesList: any) {
    return new Promise((resolve, reject) => {
      localStorage.setItem(this.PERSISTENCE_KEY_DEVICES_LIST, JSON.stringify(devicesList));
      resolve(localStorage.getItem(this.PERSISTENCE_KEY_DEVICE));
    });
  }

  setTmp(tmp: any) {
    this.tmp = tmp;
  }
  
}
