import { Injectable } from '@angular/core';

import { FeedService } from './feed.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private feed: FeedService,
  ) {

  }

  getHome(blForceRefresh: boolean = false, params: any = {}) {
    return this.feed.getFeed('home', blForceRefresh, params);
  }
  
}
