import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-choose-cover',
  templateUrl: './choose-cover.page.html',
  styleUrls: ['./choose-cover.page.scss'],
})
export class ChooseCoverPage implements OnInit {

  covers: any;

  view: any = {};

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public userService: UserService,
  ) {
    this.covers = this.navParams.get('coversService');
    this.view.covers = this.covers.getAll();
  }

  dismiss(cover: cover = null) {
    this.modalCtrl.dismiss(cover);
  }

  ngOnInit() {
  }

  use(cover: cover) {
    this.view.cover = cover;
  }

  update() {
    this.dismiss(this.view.cover);
  }

}
