import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';
import { UserService } from './user.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ScrollspyService {

  feedLog: any = {};

  feeds: any = {};

  iMaxFeedPostViewTime: number = 20;

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private user: UserService,
  ) {

  }

  async getFeedLog() {
    let cached: cacheItem = await this.cache.get('feed_view_log', -1);
    return cached && !!cached.data ? (cached.data || {}) : {};
  }
  
  async getFeedPostsToHide() {
    let uids = [];

    try {
      let feedLog = await this.getFeedLog();
      let keys = Object.keys(feedLog);
      
      if(keys && keys.length) {
        keys.forEach((uid: string) => {
          if(feedLog[uid] && (feedLog[uid] > this.iMaxFeedPostViewTime)) {
            uids.push(
              parseInt(uid)
            );
          }
        });
      }
    } catch(e) {
      console.warn('getFeedPostsToHide error', e);
    }
    
    return uids;
  }

  async getLocalLog() {
    let cached: cacheItem = await this.cache.get('scrollspy_localLog', -1);
    return (cached ? cached.data || {} : {}) || {};
  }

  async getPercentageByUid(uid: number) {
    let cached: cacheItem = await this.cache.get('scrollspy_localLog', -1);
    let log = (cached ? cached.data || {} : {}) || {};
    return log[uid];
  }

  initFeed(config: scrollSpyFeedConfig = {}) {
    config.elements = document.querySelectorAll(config.selector);
    this.feeds[config.route] = config;

    this.initFeedLog();

    setTimeout(() => {
      this.updateFeed(config);
    }, 1000);

    return this;
  }

  async initFeedLog() {
    this.feedLog = await this.getFeedLog();
  }

  isInViewPort(element: any) {
    var bounding = element.getBoundingClientRect();
    return !!(
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  log(type: string, data: scrollspyData) {
    return new Promise((resolve, reject) => {
      data.type = type;
      
      if(data.container && data.container.el) {
        data.container =  data.container.el;
      }

      if(data.container && !data.containerHeigth) {
        data.containerHeigth = data.container.offsetHeight;
      }

      if(data.containerHeigth && data.scrollTop) {
        data.percentage = (100 / data.containerHeigth) * data.scrollTop;
        data.percentage = parseInt((data.percentage > 100 ? 100 : data.percentage) + '');
      }

      if(data.uid) {
        this.updateLocalLog(data)
        .then(() => {
          resolve(data);
        })
        .catch(reject);
      } else {
        resolve(data);
      }
    });
  }

  async logPostOpen(post: post, data: scrollspyData) {
    return new Promise((resolve, reject) => {
      resolve({
        data: data,
        post: post,
      });
    });
  }

  async logPostView(post: post, data: scrollspyData) {
    return new Promise((resolve, reject) => {
      resolve({
        data: data,
        post: post,
      });
    });
  }

  async shouldSyncLocalLog(suffix: string = 'log') {
    let shouldSyncEntry: cacheItem = await this.cache.get('scrollspy_shouldSync_' + suffix, (60 * 2)),
        shouldSync = !(shouldSyncEntry.data);
    return !!this.user.getUid() && shouldSync;
  }

  syncFeedLog() {
    return this.syncLog('feed_view_log');
  }

  syncFeedLogIfShould() {
    return new Promise(async (resolve, reject) => {
      let blShould = await this.shouldSyncLocalLog('feed_view_log');
      if(blShould) {
        this.syncFeedLog().then(resolve).catch(reject);
      } else {
        resolve({});
      }
    })
  }

  syncLog(suffix: string = 'log') {
    return new Promise(async (resolve, reject) => {
      let userId: number = this.user.getUid();

      if(!userId || userId == -1) {
        resolve('not_syncing_in_guest_mode');
      } else {

        let params: any = {
          suffix: suffix,
          user: userId,
        };
  
        if(suffix === 'log') {
          params.localLog = await this.getLocalLog();
        } else
        if(suffix === 'feed_view_log') {
          params.feedLog = await this.getFeedLog();
        }
  
        this.cache.set('scrollspy_shouldSync_' + suffix, true)
        .then(() => {
          return this.AppCMS.loadPluginData('pipeline', params, ['log', 'sync']).then(resolve).catch(reject);
        })
        .catch(() => {
          return this.AppCMS.loadPluginData('pipeline', params, ['log', 'sync']).then(resolve).catch(reject);
        });  
      }
    });
  }

  syncLocalLog() {
    return this.syncLog();
  }

  syncLocalLogIfShould() {
    return new Promise(async (resolve, reject) => {
      let blShould = await this.shouldSyncLocalLog();
      if(blShould) {
        this.syncLocalLog().then(resolve).catch(reject);
      } else {
        resolve({});
      }
    })
  }

  updateLocalLog(data: scrollspyData) {
    return new Promise(async (resolve, reject) => {
      if(data && data.uid && data.percentage) {
        let localLog = await this.getLocalLog();
        localLog[data.uid] = localLog[data.uid] ? (data.percentage > localLog[data.uid] ? data.percentage : localLog[data.uid]) : data.percentage;
        this.setLocalLog(localLog).then(resolve).catch(reject);
      } else {
        resolve({});
      }
    });
  }

  setLocalLog(localLog: any) {
    return new Promise((resolve, reject) => {
      this.cache.set('scrollspy_localLog', localLog)
      .then(() => {
        this.syncLocalLogIfShould().then(resolve).catch(reject);
      })
      .catch(reject);
    });
  }

  updateFeed(feedConfig: scrollSpyFeedConfig) {
    feedConfig.iMaxScrollIndex = feedConfig.iMaxScrollIndex || 0;

    if(feedConfig.elements) {
      feedConfig.elementStates = feedConfig.elementStates || {};
    
      feedConfig.elements.forEach((element: any, index: number) => {

        let bl = this.isInViewPort(element),
            now = moment().unix(),
            uid = feedConfig.items && feedConfig.items[index] && feedConfig.items[index].uid ? feedConfig.items[index].uid : null,
            start = (feedConfig.elementStates[index] && feedConfig.elementStates[index].start ? feedConfig.elementStates[index].start : null),
            stop = (feedConfig.elementStates[index] && feedConfig.elementStates[index].start ? feedConfig.elementStates[index].stop : null),
            total_time = (feedConfig.elementStates[index] && feedConfig.elementStates[index].start ? feedConfig.elementStates[index].total_time : 0);
        
        if(!!bl && !start) {
          start = now;
          if(index >= feedConfig.iMaxScrollIndex) {
            feedConfig.iMaxScrollIndex = index;
          }
        } else
        if(!bl && !!start && !stop) {
          stop = now;
          total_time = (stop - start);
        }

        feedConfig.elementStates[index] = {
          start: start,
          stop: stop,
          total_time: total_time,
          uid: uid,
        };

        this.updateFeedLogElementState(feedConfig.elementStates[index]);
      });

      this.updateFeedLog();
    }

  }

  async updateFeedLog() {
    try {
      this.cache.set('feed_view_log', this.feedLog)
      .then(() => {
        this.syncFeedLogIfShould()
        .catch((e) => {
          console.warn('> feed view sync error', e);
        });
      })
      .catch((e) => {
        console.warn('> feed view store error', e);
      });
    } catch(e) {
      console.warn('> feed view log error', e);
    }
  }

  async updateFeedLogElementState(elementState: any) {
    if(!!elementState.uid) {
      this.feedLog[elementState.uid] = elementState.total_time;
    }
  }

}