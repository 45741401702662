import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BrowserService } from './browser.service';
import { CacheService } from './cache.service';
import { CookiesPage } from '../pages/cookies/cookies.page';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  key: string = 'cookies_accepted';

  moreUrl: string = 'https://pipeline.page/datenschutz/';

  constructor(
    private browser: BrowserService,
    private cache: CacheService,
    private modalCtrl: ModalController,
  ) {
  }

  accept() {
    return this.cache.set(this.key, true);
  }

  async isAccepted() {
    let bl: cacheItem = await this.cache.get(this.key, 60 * 60 * 24);
    return !!(bl && bl.data && !!bl.data);
  }

  more() {
    this.browser.create(this.moreUrl);
  }

  async showCookieBanner() {
    let blAccepted = await this.isAccepted();

    if(!blAccepted) {
      let modal = await this.modalCtrl.create({
        component: CookiesPage,
        componentProps: {
          cookiesService: this,
        },
        animated: true,
        canDismiss: true,
        showBackdrop: false,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: 'cookiesModal',
      });
      modal.present();
    }
  }

}
