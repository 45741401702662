<ion-header color="medium" class="ion-no-border">
  <ion-toolbar color="medium" class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'share_internally'|translate"></ion-title>
  </ion-toolbar>
  <ion-toolbar color="medium" class="container">
    <ion-searchbar [(ngModel)]="search.query" (ionChange)="runSearch()" [placeholder]="'search_friend'|translate">
    </ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content color="medium">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">

    <ion-spinner name="circular" *ngIf="search.query && search.query.length && !search.results"></ion-spinner>

    <ion-list color="medium" [hidden]="search.query && search.query.length">
      <ion-item color="medium" size="block" (click)="postInStory()">
        <ion-icon name="eye-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'post_in_story'|translate"></ion-label>
      </ion-item>
      <ion-item color="medium" size="block" (click)="copyLink()">
        <ion-icon name="link-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'copy_link'|translate"></ion-label>
      </ion-item>
      <ion-item color="medium" size="block" (click)="shareExternally()">
        <ion-icon name="share-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'share_externally'|translate"></ion-label>
      </ion-item>
    </ion-list>

    <swiper [slidesPerView]="sliderOptions.slidesPerView" (swiper)="setSwiperInstance($event)" class="ion-padding"
      id="suggestionsSlider" *ngIf="search.suggestions" [hidden]="search.query && search.query.length">
      <ng-template swiperSlide *ngFor="let friend of search.suggestions; let iSuggestion = index;">
        <div (click)="use(friend)">
          <img [src]="friend.photo || avatarUrl" (error)="search.suggestions[iSuggestion].photo=avatarUrl" />
          <small [innerHTML]="friend.nickname || friend.firstname"></small>
          <ion-skeleton-text *ngIf="!friend.uid" class="image" animated></ion-skeleton-text>
        </div>
      </ng-template>
    </swiper>

    <ion-list color="medium">
      <ion-item color="medium" *ngFor="let friend of search.results; let iFriend = index" (click)="use(friend)">
        <ion-avatar slot="start">
          <img [src]="friend.photo && friend.photo.length ? friend.photo : avatarUrl"
            (error)="friends[iFriend].photo=avatarUrl" />
        </ion-avatar>
        <ion-label>
          <h2 [innerHTML]="friend.firstname + ' ' + friend.lastname"></h2>
          <p [innerHTML]="friend.nickname || friend.email"></p>
        </ion-label>
        <ion-icon slot="end" name="send-outline"></ion-icon>
      </ion-item>
    </ion-list>

  </div>

</ion-content>