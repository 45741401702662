import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'pipeline-home-loading-wrapper',
  templateUrl: './home-loading-wrapper.component.html',
  styleUrls: ['./home-loading-wrapper.component.scss'],
})
export class HomeLoadingWrapperComponent implements OnInit {

  user: user;

  constructor(
    public userService: UserService,
  ) {
    this.user = this.userService.getUser() || {};
  }

  ngOnInit() {
    
  }

}
