import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  eventsStorage: any = {};

  constructor(
  ) {
  }

  init() {
  }

  publish(key: string, data: any = null) {
    this.eventsStorage[key] = this.eventsStorage[key] || new Subject<any>();
    this.eventsStorage[key].next(data);
  }

  subscribe(key: string, callback: any = null): Subject<any> {
    this.eventsStorage[key] = this.eventsStorage[key] || new Subject<any>();
    return this.eventsStorage[key].subscribe((data: any) => {
      if(callback) {
        callback(data);
      }
    });
  }

}
