import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ConfigService } from '../../../services/config.service';

import { EventsService } from '../../../services/events.service';
import { MediaextendService } from '../../../services/mediaextend.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-pick-story',
  templateUrl: './pick-story.page.html',
  styleUrls: ['./pick-story.page.scss'],
})
export class PickStoryPage implements OnInit {

  config: pipelineAppConfig;

  search: searchOptions = {
    query: '',
  };

  stories: any;
  
  view: any = {
    canSubmit: false,
    collection: 'post',
    collectionKeys: [],
    placeholders: [{},{},{},{},{},{},{},{}],
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private media: MediaextendService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public userService: UserService,
  ) {
    this.config = this.configService.getConfig();
    this.stories = this.navParams.get('storiesService');
  }

  dismiss() {
    this.events.publish('camera:stop');
    this.modalCtrl.dismiss();
  }

  loadCollections() {
    let collections = this.navParams.get('collections') || {};

    if(!!this.config.useStoriesCameraExtension && !collections.hasOwnProperty('camera')) {
      collections.camera = {};
      this.view.collection = 'camera';
    }

    if(collections) {
      this.view.collectionKeys = Object.keys(collections).sort();
      
      this.view.collectionKeys.forEach((collectionKey: string) => {

        if(collections[collectionKey] && collections[collectionKey].items) {
          collections[collectionKey] = collections[collectionKey].items;
        }

        if(collectionKey === 'people') {
          collections[collectionKey] = this.userService.parseUsers(collections[collectionKey]);

          if(collections[collectionKey] && collections[collectionKey].length) {
            collections[collectionKey].forEach((collectionItem: any) => {
              if(!!collectionItem.avatar) {
                collectionItem.photo = collectionItem.avatar;
                collectionItem.avatar = this.userService.getAvatarUrl(collectionItem);
              }
            });
          }

        }

      })
      this.view.collections = collections;
    }
  }
  
  loadMedia() {
    this.view.collectionKeys = this.view.collectionKeys || [];

    if(!this.view.collectionKeys.hasOwnProperty('media')) {
      this.view.collectionKeys.push('media');
    }

    this.view.collections = this.view.collections || {};
    this.view.collections.media = JSON.parse(JSON.stringify(this.view.placeholders));

    this.media.getMediaList()
    .then((mediaList: mediaItem[]) => {
      this.view.collections.media = mediaList;
    })
    .catch((error: any) => {
      this.view.collections.media = [];
      this.events.publish('error', error);
    });
  }

  ngOnInit() {
    this.loadCollections();

    if(!!this.config.useMediaExtension) {
      this.loadMedia();
    }
  }

  onColClick(col: col, event: any = null) {
    this.view.col = col;
    this.view.canSubmit = true;
    this.post();
  }

  onCollectionChanged() {
    switch(this.view.collection) {
      case 'camera':
        this.events.publish('camera:show');
        break;
      default:
        this.events.publish('camera:hide');
        break;
    }
  }

  post() {
    this.stories.publish({
      item: this.view.col.uid,
      type: this.view.collection,
    })
    .then((response: any) => {
      this.events.publish('people:refresh');
      this.dismiss();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  runSearch() {
    
  }

}
