import { Injectable } from '@angular/core';

import { AdsService } from './ads.service';
import { AppcmsService } from './appcms.service';
import { BlogService } from './blog.service';
import { CacheService } from './cache.service';
import { ConfigService } from './config.service';
import { EventsService } from './events.service';
import { PostsService } from './posts.service';
import { StoriesService } from './stories.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  feedData: any = {};

  constructor(
    private ads: AdsService,
    private AppCMS: AppcmsService,
    private blog: BlogService,
    private cache: CacheService,
    private config: ConfigService,
    private events: EventsService,
    private posts: PostsService,
    private stories: StoriesService,
    public userService: UserService,
  ) {

  }

  getFeeds(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['feeds'], null, blForceRefresh);
  }

  getFeed(feedName: string, blForceRefresh: boolean = false, params: any = {}) {
    return new Promise(async (resolve, reject) => {
      let user = this.userService.getUser() || {},
        language = (user.classifications || {}).language || "de";

      params = params || {};
      params.language = params.language || language;
      params.limit = params.limit || 50;

      params = Object.assign(
        params,
        {
          location: false,
          logfile: false,
          uid: user.uid,
        }
      );

      params.filter = params.filter || {};
      params.user = params.user || this.userService.getApiCredentials();

      if (this.config.filterFeedPostsByContentHostUrl(feedName)) {
        params.filter.host = this.config.getContentHostUrl();
        params.like = this.config.getContentHostUrlLike();
        params.orderBy = params.orderBy || this.config.getOrderBy(feedName);
      }

      params.includeRatings = !!this.config.shouldIncludeRatingsInFeed();
      params.includeReactions = !!this.config.shouldIncludeReactionsInFeed();
      params.includeRelatedArticles = !!this.config.shouldIncludeRelatedArticlesInFeed();

      let key = `pipeline_${feedName}_${JSON.stringify(params)}`,
        fromCache: cacheItem = await this.cache.get(key, 60 * 60);
        
      if (!blForceRefresh && (fromCache && fromCache.data)) {
        this.setFeedData(feedName, fromCache.data, key);
        resolve(fromCache.data);
      } else if (!blForceRefresh && this.feedData[key]) {
        resolve(this.feedData[key]);
      } else {
        this.AppCMS.loadPluginData(
          "pipeline",
          params,
          ['feeds', feedName]
        )
          .then(async (feedData: feedData) => {
            new Promise((resolve, reject) => {
              if (feedData.posts && feedData.posts.length && feedData && feedData.ads && feedData.ads.length) {
                this.ads.addAdsToPosts(feedData.ads, feedData.posts)
                  .then((posts: post[]) => {
                    feedData.posts = posts;
                    resolve(feedData);
                  })
                  .catch((error: any) => {
                    console.warn('add ads to posts error', error);
                    resolve(feedData);
                  });
              } else {
                resolve(feedData);
              }
            })
              .then((feedData: feedData) => {
                
                if(feedData.posts && feedData.posts.length) {
                  feedData.posts.forEach((post: post, index: number) => {
  
                    if(post && !!post.type) {
                      switch(post.type) {
                        case 'feed_post':
                          post = this.posts.getFullPost(post);
                          post.excerpt = post.excerpt || post.post_content;

                          feedData.posts[index] = post;
      
                          setTimeout(() => {
                            this.posts.cachePost(post, {});
                          }, (100 * index));

                          break;
                        case 'post':
                          post = this.posts.getFullPost(post);
                          feedData.posts[index] = post;
      
                          setTimeout(() => {
                            this.posts.cachePost(post, {});
                          }, (100 * index));

                          break;
                      }
                    }
  
                  });
                }

                if (feedData.categories) {
                  feedData.categories = this.blog.parseCategories(
                    feedData.categories
                  );
                }

                if(feedData.stories) {
                  feedData.stories = this.stories.parseStories(
                    feedData.stories
                  );
                }

                this.cache.set(key, feedData)
                  .then(() => {
                    this.setFeedData(feedName, feedData, key);
                    resolve(feedData);
                  })
                  .catch((error: any) => {
                    console.warn('> store feed data failed', error);

                    this.setFeedData(feedName, feedData, key);
                    resolve(feedData);
                  });

              })
              .catch(reject);
          })
          .catch(reject);
      }
    });
  }

  setFeedData(feedName: string, data: feedData, key: string = 'default') {
    this.feedData[key] = JSON.parse(JSON.stringify(data));

    data = this.setFeedDataPosts(feedName, this.feedData[key]);

    // cache people
    if (data.people && data.people.length) {
      data.people.forEach((person: user) => {
        this.cache.set('user_' + person.uid, person);
      });
    }

    this.events.publish(`${feedName}:data:updated`, data);
  }

  setFeedDataPosts(feedName: string, data: feedData) {

    // cache posts
    if (data && data.posts && data.posts.length) {
      data.posts.forEach((post: post, index: number) => {
        post = this.posts.getFullPost(post);
        data.posts[index] = post;

        setTimeout(() => {
          this.posts.cachePost(post, {});
        }, (100 * index));
      });
    } else {
      let blogKeys = ["blog", "external", "regional"];
      blogKeys.forEach((blogKey: string) => {
        if (data.posts && data.posts[blogKey]) {
          data.posts[blogKey].forEach((post: post, index: number) => {
            post = this.posts.getFullPost(post);
            data.posts[blogKey][index] = post;

            setTimeout(() => {
              this.posts.cachePost(post, {});
            }, (100 * index));

          });
        }
      });
    }
    return data;
  }

}
