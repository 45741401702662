import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { BasketService } from '../../../services/basket.service';
import { EventsService } from '../../../services/events.service';
import { ModalService } from '../../../services/modal.service';
import { ConfigService } from '../../../services/config.service';
import { WalletService } from '../../../services/wallet.service';

import { ConfigureProductPage } from '../configure-product/configure-product.page';
import { CouponPage } from '../../coupons/coupon/coupon.page';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.page.html',
  styleUrls: ['./basket.page.scss'],
})
export class BasketPage implements OnInit {

  basketInfo: basketInfo = {
    hasRequestMinimumValue: false,
  };

  basketStorage: any;

  config: pipelineAppConfig;

  couponModal: any;

  upSellingConfig: any = {};

  view: any = {};

  constructor(
    private basket: BasketService,
    private configService: ConfigService,
    private events: EventsService,
    private navCtrl: NavController,
    private modalCtrl: ModalService,
    private wallet: WalletService,
  ) {
    this.config = this.configService.getConfig();
  }

  async add(item: basketItem, index: number) {
    if (!item.variations || !item.variations.length) {
      item.amount = parseFloat((item.amount || 1) + '');
      item.amount++;
      this.basket.updateIndex(index, item)
        .then(async () => {
          this.basketInfo = await this.basket.calculateBasketInfo();
          this.basketStorage = await this.basket.getBasket();

          this.calculateWalletPoints();
        });
    } else {
      this.basket.add(item)
        .then(async () => {
          this.basketInfo = await this.basket.calculateBasketInfo();
          this.basketStorage = await this.basket.getBasket();

          this.calculateWalletPoints();
        });
    }
  }

  calculateWalletPoints() {
    if (!!this.config.useWallet) {
      this.view.walletPoints = this.wallet.calculateWalletPoints(this.basketInfo);
    }
  }

  checkout() {
    this.dismiss();
    this.events.publish('view:payments');
  }

  async configure(product: any) {
    let modal = await this.modalCtrl.create({
      component: ConfigureProductPage,
      componentProps: {
        product: product,
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
    });
    modal.onWillDismiss().then(response => {
      if (response.data) {
        let updatedProduct = response.data.product || null;
        this.basketStorage.forEach((basketItem: any, index: number) => {
          if (basketItem.id === product.id) {
            this.basketStorage[index] = updatedProduct;
          }
        });
        this.basket.setBasket(this.basketStorage);
      }
    });
    await modal.present();
  }

  async coupon() {
    this.couponModal = await this.modalCtrl.create({
      component: CouponPage,
      componentProps: {
      },
      animated: true,
      canDismiss: true,
    });
    this.couponModal.onWillDismiss()
      .then(
        (response: any) => this.validateCoupon(response)
      );
    await this.couponModal.present();
  }

  async dismiss() {
    this.modalCtrl.dismiss();
  }

  ionViewWillEnter() {
    this.loadBasket();
  }

  loadBasket() {
    this.basket.getBasket()
      .then(async (basketStorage: basketItem[]) => {
        this.basketStorage = basketStorage;
        this.basketInfo = await this.basket.calculateBasketInfo();
        
        this.calculateWalletPoints();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {
    this.loadBasket();
  }

  remove(item: any, index: number) {
    item.amount = parseFloat(item.amount || 1);
    item.amount--;

    if ((!item.variations || !item.variations.length) && !item.amount) {
      this.basketStorage = this.basketStorage.filter(_item => {
        return _item.id !== item.id;
      });
    } else
      if (!item.amount) {
        this.basketStorage = this.basketStorage.filter((_item: any, _index: number) => {
          //console.log(_index, index);
          return _index !== index;
        });
      }

    this.basket.setBasket(this.basketStorage)
      .then(async () => {
        this.basketInfo = await this.basket.calculateBasketInfo();
        this.calculateWalletPoints();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  shop() {
    this.navCtrl.navigateRoot(this.configService.getRoute('home'));
    this.dismiss();
  }

  validateCoupon(response: any) {
    if (response.data.use && (!response.data.coupon || !response.data.coupon.id)) {
      this.events.publish('error', 'Der Gutschein-Code wurde nicht gefunden.');
    } else {
      this.events.publish('appcms:alert', {
        title: 'Hinzugefügt',
        message: 'Der Gutschein-Code wurde eingelöst.',
      });
    }
  }

  viewItem(item: any, index: number) {
    this.basket.setEditItemIndex(index);

    console.log('> item.type', item.type);

    switch (item.type) {
      case 'event':
        this.events.publish('view:event', item);
        break;
      default:
        this.events.publish('view:product', item);
        break;
    }

    this.dismiss();

  }

}