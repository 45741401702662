import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { ClipboardService } from '../../../services/clipboard.service';

@Component({
  selector: 'app-sharing-modal',
  templateUrl: './sharing-modal.page.html',
  styleUrls: ['./sharing-modal.page.scss'],
})
export class SharingModalPage implements OnInit {

  shareOptions: any = {};

  constructor(
    private clipboard: ClipboardService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {
    this.shareOptions = this.navParams.get('shareOptions');
  }

  copyToClipboard() {
    let text = `${this.shareOptions.title || ''}\n${this.shareOptions.message || ''}\n${this.shareOptions.url || ''}`;

    this.clipboard.copyText(text)
    .catch((error) => {
      console.warn('error!');
    })
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

}
