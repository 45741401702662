import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ConfigService } from '../../services/config.service';
import { EventsService } from '../../services/events.service';
import { PostsService } from '../../services/posts.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.page.html',
  styleUrls: ['./view.page.scss'],
})
export class ViewPage implements OnInit {

  allowWithoutLogin: boolean = true;

  user: user;

  view: any = {
    footer: {},
    header: {},
    post: {},
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private posts: PostsService,
    public userService: UserService,
  ) {
    this.user = this.userService.getUser() || {};
    this.view.slug = this.route.snapshot.paramMap.get('slug');

    if(this.view.slug) {
      this.loadData();
    }
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
  }

  loadData() {
    let method = this.view.slug == parseInt(this.view.slug) ? 'getPostByUid' : 'getPostBySlug';

    if(!this.posts || !this.posts[method]) {
      console.warn('missing posts method');
      this.events.publish('view:post', this.view.slug);
      return false;
    }

    this.posts[method](this.view.slug)
    .then((post: post) => {
      this.events.publish('view:post', post);
      setTimeout(() => {
        this.navCtrl.navigateRoot(this.configService.getRoute('home'));
      });
    })
    .catch((error: any) => {
      this.events.publish('error', error);
      this.navCtrl.navigateForward('/login');
    });
  }

  ngOnInit() {
  }

}
