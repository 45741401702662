import { Component, OnInit } from '@angular/core';

import { EventsService } from '../../../services/events.service';
import { ReactionsService } from '../../../services/reactions.service';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'pipeline-reactions-stage',
  templateUrl: './reactions-stage.component.html',
  styleUrls: ['./reactions-stage.component.scss'],
})
export class ReactionsStageComponent implements OnInit {

  view: any = {

  };

  constructor(
    private events: EventsService,
    private reactions: ReactionsService,
    private sanitizer: DomSanitizer,
  ) {

  }

  initEvents() {
    this.events.subscribe('reactions:stage:render', (stageConfig: any) => {
      this.renderStage(stageConfig);
    });
  }

  ngOnInit() {
    this.initEvents();
  }

  renderStage(reaction: reaction) {
    
    this.view.stage = this.sanitizer.bypassSecurityTrustHtml(
      this.reactions.getStageHtml(reaction)
    );

    setTimeout(() => {
      this.view.stage = null;
    }, 2 * 1000);

  }

}
