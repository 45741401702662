<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-icon name="eye-off-outline"></ion-icon>

  <ion-card>
    <ion-card-header>
      <ion-card-title [innerHTML]="'invisible_title'|translate"></ion-card-title>
      <ion-card-subtitle [innerHTML]="'invisible_subtitle'|translate"></ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ion-button size="block" color="dark" (click)="setVisible(true)">
        <ion-label [innerHTML]="'invisible_off'|translate"></ion-label>
      </ion-button>
      <ion-button size="block" color="dark" (click)="setVisible(false, true)" fill="outline">
        <ion-label [innerHTML]="'invisible_on'|translate"></ion-label>
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
