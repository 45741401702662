import { Injectable } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

import { AppcmsService } from './appcms.service';
import { EventsService } from './events.service';
import { UserService } from './user.service';
import { ToolsService } from './tools.service';
import { ProfileService } from './profile.service';

import { PickStoryPage } from '../pages/stories/pick-story/pick-story.page';

import * as moment from 'moment';
import { PostsService } from './posts.service';

@Injectable({
  providedIn: 'root'
})
export class StoriesService {

  constructor(
    private AppCMS: AppcmsService,
    private events: EventsService,
    private loading: LoadingController,
    private modalCtrl: ModalController,
    private posts: PostsService,
    private profile: ProfileService,
    private tools: ToolsService,
    public userService: UserService,
  ) {

  }

  delete(story: story) {
    return new Promise(async (resolve, reject) => {
      let loading = await this.loading.create({
        spinner: 'circular',
      });
      loading.present();
      
      this.AppCMS.loadPluginData('pipeline', {
        story: story,
      }, ['stories', 'delete'])
      .then((response: any) => {
        this.events.publish('profile:refresh');
        
        loading.dismiss();
        resolve(response);
      }).catch((error: any) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      
    });
  }

  parseStories(stories: story[]) {
    if(stories && stories.length) {
      stories.forEach((story: story, storyIndex: number) => {
        stories[storyIndex] = this.parseStory(story);
      });

      stories = stories.filter((story: story) => {
        return !!(!!story.label && !!story.label.length) || (story.user && story.user.firstname && story.user.lastname);
      });
    }
    return stories;
  }

  parseStory(story: story) {

    if(!story.posts && !story.post) {
      story = this.toStory(story as post);
    }

    if(!story.posts && story.post && (typeof story.post === 'object')) {
      story.posts = [story.post];
    }

    if(story.posts) {
      story.posts.forEach((post: post, index: number) => {
        story.posts[index] = this.posts.getFullPost(post);
        story.posts[index].post_content_formatted = this.tools.cleanContent(story.posts[index]);
        story.posts[index].post_excerpt_formatted = (this.tools.cleanContent(story.posts[index], 'excerpt') || '');
        
        if(story.posts[index].timestamp) {
          story.posts[index].timestamp_formatted = moment(story.posts[index].timestamp).format('DD.MM.YYYY HH:mm');
        }

        let isVideoMode = this.tools.isVideoModeContent(story.posts[index].post_content_formatted);
        
        if(isVideoMode) {
          story.posts[index].video = this.tools.getVideoFromContent(post.post_content);
        }

      });
    }

    return story;
  }

  pick() {
    return new Promise(async (resolve, reject) => {
      let collections = await this.profile.getCollections();

      let pickStoryModal = await this.modalCtrl.create({
        component: PickStoryPage,
        componentProps: {
          collections: collections,
          storiesService: this,
        },
        animated: true,
      });

      pickStoryModal.present();

      resolve({});
    });
  }

  async publish(story: story) {
    let loading = await this.loading.create({
      spinner: 'circular',
    });
    loading.present();

    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        item: story.item,
        type: story.type || 'post',
        user: this.userService.getUid(),
      }, ['stories', 'publish'])
      .then((response: any) => {
        this.events.publish('profile:refresh');

        loading.dismiss();
        resolve(response);
      }).catch((error: any) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

  toStory(post: any) {
    return {
      color: post.color,
      icon: post.icon,
      label: post.label || post.name,
      posts: [post],
      src: post.thumbnail,
      type: post.type,
      uid: post.uid,
      url: post.url,
    } as story;
  }

}
