import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { ConfigService } from './config.service';
import { NetworkService } from './network.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  config: pipelineAppConfig;

  tmp: any = {};

  constructor(
    private configService: ConfigService,
    private network: NetworkService,
    private storage: StorageService,
  ) {
    this.config = this.configService.getConfig();
  }

  clear() {
    return new Promise((resolve, reject) => {
      this.tmp = {};
      window.localStorage.clear();
      this.storage.clear().then(resolve).catch(reject);
    });
  }

  get(key: string, allowedAge: number = 60, unit: any = 'seconds') {
    key = `${this.config.appPackageName}_${key}`;

    return new Promise(async (resolve, reject) => {
      allowedAge = (allowedAge === -1 ? -1 : (this.network.isConnected() ? (allowedAge || 60) : (60 * 60 * 24 * 7)));
      
      this.getItem(key)
      .then((cacheItem: cacheItem) => {
        cacheItem = cacheItem || {};

        if(cacheItem.timestamp) {
          cacheItem.age =  moment().diff(moment.unix(cacheItem.timestamp), unit);
        }
        if(cacheItem && (cacheItem.data !== null) && (allowedAge === -1 || !cacheItem.age || cacheItem.age <= allowedAge)) {
          resolve(cacheItem);
        } else {
          resolve({});
        }
      })
      .catch(reject);
    });
  }

  getItem(key: string) {
     return new Promise((resolve, reject) => {
      let tmp = this.getTmp(key);
      if(tmp) {
        resolve(tmp);
      } else {
        this.storage.get(key)
        .then(resolve)
        .catch((e) => {
          console.warn('storage error > item', e);
          resolve(window.localStorage.getItem(key) || tmp);
        });
      }
     });
  }

  getTmp(key: string) {
    return this.tmp[key];
  }

  remove(key: string) {
    window.localStorage.removeItem(key);
    this.removeTmp(key);
    
    return this.storage.remove(key);
  }

  removeTmp(key: string) {
    delete this.tmp[key];
  }
  
  set(key: string, value: any) {
    key = `${this.config.appPackageName}_${key}`;
    
    return new Promise((resolve, reject) => {

      let options = {
        data: value,
        timestamp: moment().unix(),
      };
      
      try {
        this.setTmp(key, options);
      } catch(e) {
        console.warn('cache: set tmp', e);
      }

      try {
        this.storage.set(key, options).then(resolve).catch(reject);
      } catch(e) {
        console.warn('storage error', e);
        try {
          window.localStorage.setItem(key, JSON.stringify(options));
          resolve({});
        } catch(e) {
          console.warn('cache set error', e);
          resolve({});
        }
      }
    });
  }

  setTmp(key: string, options: any) {
    this.tmp[key] = options;
  }

}
