import { Injectable } from '@angular/core';

import { AppcmsService } from './appcms.service';
import { ModalService } from './modal.service';

import { PostCommentsPage } from '../pages/post/post-comments/post-comments.page';
import { UserService } from './user.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(
    private AppCMS: AppcmsService,
    private modalService: ModalService,
    private userService: UserService,
  ) {

  }

  commentPost(post: post) {
    return new Promise(async (resolve, reject) => {

      let modal = await this.modalService.create({
        component: PostCommentsPage,
        componentProps: {
          commentsService: this,
          post: post,
        },
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: "postCommentsModal",
      });

      modal.onWillDismiss().then((response: any) => {
        console.log('> dismiss comments modal', response);
        resolve(response.data);
      });

      modal.present();
    });
  }

  deleteComment(commentId: number) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['comments', commentId, 'delete']);
  }

  getByPost(postId: number, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {}, ['posts', postId, 'comments'], null, blForceRefresh)
      .then((comments: postComment[]) => {
        if(comments && comments.length) {
          comments.forEach((comment: postComment) => {
            comment = this.parseComment(comment);
          })
        }
        resolve(comments);
      })
      .catch(reject);
    });
  }

  parseComment(comment: postComment) {
    let now = moment(),
        timestampDate = moment(comment.timestamp, 'YYYY-MM-DD HH:mm:ss'),
        timestampToday = now.format('DD.MM.YYYY') === timestampDate.format('DD.MM.YYYY');
      
    comment.timestamp_formatted = timestampToday ? timestampDate.format('HH:mm') : timestampDate.format('DD.MM.YYYY');

    if(comment.user && !!(comment.user as user).uid) {
      comment.user = this.userService.getFullUser(comment.user as user);
      comment.user.displayName = this.userService.getDisplayName(comment.user);
    }

    return comment;
  }

  submit(comment: postComment) {
    return new Promise((resolve, reject) => {
      if(!comment.user || (comment.user === -1)) {
        reject('error_missing_comment_user');
      } else
      if(!comment.item) {
        reject('error_missing_comment_item');
      } else
      if(!comment.description) {
        reject('error_missing_comment_description');
      } else {
        this.AppCMS.loadPluginData('pipeline', {
          comment: comment,
        }, ['comments', 'submit']).then(resolve).catch(reject);
      }
    });
  }

}
