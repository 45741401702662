import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { EventsService } from '../../../services/events.service';
import { ModalService } from '../../../services/modal.service';
import { TranslationService } from '../../../services/translation.service';

@Component({
  selector: 'app-rate-post',
  templateUrl: './rate-post.page.html',
  styleUrls: ['./rate-post.page.scss'],
})
export class RatePostPage implements OnInit {

  rating: any = {
    quality: 3,
    usefulness: 3,
    time: 3,
  };

  ratings: any;
  
  constructor(
    private events: EventsService,
    private modalCtrl: ModalService,
    private navParams: NavParams,
    private translations: TranslationService,
  ) {
    this.ratings = this.navParams.get('ratingsService');
    this.rating.post = this.navParams.get('post');
  }
  
  dismiss(data: any = null) {
    this.modalCtrl.dismiss(data);
  }

  ngOnInit() {
  }

  submit() {
    this.ratings.submit(this.rating)
    .then(() => {
      this.translations.get(['feedback_success'])
      .subscribe((translations: any) => {
        this.events.publish('toast', {
          icon: 'checkmark-outline',
          message: translations.feedback_success || 'Danke für Dein Feedback!',
          color: 'primary',
        });
      });
      this.dismiss(this.rating);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

}
