import { Component, Input, OnInit } from '@angular/core';

import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'pipeline-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() options: postCardOptions = {};
  @Input() product: any;
  @Input() size: number;

  constructor(
    private events: EventsService,
  ) {
  }

  ngOnInit() {
  }

  thumbnailLoadingFailed(product: product = null) {
    product = product || this.product;
    product.hidden = true;
    console.log('> thumbnailLoadingFailed', product);
  }

  viewProduct(product: product) {
    this.events.publish('view:product', product);
  }
  
}