import { Component, OnInit, NgZone, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, PopoverController } from '@ionic/angular';
import { Keyboard } from '@capacitor/keyboard';

import { ProfileSettingsPage } from '../profile-settings/profile-settings.page';
import { VerifiedInfoPage } from '../../account/verified-info/verified-info.page';

import { UserService } from '../../../services/user.service';
import { EventsService } from '../../../services/events.service';
import { ProfileService } from '../../../services/profile.service';
import { FriendsService } from '../../../services/friends.service';
import { MediaextendService } from '../../../services/mediaextend.service';
import { SocialService } from '../../../services/social.service';
import { BrowserService } from '../../../services/browser.service';
import { LogfileService } from '../../../services/logfile.service';
import { ToolsService } from '../../../services/tools.service';
import { TranslationService } from '../../../services/translation.service';
import { ModalService } from '../../../services/modal.service';
import { IntroService } from '../../../services/intro.service';
import { MenuService } from '../../../services/menu.service';
import { CoversService } from '../../../services/covers.service';
import { AdminService } from '../../../services/admin.service';
import { LoginService } from '../../../services/login.service';
import { ConfigService } from '../../../services/config.service';
import { JobtitlesService } from '../../../services/jobtitles.service';
import { CompaniesService } from '../../../services/companies.service';
import { InboxService } from '../../../services/inbox.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfilePage implements OnInit {
  @ViewChild('descriptionText', { read: ElementRef }) descriptionText: ElementRef;
  @ViewChild('socialGrid', { read: ElementRef }) socialGrid: ElementRef;

  appConfig: pipelineAppConfig;

  avatarUrl: string = './assets/img/avatars/1.jpg';

  collections: any;

  profile: any = {};

  profileId: number;

  view: any = {
    contentPaddingTop: '20vh',
    headerOpacity: 1,
    headerToolbarOpacity: 1,
    iOffset: 0,
    isModal: false,
    segment: 'post',
    userTitles: [],
  };
  
  user: user;

  constructor(
    private admin: AdminService,
    private browser: BrowserService,
    private changeDetector: ChangeDetectorRef,
    private companies: CompaniesService,
    private configService: ConfigService,
    private coversService: CoversService,
    private events: EventsService,
    public friends: FriendsService,
    private inbox: InboxService,
    private intro: IntroService,
    private jobTitles: JobtitlesService,
    private route: ActivatedRoute,
    private log: LogfileService,
    public loginService: LoginService,
    private navCtrl: NavController,
    private menu: MenuService,
    private mediaService: MediaextendService,
    private modalService: ModalService,
    private profileService: ProfileService,
    private popoverCtrl: PopoverController,
    public userService: UserService,
    private social: SocialService,
    private tools: ToolsService,
    private translations: TranslationService,
    private zone: NgZone,
  ) {
    this.appConfig = this.configService.getConfig();
    this.user = this.userService.getUser() || {};

    this.view.os = this.tools.getOS();
    this.view.userTitles = this.userService.getTitles();
    
    let activeProfile = this.userService.getActiveProfile();
     
    if(activeProfile) {
      this.profile = activeProfile;
      this.profile.me = this.profile.uid === this.user.uid;
    }

    this.events.subscribe("appcms:user:updated", () => {
      this.user = this.userService.getUser() || {};
      this.calcPrimaryLabel();
    });

    this.events.subscribe('profile:refresh', () => {
      console.log('> profile: refresh!');
      this.doRefresh();
    });
    
  }

  addSocial() {
    this.saveProfile();

    this.social.showAddSocial()
    .then(() => {
      this.view.editMode = true;
      
      this.loadProfile(true)
      .catch((error) => {
        this.handleError(error);
      });
    })
    .catch((error: any) =>  {
      this.events.publish('error', error);
    });
  }

  calcPrimaryLabel() {
    let primaryLabel: string = (this.profile.uid ? this.profile.nickname || 'no_nickname': '...');

    if(this.appConfig.showProfileUserGroup && this.appConfig.showProfileUserGroupAsPrimaryLabel) {
      primaryLabel = (this.profile && this.profile.classifications && this.profile.classifications.type ? this.profile.classifications.type : primaryLabel);
    }

    this.view.primaryLabel = primaryLabel;
    this.detectChanges();
  }

  calcViewVars() {
    this.detectChanges();
  }

  chat() {
    if(!!this.profile.uid) {
      this.inbox.setPartnerId(this.profile.uid);
      this.modalService.dismiss();
      this.navCtrl.navigateForward('/messenger'); 
    }
  }

  chooseAvatar() {
    this.mediaService.chooseFromMedia({
      admin: this.profile.me,
      location: 'avatar',
      photo: this.profile.photo,
      photoType: 'photo',
    })
    .then((image: any) => {
      if(image && image.length) {
        this.profile.photo = image;

        this.userService.setUser(this.profile, true)
        .then(() => {
          this.view.editMode = false;
          this.detectChanges();
        })
        .catch((error: any) => {
          if(!!error) {
            this.events.publish('error', error);
          }
        });

      }
      
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  coverLoadingFailed() {

  }

  detectChanges() {
    setTimeout(() => {
      this.zone.run(() => {
        this.changeDetector.detectChanges();
      });
    });
  }

  dismiss() {
    try {
      this.modalService.getTop()
      .then((top: any) => {
        if(top) {
          this.modalService.dismiss();
        } else {
          this.navCtrl.pop();
        }
      });
    } catch(e) {
      console.warn('e', e);
      this.navCtrl.pop();
    };
  }

  doRefresh(event: any = null) {

    if(!!this.view.editMode) {
      console.warn('> not refreshing: edit mode');
      if(event) {
        event.target.complete();
      }
      return false;  
    }

    this.loadProfile(true)
      .then(() => {
        if(event) {
          event.target.complete();
        }
        this.detectChanges();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  editProfile(bl: boolean = true) {
    if(this.profile.me) {
      if(!bl) {
        this.saveProfile();
        return false;
      }
      this.view.canSave = !!bl;
      this.view.editMode = true;
      this.detectChanges();
    }
  }

  follow(profile: user = null) {
    profile = profile || this.profile;

    let _isFriend = !!parseInt(profile.isFriend + '');
    profile.isFriend = true;
    this.detectChanges();

    this.friends.addFriend(profile.uid)
    .then((response: any) => {
      if(response.success) {
        profile.isFriend = true;
        this.detectChanges();
      }
    }).catch((error) => {
      profile.isFriend = _isFriend;
      this.detectChanges();
      this.handleError(error);
    });
  }

  handleError(error: any) {
    console.warn('handleError', error);
    if(error === 'Der Benutzer ist nicht öffentlich') {
      this.collections = {};
      this.profile.uid = this.profileId;
      this.profile.public = true;
    } else {
      this.events.publish('error', error);
    }
  } 

  async initIntro() {
    let blShouldShowIntro: boolean = !!(await this.intro.shouldUse('blShouldUseProfileIntro'));
    
    // @debug
    //blShouldShowIntro = true;

    if(blShouldShowIntro && this.profile.me) {
      await this.intro.setupProfile();
      this.intro.show();
    }
  }

  ionViewWillEnter() {
    this.avatarUrl = this.userService.getAvatarUrl();
    
    this.profileId = parseInt(this.route.snapshot.paramMap.get('profileId')) || this.userService.getActiveProfileId();
    this.profileId = this.profileId || this.user.uid;

    this.user = this.userService.getUser() || {};

    this.view.isAdmin = this.userService.isType('Admin');
    this.view.isModal = this.modalService.isModal();
    this.view.os = this.tools.getOS();

    this.calcPrimaryLabel();

    if(!this.user || !this.user.uid || (this.user.uid === -1) && !!this.appConfig.showLoginPage) {
      this.navCtrl.navigateRoot('/login', { animated: false, });
    } else {
      this.loadProfile()
      .then(() => {
        this.detectChanges();

        try {
          this.log.countProfileView(this.profileId);
        } catch(e) {
          console.warn('log profile view error', e);
        }
        
      })
      .catch((error) => {
        this.handleError(error);
      });
    }
  }

  ionViewWillLeave() {
    this.userService.setActiveProfile(null);
    this.modalService.setIsModal(false);
  }

  loadNext(event: any) {
    this.view.iOffset++;

    let iCountBefore = this.view.posts && this.view.posts.all ? this.view.posts.all.length || 0 : 0;

    if(!!this.view.editMode) {
      console.warn('> not loading next: edit mode');
      if(event) {
        event.target.complete();
      }
      return false;  
    }

    this.loadProfile(true)
    .then(() => {

      this.translations.get(['no_further_contents'])
      .subscribe((translations: any) => {
        let iCountAfter = this.view.posts && this.view.posts.all ? this.view.posts.all.length || 0 : 0;

        let message = iCountBefore === iCountAfter ? (translations.no_further_contents || 'Keine weiteren Beiträge') : (iCountAfter - iCountBefore) + ' weitere geladen';
        this.events.publish('toast', {
          message: message,
          color: 'primary',
        });

      });
      
      event.target.complete();
    })
    .catch((error: any) => {
      event.target.complete();
      if(error && error.hasOwnProperty('message')) {
        this.events.publish('error', error.message);
      }
    });
  }

  loadProfile(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      let activeProfile = this.userService.getActiveProfile();

      if(!this.view.iOffset) {
        this.collections = this.setPlaceholderCollections();
      }

      if(activeProfile) {
        this.profile = activeProfile;
        this.profile.me = this.profile.uid === this.user.uid;
      }

      if(!this.profileId) {
        console.warn('error, missing this.profileId');
      }

      this.view.loading = true;
      this.detectChanges();

      if(this.profileId === -1) {
        let route: string = (!!this.appConfig.showLoginPage ? '/login' : this.configService.getRoute('home'));
        this.navCtrl.navigateForward(route || '/login');
        return false;
      }

      this.profileService
      .getProfile(this.profileId, blForceRefresh, {
        offset: this.view.iOffset
      })
      .then((profileData: profileData) => {
          this.view.loading = false;

          if(profileData.profile && profileData.profile.uid) {
            profileData.profile.me = profileData.profile.uid === this.user.uid;
    
              this.profile = this.userService.getFullUser(profileData.profile);
              this.profile.me = (profileData.profile.uid === this.user.uid);
              this.profile.classifications = this.profile.classifications || {};
              this.profile.classifications.coverImage = (this.profile.classifications.coverImage || this.coversService.getDefault()).replace('_512_511.jpg', '.jpg').replace('_512_512.jpg', '.jpg');
              
              this.profile.classifications.websiteCleaned = this.tools.cleanLink(this.profile.classifications.website);
              this.profile.classifications.social = profileData.social;
              this.profile.isFriend = !!profileData.isFriend;

              this.collections = profileData.collectionData;

              this.view.hasCustomCollections = !!(
                this.collections &&
                this.collections.custom &&
                this.collections.custom.items &&
                this.collections.custom.items.length
              );

              if(this.view.hasCustomCollections) {
                
                if(this.profile.uid !== this.user.uid) {
                  this.collections.custom.items = this.collections.custom.items.filter((collectionItem: collectionItem) => {
                    return collectionItem && !!collectionItem.thumbnail;
                  });
                }

                if(!this.view.segmentChanged) {
                  this.view.segment = 'custom';
                }
              }

              if(profileData.stories) {
                this.view.stories = profileData.stories;
              }

              this.calcViewVars();

              setTimeout(() => {
                //this.initIntro();
              }, 250);
              
              resolve(profileData);
          } else {
            this.events.publish('error', 'Profil nicht gefunden');
            reject();
          }
        })
      .catch((error: any) => {
        this.view.loading = false;
        this.detectChanges();
        
        reject(error);
      });      
    });
  }

  logProfileView() {
    /*
    setTimeout(() => {
      this.log.add({
        class: 'profile_view',
        group: 'profile_view_' + this.profile.uid,
        description: (this.user.nickname || this.user.firstname) + ' hat sich das Profil von "' + (this.profile.nickname || this.profile.firstname) + '" angesehen',
        inAppLink: this.profile.uid,
      })
      .catch((error: any) => {
        console.warn('log error:', error);
      });
    }, 2500);
    */
  }

  menuToggle() {
    this.menu.toggle();
  }

  ngOnInit() {
    this.view.hasSuggestions = false;
    
    this.events.subscribe('view:suggestions:updated', () => {
      this.view.hasSuggestions = true;
      this.detectChanges();
    });

  }

  onColClick(col: col, type: string = null) {
    col.type = col.type || type;

    switch(col.type) {
      case 'custom':
        return this.onCustomFolderClick(col as collectionsResponseItem)
        break;
      case 'person':
        return this.onPersonClick(col as user);
      case 'post':
        return this.onPostClick(col as post);
      case "product":
        return this.onProductClick(col);
      case 'simple':
        return this.onProductClick(col);
      case 'variable':
        return this.onProductClick(col);
      default:
        this.events.publish('error', 'Unbekannter Typ ' + col.type); 
        break;
    }
  }

  onCoverClick() {
    
    if( !this.profile ||
        !this.profile.classifications ||
        !this.profile.classifications.coverImage ||
        !this.profile.classifications.coverImage.length) {
      //console.log('no cover set');
      return false;
    }
    
    this.mediaService.chooseFromMedia({
      admin: this.profile.me,
      location: 'cover',
      photo: this.profile.classifications.coverImage,
      photoType: 'cover',
      aspectRatio: (16 / 9)
    })
    .then((image: any) => {

      if(image && image.length) {
        this.profile.classifications = this.profile.classifications || {};
        this.profile.classifications.coverImage = image;
  
        this.userService.setUser(this.profile, true)
        .then(() => {
          this.view.editMode = false;
        })
        .catch((error: any) => {
          if(!!error) {
            this.events.publish('error', error);
          }
        });
      } else {
        this.loadProfile(true)
        .catch((error: any) => {
          if(!!error) {
            this.events.publish('error', error);
          }
        });
      }
      
    })
    .catch((error: any) => {
      if(typeof error === 'string' && error === 'No Image Selected') {
        this.translations.get(['no_image_selected'])
        .subscribe((translations: any) => {
          this.events.publish('toast', {
            icon: 'warning-outline',
            message: translations.no_image_selected || 'Kein Bild ausgewählt',
            color: 'warning',
          });
        });
      } else {
        this.events.publish('error', error);
      }
    });
  }

  async onCustomFolderClick(collectionsResponseItem: collectionsResponseItem) {
    this.events.publish('view:collection', collectionsResponseItem);
  }

  async onPersonClick(user: user) {
    this.events.publish('view:profile', user);
  }

  async onPostClick(post: post) {
    this.events.publish('view:post', post);
  }

  async onProductClick(product: any) {
    this.events.publish('view:product', product);
  }

  onScroll(event: any) {
    let maxScroll = window.outerHeight * 0.2,
        scrollTop = event.detail.scrollTop,
        offset = scrollTop > maxScroll ? maxScroll : scrollTop;

    this.zone.run(() => {
      this.view.headerOpacity = ((100 - ((100 / maxScroll) * offset)) / 100).toFixed(3);
      this.view.headerToolbarOpacity = (1 - ((100 - ((100 / maxScroll) * offset)) / 100)).toFixed(3);
      
      this.detectChanges();
    });

  };

  async onUsernameChanged() {

    if(!this.profile || !this.profile.nickname || !this.profile.nickname.length) {
      this.view.usernameAvailable = false;
      this.view.usernameError = 'error_missing_nickname';
      this.view.canSave = false;
      this.detectChanges();
      return false;
    }

    try {
      let check: any = await this.userService.checkUsername(this.profile.nickname);
      
      this.view.canSave = !!check.success;
      this.view.usernameAvailable = !!check.success;

      if(this.view.usernameAvailable) {
        delete this.view.usernameError;
      }

      this.detectChanges();
    } catch(e) {
      this.view.usernameAvailable = false;
      this.view.usernameError = (e || '').toLowerCase();
      this.view.canSave = false;

      this.detectChanges();
    }
  }

  openWebsite(url: string) {
    if ((url + "").substr(0, 4) !== "http") {
      url = "https://" + url;
    }
    this.browser.create(url);
  }

  async profileSettings(ev: any = null) {
   let profileSettingsPopover = await this.popoverCtrl.create({
     component: ProfileSettingsPage,
     componentProps: {
      profile: this.profile,
     },
     animated: true,
     event: ev,
   });
   profileSettingsPopover.present();
  }

  removeSocial(social: socialData) {
    this.social.remove(social)
    .then(() => {
      this.loadProfile(true)
      .catch((error) => {
        this.handleError(error);
      });
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  saveProfile() {

    if(!this.view.canSave) {
      this.events.publish('error', 'error_check_input_fields');
      return false;
    }

    if(this.profile.uid !== this.user.uid) {
      this.events.publish('error', 'error_not_allowed');
      return false;
    }

    this.userService.setUser(this.profile, true)
    .then(() => {
      this.view.editMode = false;
      Keyboard.hide();
      this.calcViewVars();
    })
    .catch((error: any) => {
      Keyboard.hide();
      this.events.publish('error', error);
    });

  }

  segmentChanged() {
    this.zone.run(() => {
      this.view.segmentChanged = true;
      this.detectChanges();
    });
  }

  setPlaceholderCollections() {
    return {
      'post': {
        items: [{}, {}, {}, {}, {}, {}, {}, {}, {}]
      },
      'product': {
        items: [{}, {}, {}, {}, {}, {}, {}, {}, {}]
      },
      'people': {
        items: [{}, {}, {}, {}, {}, {}, {}, {}, {}]
      },
    }
  }

  settings() {
    this.navCtrl.navigateForward('/tabs/settings');
  }

  setupCompany() {

    if(!this.profile.me || !this.view.editMode) {
      return false;
    }
    
    this.companies.pick()
    .then((response: any) => {
      let company: company = (response && response.data && response.data.item ? response.data.item : null);
      
      if(!!company && !!company.uid) {
        this.profile.classifications = this.profile.classifications || {};
        this.profile.classifications.companyLabel = company.name;
        this.profile.classifications.companyUid = company.uid;

        this.detectChanges();
      }
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  setupJobTitle() {

    if(!this.profile.me || !this.view.editMode) {
      return false;
    }

    this.jobTitles.pick()
    .then((response: any) => {
      let jobTitle: jobTitle = (response && response.data && response.data.item ? response.data.item : null);
      console.log('> jobTitle', jobTitle);

      if(!!jobTitle) {
        this.profile.classifications = this.profile.classifications || {};
        this.profile.classifications.jobTitleLabel = jobTitle.label;
        this.profile.classifications.jobTitleUid = jobTitle.uid;

        this.detectChanges();
      }

    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  storyFailed(story: story) {
    console.warn('> story failed', story);
  }
  
  toggleFollow() {
    let uid: number = this.userService.getUid();

    if(!uid || (uid === -1)) {
      this.loginService.show(true)
      .then(() => {
        if(this.profile.isFriend) {
          this.unfollow();
        } else {
          this.follow();
        }
      });
    } else {
      if(this.profile.isFriend) {
        this.unfollow();
      } else {
        this.follow();
      }
    }
  }

  unfollow(profile: user = null) {
    profile = profile || this.profile;

    let _isFriend = !!parseInt(profile.isFriend + '');
    profile.isFriend = false;
    this.detectChanges();

    this.friends.removeFriend(profile.uid)
    .then((response: any) => {
      if(response.success) {
        profile.isFriend = false;
        this.detectChanges();
      }
    })
    .catch((error: any) => {
      profile.isFriend = _isFriend;
      this.detectChanges();
      this.events.publish('error', error);
    });
  }

  async verifiedInfo() {
    let verifiedModal = await this.modalService.create({
      component: VerifiedInfoPage,
      componentProps: {
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'verifiedInfoModal'
    });
    verifiedModal.present();
  }

  viewSocial(social: socialData) {

    if(!!this.view.editMode) {
      console.warn('edit mode, not opening');
      return false;
    }

    social = JSON.parse(JSON.stringify(social));

    if(social.value === 'email') {
      if(social.url && social.url.length && social.url.indexOf('mailto:') === -1) {
        social.url = 'mailto:' + social.url;
      }
    } else
    if((social.url + '').substr(0, 4) !== 'http') {
      social.url = 'https://' + social.url;
    }

    this.browser.create(social.url);
  }

  viewStory(story: story, index: number) {
    let posts: post[] = [];

    if(this.view.stories && this.view.stories.length) {
      this.view.stories.forEach((story: story) => {
        if(story.post && story.post.uid) {
          posts.push(story.post);
        }
      });
    }
    
    this.events.publish("view:story", {
      index: index,
      story: Object.assign(story, {
        posts: posts,
      }),
      uid: story.uid,
    });

  }

  async writePost() {

    let debug: boolean = false;
    
    if(debug || (!this.userService.isType('Admin') && !this.userService.isType('Creator'))) {
      this.navCtrl.navigateForward('/create-contents-banner');
      return false;
    }

    this.admin.createPost();
  }

}