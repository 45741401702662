import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';

import { CheckoutService } from '../../../services/checkout.service';
import { BasketService } from '../../../services/basket.service';
import { PaymentsService } from '../../../services/payments.service';
import { EventsService } from '../../../services/events.service';
import { OrdersService } from '../../../services/orders.service';
import { UserService } from '../../../services/user.service';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.page.html',
  styleUrls: ['./checkout.page.scss'],
})
export class CheckoutPage implements OnInit {

  basketInfo: basketInfo;

  basketStorage: any;

  block: boolean = false;

  deliveryOptions: any;

  estimatedTime: any;

  intervals: any = {
    'daily': 'täglich',
    'weekly': 'wöchentlich',
    'monthly': 'monatlich',
  };

  tmpOrder: any;

  paymentOptions: any;

  user: user;

  view: any = {};

  constructor(
    private basket: BasketService,
    private checkoutService: CheckoutService,
    private events: EventsService,
    private modalCtrl: ModalService,
    private navCtrl: NavController,
    private orders: OrdersService,
    private payments: PaymentsService,
    private platform: Platform,
    public userService: UserService,
  ) {
    this.user = this.userService.getUser() || {};
  }

  afterSuccessPay() {
    let method = this.deliveryOptions.repeat && this.tmpOrder && this.tmpOrder.interval ? 'checkoutScheduled' : 'checkout';
    let navPage = method == 'checkoutScheduled' ? '/planner' : '/on-delivery';

    this.checkoutService[method]().then(() => {
      this.block = false;
      this.navCtrl.navigateRoot(navPage);
    })
      .catch((error: any) => {
        this.orderFailed(error);
      });
  }

  changePayment() {
    this.dismiss();
    this.events.publish('view:payments');
  }

  async dismiss() {
    this.modalCtrl.dismiss();
  }

  async ionViewWillEnter() {
    this.basketStorage = await this.basket.getBasket();
    this.basketInfo = await this.basket.calculateBasketInfo();
    this.deliveryOptions = this.checkoutService.getDeliveryOptions();
    this.tmpOrder = this.orders.getTmpOrder();

    this.paymentOptions = this.payments.getPaymentOptions();

    if (!this.paymentOptions.paymentMethod) {
      this.events.publish('view:payments');
      this.dismiss();
    }

  }

  ionViewDidEnter() {
    this.platform.ready();
  }

  ngOnInit() {
    this.basket.calculateBasketInfo()
      .then((basketInfo: basketInfo) => {
        this.basketInfo = basketInfo;
      });

    this.basket.getBasket()
      .then((basket: basketStorage) => {
        this.basketStorage = basket;
      });

    this.tmpOrder = this.orders.getTmpOrder();
    this.deliveryOptions = this.checkoutService.getDeliveryOptions();
    this.paymentOptions = this.payments.getPaymentOptions();
  }

  orderFailed(error: string = 'Ein unbekannter Fehler ist aufgetreten') {
    this.block = false;
    this.events.publish('error', error);
  }

  pay() {
    this.payments.pay()
      .then((response: any) => {
        console.log('> checkout response', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  shop() {

  }

  viewItem(item: any, index: number) {
    this.basket.setEditItemIndex(index);
    this.navCtrl.navigateForward('/details/' + item.id);
  }

}
