import { Injectable } from '@angular/core';

import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SourcesService {

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    public userService: UserService,
  ) {

  }

  createSource(source: source) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        source: source,
      },
      ["sources", "create"]
    );
  }
  
  deleteSource(sourceId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {},
      ["sources", sourceId, "delete"]
    );
  }
  
  getGlobalSources(blForceRefresh: boolean = false, config: any = {}) {
    return new Promise(async (resolve, reject) => {
      let key = "global_sources_" + JSON.stringify(config),
        fromCache: cacheItem = await this.cache.get(key, 30 * 60);
  
      config.user = config.user || this.userService.getApiCredentials();
      config.userId = config.userId || this.userService.getUid();

      if (!blForceRefresh && fromCache && fromCache.data) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData("pipeline", config, ["sources", "global"])
          .then((sources: source[]) => {
            this.cache.set(key, sources);
            resolve(sources);
          })
          .catch(reject);
      }
    });
  }

  getRegionalSources(blForceRefresh: boolean = false, config: any = {}) {
    return new Promise(async (resolve, reject) => {
      let key = "pipeline_sources_regional_" + JSON.stringify(config),
        fromCache: cacheItem = await this.cache.get(key, 30 * 60);

      config.user = config.user || this.userService.getApiCredentials();
      config.userId = config.userId || this.userService.getUid();

      if (!blForceRefresh && fromCache && fromCache.data) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData("pipeline", config, ["sources", "regional"])
          .then((sources: source[]) => {
            this.cache.set(key, sources);
            resolve(sources);
          })
          .catch(reject);
      }
    });
  }

  getSources(blForceRefresh: boolean = false, config: any = {}) {
    return new Promise(async (resolve, reject) => {
      let cacheKey: string = 'pipeline_sources_' + JSON.stringify(config),
          fromCache: cacheItem = await this.cache.get(cacheKey, (30 * 60));

      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data as source[]);
      } else {
        this.AppCMS.loadPluginData("pipeline", config, ["sources"])
        .then((sources: source[]) => {

          if(sources && sources.length) {
            sources.forEach((source: source) => {

              // @todo fix this duplicate key
              source.name = source.name || source.title;
              source.title = source.title || source.name;

              source.system = source.system || 'blog';
            });
          }

          this.cache.set(cacheKey, sources);
          resolve(sources as source[]);
        })
        .catch(reject);
      }
    })
  }

  getSourceByUid(sourceId: number, blForceRefresh: boolean = false, config: any = {}) {
    return new Promise(async (resolve, reject) => {
      let cacheKey: string = 'pipeline_source_' + sourceId + '_' + JSON.stringify(config),
          fromCache: cacheItem = await this.cache.get(cacheKey, (30 * 60));

      if(!blForceRefresh && (fromCache && fromCache.data)) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData('pipeline', config, ['sources', sourceId])
        .then((sources: source[]) => {
          this.cache.set(cacheKey, sources);
          resolve(sources);
        })
        .catch(reject);
      }
    })
  }

  getSourceSettings(sourceId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {},
      ["sources", sourceId, "settings"]
    );
  }

  groupSystemsToSources(sources: source[]) {
    if(sources && sources.length) {
      let _sources = {};

      sources.forEach((source: source) => {
        _sources[source.uid] = _sources[source.uid] || source;
        _sources[source.uid].systems = _sources[source.uid].systems || [];
        if(_sources[source.uid].systems.indexOf(source.system) === -1) {
          _sources[source.uid].systems.push(source.system);
        }
      });

      sources = (Object.values(_sources) as source[]);
    }

    return sources;
  }

  index(source: source) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData("pipeline", {}, [
        "sources",
        source.uid,
        "index",
      ])
        .then(resolve)
        .catch(reject);
    });
  }
  
  updateSource(source: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      source: source,
      user: this.userService.getUid()
    }, ['sources', source.uid, 'update']);
  }

  updateSourcePermissions(permissions: any, sourceId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      permissions: permissions,
      source: sourceId,
      user: this.userService.getUid()
    }, ['sources', sourceId, 'permissions', 'update']);
  }

}
