import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-choose-avatar',
  templateUrl: './choose-avatar.page.html',
  styleUrls: ['./choose-avatar.page.scss'],
})
export class ChooseAvatarPage implements OnInit {

  view: any = {
    gender: 'm',
  };

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public userService: UserService,
  ) {
    this.view.avatars = this.navParams.get('avatars');
    this.view.gender = (this.userService.getGender() || 'm');
    this.view.genders = this.userService.getGenders();
  }

  dismiss(avatar: avatar = null) {
    this.modalCtrl.dismiss(avatar);
  }

  ngOnInit() {
  }

  use(avatar: avatar) {
    this.view.avatar = avatar;
  }

  update() {
    this.dismiss(this.view.avatar);
  }

}
