import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { AlertController, LoadingController } from '@ionic/angular';

import { PurchaseService } from './purchase.service';
import { TranslationService } from './translation.service';
import { ToolsService } from './tools.service';
import { WebService } from './web.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AbonnementService {

  appConfig: pipelineAppConfig;

  constructor(
    private alertCtrl: AlertController,
    private config: ConfigService,
    private loading: LoadingController,
    private purchaseService: PurchaseService,
    private translations: TranslationService,
    private tools: ToolsService,
    private user: UserService,
    private webService: WebService,
  ) {
    this.appConfig = this.config.getConfig();
  }

  calcVip(post: post) {
    
    let blIsAdmin = (
      this.user.isType('Admin') ||
      this.user.isType('Creator') ||
      this.user.isType('Moderator')
    );

    if(blIsAdmin || post.sponsored || (post.type !== 'post')) {
      return false;
    }

    if(post.vip) {
      return true;
    }

    // @debug
    return Math.random() < this.getPostsVipFactor();
  }
  
  getCurrentVersion() {
    let user = this.user.getUser() || {};
    if(this.user.isType('Admin')) {
      return 'premium';
    }
    return user && user.classifications && user.classifications.abo ? user.classifications.abo : 'free';
  }

  onAcceptBeta() {
    return new Promise((resolve, reject) => {
      this.user.setClassifications({
        abo: 'premium',
        isBetaTester: true,
      })
      .then(() => {
        this.translations.get([
          'prompt_beta_test_submitted_title',
          'prompt_beta_test_submitted_message',
          'okay',
        ])
        .subscribe(async (translations: any) => {
          
          let alert = await this.alertCtrl.create({
            header: translations.prompt_beta_test_submitted_title,
            message: translations.prompt_beta_test_submitted_message,
            buttons: [
              {
                text: translations.okay,
                role: 'cancel',
              }
            ]
          });
  
          alert.present();
  
          resolve({
            abo: 'premium',
          });
        });
      })
      .catch(reject);
    });
  }

  getPostsVipFactor() {
    return (this.appConfig.postsVipFactor !== null && this.appConfig.postsVipFactor !== undefined ? this.appConfig.postsVipFactor : 0.025);
  }

  promptBetaTest() {
    return new Promise((resolve, reject) => {
      this.translations.get([
        'prompt_beta_test_title',
        'prompt_beta_test_message',
        'accept_beta',
        'cancel',
      ])
      .subscribe(async (translations: any) => {
        //console.log('translations', translations);
        let alert = await this.alertCtrl.create({
          header: translations.prompt_beta_test_title,
          message: translations.prompt_beta_test_message,
          buttons: [
            {
              text: translations.accept_beta,
              handler: () => {
                this.onAcceptBeta().then(resolve).catch(reject);
              },
              role: 'submit',
            },
            {
              text: translations.cancel,
              role: 'cancel',
            }
          ]
        });
        alert.present();
      });
    });
  }
  
  switch(versionName: string) {
    return new Promise(async (resolve, reject) => {
      let user = this.user.getUser() || {};

      if(!user || !user.uid) {
        reject('error_missing_user_uid');
        return;
      }

      if(this.tools.isWeb()) {
        return this.webService.appFeaturesRequested();
      }

      if(versionName !== 'free') {
        this.purchaseService.purchase('de.app.tie.' + versionName)
        .then((response: any) => {
          //console.log('purchase response (b)', response);
        })
        .catch(reject);
      } else {
        this.updateUserAfterSwitch(versionName).then(resolve).catch(reject);
      }
    });
  }

  updateUserAfterSwitch(versionName: string) {
    return new Promise(async (resolve, reject) => {
      let loading = await this.loading.create({
        spinner: 'circular',
      });
      loading.present();

      let user = this.user.getUser() || {};

      user.classifications = user.classifications || {};
      user.classifications.abo = versionName;

      this.user.setUser(user, true)
        .then((user: user) => {
          loading.dismiss();
          resolve(user);
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

}
