import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { BasketService } from './basket.service';
import { WoocommerceextendService } from './woocommerceextend.service';
import { OrdersService } from './orders.service';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  deliveryOptions: any = window.localStorage.getItem('deliveryOptions') ? JSON.parse(window.localStorage.getItem('deliveryOptions')) : null;

  pendingOrder: any = window.localStorage.getItem('pendingOrder') ? JSON.parse(window.localStorage.getItem('pendingOrder')) : null;

  constructor(
    private basket: BasketService,
    private events: EventsService,
    private orders: OrdersService,
    private woocommerce: WoocommerceextendService,
  ) {
  }

  checkout() {
    return new Promise(async (resolve, reject) => {
      this.basket.getBasket()
      .then((basketStorage: basketItem[]) => {
        basketStorage.forEach((basketItem: any, index: number) => {
          delete basketStorage[index].metaData;
        });
        
        this.woocommerce.buy(
          basketStorage
        )
        .then((response: any) => {
  
          if(response.hasOwnProperty('create') && response.create) {
            let order = response.create.create || null;
            
            if(order) {
              this.setDeliveryOptions({});
              this.setPendingOrder(order);
              this.orders.addOrder(order)
              .then((response: any) => {
                this.basket.clear();
                resolve(response);
              })
              .catch(reject);
            }
          }
          this.getPendingOrder().then(resolve).catch(reject);
        })
        .catch(reject);
      })
      .catch(reject);
    });
  }

  checkoutScheduled() {
    return new Promise((resolve, reject) => {
      this.basket.getBasket()
      .then((basketStorage: basketItem[]) => {
        basketStorage.forEach((basketItem: any, index: number) => {
          delete basketStorage[index].metaData;
        });
        
        this.woocommerce.schedulePlannedOrder(
          basketStorage
        )
        .then((response: any) => {
          if(response.hasOwnProperty('create') && response.create) {
            let order = response.create.create || null;
            if(order) {
              this.setDeliveryOptions({});
              this.setPendingOrder(order);
              this.orders.addOrder(order)
              .then((response: any) => {
                this.basket.clear();
                resolve(response);
              })
              .catch(reject);
            }
          }
          this.getPendingOrder().then(resolve).catch(reject);
        })
        .catch(reject);
      })
      .catch(reject);
    });
  }

  getDeliveryOptions() {
    if(this.deliveryOptions && this.deliveryOptions.type === 'now' && !this.deliveryOptions.datetime) {
      this.deliveryOptions.datetime = moment().format('DD.MM.YYYY') + ' ' + moment().format('HH:mm');
    }
    return this.deliveryOptions;
  }

  getPendingOrder() {
    return new Promise((resolve, reject) => {
      if(this.pendingOrder) {
        this.woocommerce.getOrderByUid(this.pendingOrder.id)
        .then((order: any) => {
          switch(order.status) {
            case 'completed':
              order.status_formatted = 'Fertig';
              break;
            case 'on-hold':
              order.status_formatted = 'In Zustellung';
              break;
            case 'processing':
              order.status_formatted = 'In Bearbeitung';
              break;
            case 'trash':
              order.status_formatted = 'Papierkorb';
              break;
            default:
              order.status_formatted = 'Ausstehend';
              break;
          }
          resolve(order);
        }).catch(reject);
      } else {
        reject('Keine Bestellung in Bearbeitung');
      }
    });
  }
  
  setDeliveryOptions(options: any) {
    this.deliveryOptions = options;
    window.localStorage.setItem('deliveryOptions', JSON.stringify(this.deliveryOptions));
    this.events.publish('checkout:deliveryOptions:updated', this.deliveryOptions);
  }

  setPendingOrder(order: any) {
    this.pendingOrder = order;
    window.localStorage.setItem('pendingOrder', JSON.stringify(this.pendingOrder));
  }

}