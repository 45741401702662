import { Injectable } from '@angular/core';
import { ChooseActionSheetPage } from '../pages/chooser/choose-action-sheet/choose-action-sheet.page';

import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class ChooserService {

  constructor(
    private modalCtrl: ModalService,
  ) {
  }

  choose(chooseConfig: chooseConfig) {
    return new Promise(async (resolve, reject) => {
      let chooseModal = await this.modalCtrl.create({
        component: ChooseActionSheetPage,
        componentProps: chooseConfig,
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: 'defaultModal'
      });
      chooseModal.onWillDismiss().then(resolve);
      chooseModal.present();
    });
  }

}
