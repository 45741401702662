<ion-header class="ion-no-border" translucent="true">
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" icon-only>
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'search'|translate"></ion-title>
  </ion-toolbar>

  <ion-toolbar class="ion-no-border container">

    <ion-searchbar
      autocorrect="on"
      [(ngModel)]="search.query"
      #searchInput
      id="searchInput"
      [placeholder]="'search_something'|translate"
      (ionChange)="onSearchQueryChanged()"
    ></ion-searchbar>

  </ion-toolbar>

  <ion-toolbar class="ion-no-border container" *ngIf="config.useLocal || config.usePeople || config.useShop">
    <ion-segment [hidden]="state.loading" scrollable [(ngModel)]="state.searchTab"
    (ionChange)="setSearchTab(state.searchTab)" id="searchSegment" #searchSegment>
      <ion-segment-button value="everything">
        <ion-icon name="layers-outline"></ion-icon>
        <ion-label [innerHTML]="'search_everything'|translate"></ion-label>
      </ion-segment-button>  
      <ion-segment-button value="posts">
        <ion-icon name="newspaper-outline"></ion-icon>
        <ion-label [innerHTML]="'posts'|translate"></ion-label>
      </ion-segment-button>
      <ion-segment-button value="local" *ngIf="config.useLocal" [hidden]="!view.hasLocal">
        <ion-icon name="location-outline"></ion-icon>
        <ion-label [innerHTML]="'local'|translate"></ion-label>
      </ion-segment-button>
      <ion-segment-button value="events" *ngIf="config.useEventsList">
        <ion-icon name="calendar-outline"></ion-icon>
        <ion-label [innerHTML]="'events'|translate"></ion-label>
      </ion-segment-button>
      <ion-segment-button value="people" *ngIf="config.usePeople">
        <ion-icon name="people-outline"></ion-icon>
        <ion-label [innerHTML]="'people'|translate"></ion-label>
      </ion-segment-button>
      <ion-segment-button value="products" *ngIf="config.useShop">
        <ion-icon name="bag-handle-outline"></ion-icon>
        <ion-label [innerHTML]="'products'|translate"></ion-label>
      </ion-segment-button>
      <ion-segment-button value="job_posts" *ngIf="config.useJobPosts">
        <ion-icon name="business-outline"></ion-icon>
        <ion-label [innerHTML]="'job_posts'|translate"></ion-label>
      </ion-segment-button>
      <ion-segment-button value="companies" *ngIf="config.useCompaniesExtension">
        <ion-icon name="business-outline"></ion-icon>
        <ion-label [innerHTML]="'companies'|translate"></ion-label>
      </ion-segment-button>
    </ion-segment>

  </ion-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">

    <ion-list id="searchResults">

      <ion-item
        *ngFor="let col of search.results"
        [hidden]="col.hidden"
        (click)="onColClick(col)">
        
        <ion-thumbnail slot="start">
          <ion-img *ngIf="col.avatar || col.thumbnail" [src]="col.avatar || col.thumbnail" (ionError)="thumbnailLoadingFailed(col)"></ion-img>
          <ion-skeleton-text class="image" [hidden]="col.avatar || col.thumbnail" animated></ion-skeleton-text>
        </ion-thumbnail>
  
        <ion-label class="ion-text-wrap">
          <h2 *ngIf="col.name" [innerHTML]="col.name"></h2>
          <h4 *ngIf="col.host"  (click)="openProfile(col)" [innerHTML]="col.host"></h4>
          <ion-skeleton-text *ngIf="!col.name" style="width:40%;" animated></ion-skeleton-text>
          <p class="ion-text-wrap" [innerHTML]="col.hostLabel|translate" *ngIf="col.hostLabel"></p>
        </ion-label>
        
        <ion-button
            slot="end"
            icon-only
            class="optionsButton"
            (click)="options(col, $event)"
            [hidden]="!col.allowed"
            color="dark"
            fill="clear">
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
        </ion-button>
          
      </ion-item>
    </ion-list>
  
    <ion-card *ngIf="search && search.history && search.history.length && !search.results">
      <ion-list id="searchHistory">
  
        <ion-list-header>
          <ion-label [innerHTML]="'search_history'|translate"></ion-label>
        </ion-list-header>
    
        <ion-item-sliding *ngFor="let query of search.history">
          <ion-item>
            <ion-label (click)="onSearchHistoryItemClick(query)" [innerHTML]="query"></ion-label>
            <ion-button slot="end" fill="clear" color="dark" icon-only (click)="deleteSearchHistoryItem(query)">
              <ion-icon name="close-outline"></ion-icon>
            </ion-button>
          </ion-item>
          <ion-item-options slot="end">
            <ion-item-option color="danger" icon-only (click)="deleteSearchHistoryItem(query)">
              <ion-icon name="trash-outline"></ion-icon>
              <ion-label [innerHTML]="'delete'|translate"></ion-label>
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
    </ion-card>
  
    <ion-card id="categoriesCard" *ngIf="view.categories && view.categories.length && (!search.results || !search.results.length)">
      <ion-list lines="none">
        <ion-list-header>
          <ion-label [innerHTML]="'recommended_categories'|translate"></ion-label>
        </ion-list-header>
      </ion-list>
      <ion-card-content>
        <ion-button size="small" *ngFor="let category of view.categories" shape="round" fill="outline" color="dark" (click)="onCategoryClick(category)">
          <ion-icon *ngIf="category.icon" [name]="category.icon" slot="start"></ion-icon>
          <ion-label [innerHTML]="category.name"></ion-label>
        </ion-button>
      </ion-card-content>
    </ion-card>
    
  </div>

</ion-content>
